import { SheetSize, VizSize as VizSizeInterface } from '@tableau/api-external-contract-js';

export class VizSize implements VizSizeInterface {
  public constructor(private _sheetSize: SheetSize, private _chromeHeight: number) {}

  public get sheetSize(): SheetSize {
    return this._sheetSize;
  }

  public get chromeHeight(): number {
    return this._chromeHeight;
  }
}
