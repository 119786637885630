import { VersionNumber } from './VersionNumber';
import { VersionNumberContract } from './VersionNumberContract';

/**
 * This is the singleton wrapper of VersionNumber
 */
export class ApiVersion {
  // Using some webpack tricks, we can inject this version into our code (kinda like c++ preprocessor stuff)
  private static _instance: VersionNumberContract;

  /**
   * Gets the singleton instance of the version number.
   */
  public static get Instance(): VersionNumberContract {
    return ApiVersion._instance;
  }

  public static SetVersionNumber(numString: string, isAlpha: boolean): void {
    ApiVersion._instance = new VersionNumber(numString, isAlpha);
  }
}
