import * as Contract from '@tableau/api-external-contract-js';
import { EmbeddingTableauEventType } from '@tableau/api-external-contract-js';
import { TableauWorksheetEvent } from './TableauWorksheetEvent';

export class MarksSelectedEvent extends TableauWorksheetEvent implements Contract.EmbeddingMarkSelectedEvent {
  public constructor(worksheet: Contract.EmbeddingWorksheet) {
    super(EmbeddingTableauEventType.MarkSelectionChanged, worksheet);
  }

  public getMarksAsync(): Promise<Contract.MarksCollection> {
    return this.worksheet.getSelectedMarksAsync();
  }
}
