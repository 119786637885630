import { SheetSize } from '@tableau/api-external-contract-js';
import { ExecuteParameters, ParameterId, VerbId } from '@tableau/api-internal-contract-js';
import { ServiceNames } from '../ServiceRegistry';
import { SizeService } from '../SizeService';
import { ServiceImplBase } from './ServiceImplBase';

export class SizeServiceImpl extends ServiceImplBase implements SizeService {
  public get serviceName(): string {
    return ServiceNames.Size;
  }

  changeSizeAsync(name: string, newSize: SheetSize): Promise<void> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'changeSizeAsync',
      [ParameterId.SheetName]: name,
      [ParameterId.MinHeight]: newSize.minSize?.height || 0,
      [ParameterId.MinWidth]: newSize.minSize?.width || 0,
      [ParameterId.MaxHeight]: newSize.maxSize?.height || 0,
      [ParameterId.MaxWidth]: newSize.maxSize?.width || 0,
    };

    return this.execute(VerbId.SetSheetSize, parameters).then<void>((_response) => {
      return;
    });
  }
}
