import * as Contract from '@tableau/api-external-contract-js';
import { EmbeddingErrorCodes, EmbeddingTableauEventType } from '@tableau/api-external-contract-js';
import { TableauError } from '@tableau/api-shared-js';
import { TableauWorksheetEvent } from './TableauWorksheetEvent';

export class FilterChangedEvent extends TableauWorksheetEvent implements Contract.EmbeddingFilterChangedEvent {
  public constructor(worksheet: Contract.EmbeddingWorksheet, private _fieldName: string, private _fieldId?: string) {
    super(EmbeddingTableauEventType.FilterChanged, worksheet);
  }

  public get fieldName(): string {
    return this._fieldName;
  }

  public get fieldId(): string | undefined {
    return this._fieldId;
  }

  public getFilterAsync(): Promise<Contract.Filter> {
    return this.worksheet.getFiltersAsync().then<Contract.Filter>((filters) => {
      // TODO: Filtering of the filters should eventually be done platform side.
      const eventedFilter = this.fieldId
        ? filters.find((filter) => filter.fieldId === this.fieldId, this)
        : filters.find((filter) => filter.fieldName === this.fieldName, this);

      if (!eventedFilter) {
        // We shouldn't hit this unless the filter was removed from the worksheet
        // after the event was raised.
        throw new TableauError(EmbeddingErrorCodes.FilterMissingOrNotImplemented, `Cannot find filter: ${this._fieldName}.`);
      }

      return eventedFilter;
    });
  }
}
