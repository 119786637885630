import * as Contract from '@tableau/api-external-contract-js';
import { StoryPointInfoImpl } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { Story } from './Story';

export class StoryPointInfo implements Contract.StoryPointInfo {
  constructor(private _storyPointInfoImpl: StoryPointInfoImpl, private _workbookImpl: EmbeddingWorkbookImpl) {}

  public get index(): number {
    return this._storyPointInfoImpl.index;
  }

  public get caption(): string {
    return this._storyPointInfoImpl.caption;
  }

  public get active(): boolean {
    return this._storyPointInfoImpl.active;
  }

  public get updated(): boolean {
    return this._storyPointInfoImpl.updated;
  }

  public get parentStory(): Contract.Story {
    return new Story(this._storyPointInfoImpl.parentStory, this._workbookImpl);
  }
}
