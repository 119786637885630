import * as Contract from '@tableau/api-external-contract-js';
import { ErrorCodes } from '@tableau/api-external-contract-js';
import * as InternalContract from '@tableau/api-internal-contract-js';
import {
  ConnectionDescriptionSummary,
  DataSchema,
  ExecuteParameters,
  InternalApiDispatcher,
  LogicalTableInfo,
  ParameterId,
  TableInfo,
  TableInfos,
  VerbId,
  VisualId,
} from '@tableau/api-internal-contract-js';
import { DataSource } from '../../DataSource';
import { Field } from '../../Field';
import { DataSourceImpl } from '../../Impl/DataSourceImpl';
import { FieldImpl } from '../../Impl/FieldImpl';
import { TableauError } from '../../TableauError';
import { DataSourceService } from '../DataSourceService';
import { ServiceNames } from '../ServiceRegistry';
import { ServiceImplBase } from './ServiceImplBase';

export const SENTINEL_LOGICAL_TABLE_INFO: LogicalTableInfo = {
  id: InternalContract.ApiShowDataTableSentinel.SingleTableId,
  caption: InternalContract.ApiShowDataTableSentinel.SingleTableCaption,
};

// The minimum platform version when Object Model was supported
const platformVersionObjectModelSupport = { major: 1, minor: 20, fix: 1 };

export class DataSourceServiceImpl extends ServiceImplBase implements DataSourceService {
  // Since Object Model is supported since Tableau 2020.2, DataSourceServiceImpl can be initialized with the
  // platform version when OM was supported. Once we drop support for Tableau versions <= 2020.1, all additional
  // code in here for Object Model can be removed.
  public constructor(
    dispatcher: InternalApiDispatcher,
    registryId: number,
    private _platformVersion: InternalContract.VersionNumber = platformVersionObjectModelSupport,
  ) {
    super(dispatcher, registryId);
  }
  public get serviceName(): string {
    return ServiceNames.DataSourceService;
  }

  public refreshAsync(dataSourceId?: string): Promise<void> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'refreshAsync',
      [ParameterId.DeltaTimeMs]: 0,
      [ParameterId.ShouldRefreshDS]: true,
    };

    // On server: Not passing the datasource id will refresh all datasources.
    if (dataSourceId) {
      parameters[ParameterId.DataSourceId] = dataSourceId;
    }

    return this.execute(VerbId.RefreshDataSource, parameters).then<void>((response) => {
      return;
    });
  }

  public getActiveTablesAsync(dataSourceId: string): Promise<Array<TableInfo>> {
    const joinParameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getActiveTablesAsync',
      [ParameterId.DataSourceId]: dataSourceId,
    };

    // Get the description of the tables used by this connection
    return this.execute(VerbId.GetActiveTables, joinParameters).then<Array<TableInfo>>((joinResponse) => {
      const tableInfos = joinResponse.result as TableInfos;

      // getActiveTables is unsupported for cubes and GA. We do not have a connection type property
      // available from the platform (intentionally, to reduce code churn as new connections are added).
      // Instead,just check if any tables are returned. This array will be empty for any non-table based datasource.
      if (tableInfos.tables.length === 0) {
        throw new TableauError(ErrorCodes.UnsupportedMethodForDataSourceType, `getActiveTables is not supported for: ${dataSourceId}`);
      }

      return tableInfos.tables;
    });
  }

  public getDataSourcesAsync(visualId: VisualId): Promise<DataSchema> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getDataSourcesAsync',
      [ParameterId.VisualId]: visualId,
    };
    return this.execute(VerbId.GetDataSources, parameters).then<DataSchema>((response) => {
      const dataSchema = response.result as DataSchema;
      return dataSchema;
    });
  }

  public getAllDataSourcesAsync(): Promise<DataSchema> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getAllDataSourcesAsync',
    };
    return this.execute(VerbId.GetAllDataSources, parameters).then<DataSchema>((response) => {
      const dataSchema = response.result as DataSchema;
      return dataSchema;
    });
  }

  public getConnectionSummariesAsync(dataSourceId: string): Promise<ConnectionDescriptionSummary[]> {
    const params: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getConnectionSummariesAsync',
      [ParameterId.DataSourceId]: dataSourceId,
    };

    // Get the description of the tables used by this connection
    return this.execute(VerbId.GetConnectionDescriptionSummaries, params).then<ConnectionDescriptionSummary[]>((response) => {
      const descriptionSummaries = response.result as ConnectionDescriptionSummary[];
      return descriptionSummaries;
    });
  }

  public getFieldAsync(globalfieldName: string): Promise<Contract.Field> {
    const verb = VerbId.GetFieldAndDataSource;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getFieldAsync',
      [ParameterId.FieldId]: globalfieldName,
    };

    return this.execute(verb, parameters).then<Contract.Field>((response) => {
      const dataSource = response.result[ParameterId.DataSource] as InternalContract.DataSource;
      const field = response.result[ParameterId.Field] as Field;
      return this.convertField(field, this.convertDataSource(dataSource));
    });
  }

  public getLogicalTablesAsync(dataSourceId: string): Promise<Array<LogicalTableInfo>> {
    if (!this.isObjectModelSupportedByPlatform()) {
      /**
       * This sentinel ID can be passed to datasource.getLogicalTableData.
       * Once the desktop is upgraded to a version that supports object model,
       * the sentinel ID will automatically fetch the upgraded table.
       * */
      return new Promise<Array<LogicalTableInfo>>((resolve) => resolve([SENTINEL_LOGICAL_TABLE_INFO]));
    }

    const params: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getLogicalTablesAsync',
      [ParameterId.DataSourceId]: dataSourceId,
    };
    return this.execute(VerbId.GetLogicalTables, params).then<Array<LogicalTableInfo>>((response) => {
      return response.result as Array<LogicalTableInfo>;
    });
  }

  public getUnderlyingTablesAsync(visualId: VisualId): Promise<Array<LogicalTableInfo>> {
    if (!this.isObjectModelSupportedByPlatform()) {
      /**
       * This sentinel ID can be passed to worksheet.getUnderlyingTableData.
       * Once the desktop is upgraded to a version that supports object model,
       * the sentinel ID will automatically fetch the upgraded table.
       * */
      return new Promise<Array<LogicalTableInfo>>((resolve) => resolve([SENTINEL_LOGICAL_TABLE_INFO]));
    }

    const params: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getUnderlyingTablesAsync',
      [ParameterId.VisualId]: visualId,
    };
    return this.execute(VerbId.GetUnderlyingTables, params).then<Array<LogicalTableInfo>>((response) => {
      return response.result as Array<LogicalTableInfo>;
    });
  }

  private convertField(field: InternalContract.Field, dataSource: Contract.DataSource): Contract.Field {
    return new Field(new FieldImpl(field, dataSource));
  }

  private convertDataSource(dataSource: InternalContract.DataSource): Contract.DataSource {
    return new DataSource(new DataSourceImpl(dataSource, this._registryId));
  }

  private isObjectModelSupportedByPlatform(): boolean {
    const platformVersionNoObjectModelSupport = { major: 1, minor: 13, fix: 0 };
    return InternalContract.VersionLessThan(platformVersionNoObjectModelSupport, this._platformVersion);
  }
}
