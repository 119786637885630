import * as Contract from '@tableau/api-external-contract-js';
import { DashboardImpl, StoryPointImpl, TableauError, WorksheetImpl } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { Dashboard } from './Dashboard';
import { Story } from './Story';
import { Worksheet } from './Worksheet';

export class StoryPoint implements Contract.StoryPoint {
  constructor(private _storyPointImpl: StoryPointImpl, private _workbookImpl: EmbeddingWorkbookImpl) {}

  public get index(): number {
    return this._storyPointImpl.index;
  }

  public get caption(): string {
    return this._storyPointImpl.caption;
  }

  public get active(): boolean {
    return this._storyPointImpl.active;
  }

  public get updated(): boolean {
    return this._storyPointImpl.updated;
  }

  public get containedSheet(): Contract.EmbeddingSheet | undefined {
    const containedSheet = this._storyPointImpl.containedSheet;
    if (!containedSheet) {
      return undefined;
    }

    switch (containedSheet.sheetType) {
      case Contract.SheetType.Worksheet: {
        return new Worksheet(containedSheet as WorksheetImpl, this._workbookImpl);
      }
      case Contract.SheetType.Dashboard: {
        return new Dashboard(containedSheet as DashboardImpl, this._workbookImpl);
      }
      case Contract.SheetType.Story: {
        throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError, 'A story cannot contain another story');
      }
      default: {
        throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError, 'Unable to initialize contained sheet');
      }
    }
  }

  public get parentStory(): Contract.Story {
    return new Story(this._storyPointImpl.parentStory, this._workbookImpl);
  }
}
