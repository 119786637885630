import * as Contract from '@tableau/api-external-contract-js';
import { DateRangeType, FilterDomainType, FilterType, PeriodType } from '@tableau/api-external-contract-js';
import { HierarchicalLevelSelectionState } from '@tableau/api-external-contract-js/lib/src/ExternalContract/Shared/Namespaces/Tableau';
import { DataSourceService } from '../Services/DataSourceService';
import { FilterService } from '../Services/FilterService';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { ErrorHelpers } from '../Utils/ErrorHelpers';

export class Filter implements Contract.Filter {
  public constructor(
    protected _worksheetName: string,
    protected _fieldName: string,
    protected _filterType: FilterType,
    protected _fieldId: string,
    protected _registryId: number,
  ) {}

  public get worksheetName(): string {
    return this._worksheetName;
  }

  public get fieldName(): string {
    return this._fieldName;
  }

  public get fieldId(): string {
    return this._fieldId;
  }

  public get filterType(): FilterType {
    return this._filterType;
  }

  public getFieldAsync(): Promise<Contract.Field> {
    const service = ApiServiceRegistry.get(this._registryId).getService<DataSourceService>(ServiceNames.DataSourceService);
    return service.getFieldAsync(this._fieldId);
  }

  public getAppliedWorksheetsAsync(): Promise<Array<string>> {
    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.getAppliedWorksheetsAsync(this._worksheetName, this._fieldId);
  }

  public setAppliedWorksheetsAsync(applyToWorksheets: Array<string>): Promise<Array<string>> {
    ErrorHelpers.verifyParameter(applyToWorksheets, 'applyToWorksheets');
    const uniqueWorksheets = new Set(applyToWorksheets);
    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.setAppliedWorksheetsAsync(this._worksheetName, this._fieldName, this._fieldId, Array.from(uniqueWorksheets));
  }
}

export class CategoricalFilter extends Filter implements Contract.CategoricalFilter {
  public constructor(
    worksheetName: string,
    fieldName: string,
    fieldId: string,
    filterType: FilterType,
    registryId: number,
    private _appliedValues: Array<Contract.DataValue>,
    private _isExcludeMode: boolean,
    private _isAllSelected?: boolean,
  ) {
    super(worksheetName, fieldName, filterType, fieldId, registryId);
  }

  public get isAllSelected(): boolean | undefined {
    return this._isAllSelected;
  }

  public get appliedValues(): Array<Contract.DataValue> {
    return this._appliedValues;
  }

  public get isExcludeMode(): boolean {
    return this._isExcludeMode;
  }

  public getDomainAsync(domainType?: FilterDomainType): Promise<Contract.CategoricalDomain> {
    if (!domainType) {
      domainType = FilterDomainType.Relevant;
    }

    ErrorHelpers.verifyEnumValue<FilterDomainType>(domainType, FilterDomainType, 'FilterDomainType');

    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.getCategoricalDomainAsync(this._worksheetName, this._fieldId, domainType);
  }
}

export class HierarchicalDataValue implements Contract.HierarchicalFilterDataValue {
  public constructor(private _value: Contract.DataValue, private _hierarchicalPath: string, private _level: number) {}

  public get value(): Contract.DataValue {
    return this._value;
  }

  public get hierarchicalPath(): string {
    return this._hierarchicalPath;
  }

  public get level(): number {
    return this._level;
  }
}

export class HierarchicalLevelDetail implements Contract.HierarchicalLevelDetail {
  public constructor(private _name: string, private _levelSelectionState: HierarchicalLevelSelectionState) {}

  public get name(): string {
    return this._name;
  }

  public get levelSelectionState(): HierarchicalLevelSelectionState {
    return this._levelSelectionState;
  }
}

export class HierarchicalFilter extends Filter implements Contract.HierarchicalFilter {
  public constructor(
    worksheetName: string,
    fieldName: string,
    fieldId: string,
    filterType: FilterType,
    registryId: number,
    private _dimensionName: string,
    private _hierarchyCaption: string,
    private _numberOfLevels: number,
    private _levelDetails: Array<Contract.HierarchicalLevelDetail>,
    private _appliedValues: Array<Contract.HierarchicalFilterDataValue>,
    private _isAllSelected: boolean,
  ) {
    super(worksheetName, fieldName, filterType, fieldId, registryId);
  }
  getDomainAsync(domainType?: Contract.FilterDomainType): Promise<Contract.CategoricalDomain> {
    throw new Error('Method not implemented.');
  }

  public get dimensionName(): string {
    return this._dimensionName;
  }

  public get hierarchyCaption(): string {
    return this._hierarchyCaption;
  }

  public get numberOfLevels(): number {
    return this._numberOfLevels;
  }

  public get levelDetails(): Contract.HierarchicalLevelDetail[] {
    return this._levelDetails;
  }

  public get isAllSelected(): boolean {
    return this._isAllSelected;
  }

  public get appliedValues(): Array<Contract.HierarchicalFilterDataValue> {
    return this._appliedValues;
  }
}

export class RangeFilter extends Filter implements Contract.RangeFilter {
  public constructor(
    worksheetName: string,
    fieldName: string,
    fieldId: string,
    filterType: FilterType,
    registryId: number,
    private _min: Contract.DataValue,
    private _max: Contract.DataValue,
    private _includeNullValues: boolean,
  ) {
    super(worksheetName, fieldName, filterType, fieldId, registryId);
  }

  public get minValue(): Contract.DataValue {
    return this._min;
  }

  public get maxValue(): Contract.DataValue {
    return this._max;
  }

  public get includeNullValues(): boolean {
    return this._includeNullValues;
  }

  public getDomainAsync(domainType?: FilterDomainType): Promise<Contract.RangeDomain> {
    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    if (!domainType) {
      domainType = FilterDomainType.Relevant;
    }

    ErrorHelpers.verifyEnumValue<FilterDomainType>(domainType, FilterDomainType, 'FilterDomainType');

    return service.getRangeDomainAsync(this._worksheetName, this._fieldId, domainType);
  }
}

export class RelativeDateFilter extends Filter implements Contract.RelativeDateFilter {
  public constructor(
    worksheetName: string,
    fieldName: string,
    fieldId: string,
    filterType: FilterType,
    registryId: number,
    private _anchorDate: Contract.DataValue,
    private _periodType: PeriodType,
    private _rangeType: DateRangeType,
    private _rangeN: number,
  ) {
    super(worksheetName, fieldName, filterType, fieldId, registryId);
  }

  public get anchorDate(): Contract.DataValue {
    return this._anchorDate;
  }

  public get periodType(): PeriodType {
    return this._periodType;
  }

  public get rangeType(): DateRangeType {
    return this._rangeType;
  }

  public get rangeN(): number {
    return this._rangeN;
  }
}

export class CategoricalDomain implements Contract.CategoricalDomain {
  public constructor(private _values: Array<Contract.DataValue>, private _domainType: FilterDomainType) {}

  public get values(): Array<Contract.DataValue> {
    return this._values;
  }

  public get type(): FilterDomainType {
    return this._domainType;
  }
}

export class RangeDomain implements Contract.RangeDomain {
  public constructor(private _min: Contract.DataValue, private _max: Contract.DataValue, private _domainType: FilterDomainType) {}

  public get type(): FilterDomainType {
    return this._domainType;
  }

  public get min(): Contract.DataValue {
    return this._min;
  }

  public get max(): Contract.DataValue {
    return this._max;
  }
}
