import { CustomViewModel } from '@tableau/api-internal-contract-js';
import { ApiServiceRegistry, ServiceNames, VizService } from '../../ApiShared';
export class CustomViewImpl {
  private _luid: string;
  private _name: string;
  private _shared: boolean;
  private _isDefault: boolean;
  private _ownerName: string;
  private _url: string;
  public constructor(customViewModel: CustomViewModel, private _registryId: number) {
    this._luid = customViewModel.luid;
    this._name = customViewModel.name;
    this._shared = customViewModel.shared;
    this._isDefault = customViewModel.isDefault;
    this._ownerName = customViewModel.owner.userFriendlyName;
    this._url = customViewModel.url;
  }

  public get luid() {
    return this._luid;
  }

  public get name() {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get shared() {
    return this._shared;
  }

  public set shared(shared: boolean) {
    this._shared = shared;
  }

  public get isDefault() {
    return this._isDefault;
  }

  public set isDefault(isDefault: boolean) {
    this._isDefault = isDefault;
  }

  public get ownerName() {
    return this._ownerName;
  }

  public get url() {
    return this._url;
  }

  public saveAsync(): Promise<CustomViewImpl> {
    const service = ApiServiceRegistry.get(this._registryId).getService<VizService>(ServiceNames.Viz);
    return service.saveAsync(this);
  }
}
