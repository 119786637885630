import * as Contract from '@tableau/api-external-contract-js';
import { Model, NotificationId } from '@tableau/api-internal-contract-js';
import { ApiServiceRegistry, NotificationService, ServiceNames, TableauError } from '@tableau/api-shared-js';
import { VizImpl } from '../Impl/VizImpl';
import { WebComponentManager } from '../WebComponentManager';
import { TableauWebComponent } from './TableauWebComponent';

export type ShouldRaiseNotificationFunc = (model: Model) => boolean;
export type HandleNotificationFunc = (model: Model) => void;
export type EventHandlerFn = [NotificationId, ShouldRaiseNotificationFunc, HandleNotificationFunc];

export type AttributeEventType = [
  Contract.VizSharedAttributes | Contract.VizAttributes | Contract.VizAuthoringAttributes,
  Contract.EmbeddingTableauEventType,
];

/**
 * This class is specifically focused on transferring information between html and viz
 * and giving the user an entry point into the viz model
 * It should have as little logic as possible
 */
export abstract class TableauVizBase extends TableauWebComponent {
  public static VizAttributeDefaults = {
    device: Contract.DeviceType.Default,
    toolbar: Contract.Toolbar.Bottom,
  };

  private _vizImpl: VizImpl;
  protected abstract createVizImpl(customParams: Contract.CustomParameter[]): VizImpl;
  protected abstract getAttributeEvents(): AttributeEventType[];

  // ========================================== Begin Custom Element definition ==========================================

  // https://html.spec.whatwg.org/multipage/custom-elements.html#custom-element-conformance

  public disconnectedCallback(): void {
    super.disconnectedCallback();
    this._vizImpl.dispose();
  }

  public static get observedAttributes(): string[] {
    // Take caution before adding to this list because for every observed attribute change
    // we unregister and re-render the viz
    return [...super.observedAttributes, ...Object.values(Contract.VizSharedAttributes)];
  }

  protected updateRenderingIfConnected() {
    if (!this._connected) {
      return;
    }

    // vizImpl is empty when a src is not set on initial tableau-viz load
    if (this._vizImpl) {
      this._vizImpl.dispose();
    }
    WebComponentManager.unregisterWebComponent(this._embeddingIdCounter);
    this.updateRendering();
  }

  protected updateRendering(): void {
    // Nothing to render if the user hasn't provided a src
    if (this.src) {
      const customParams = this.readCustomParamsFromChildren();
      this.registerAttributeEvents();
      this._vizImpl = this.createVizImpl(customParams);
    } else {
      console.warn(`A src needs to be set on the ${this.tagName.toLowerCase()} element. Skipping rendering.`);
    }
  }

  private registerAttributeEvents(): void {
    this.getAttributeEvents().forEach((elem) => {
      const [attributeEvent, eventType] = elem;
      this.registerCallback(attributeEvent, eventType);
    });
  }

  private registerCallback(attributeEvent: string, eventType: string) {
    // this will allow for both lowercase and camelcase attribute
    const funcName = this.getAttribute(attributeEvent);
    if (funcName && /^[a-zA-Z_$][0-9a-zA-Z_$]*$/.test(funcName)) {
      if (window[funcName]) {
        this.addEventListener(eventType, window[funcName]);
      }
    }
  }

  public initializeEvents(): void {
    let notificationService: NotificationService;

    try {
      notificationService = ApiServiceRegistry.get(this.vizImpl.embeddingId).getService<NotificationService>(ServiceNames.Notification);
    } catch (e) {
      throw new TableauError(Contract.EmbeddingErrorCodes.EventInitializationError, 'Event initialization failed');
    }

    const registeredEvents = this.getRegisteredEvents();
    for (const [notification, filterfn, handler] of registeredEvents) {
      notificationService.registerHandler(notification, filterfn, handler);
    }
  }

  protected getRegisteredEvents(): EventHandlerFn[] {
    return [
      [
        NotificationId.EditInDesktopButtonClicked,
        () => true,
        () => this.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.EditInDesktopButtonClicked)),
      ],
    ];
  }

  //#region Simple Getters / Setters

  public get touchOptimize(): boolean {
    return this.hasAttribute(Contract.VizSharedAttributes.TouchOptimize);
  }

  public set touchOptimize(v: boolean) {
    if (v) {
      this.setAttribute(Contract.VizSharedAttributes.TouchOptimize, '');
    } else {
      this.removeAttribute(Contract.VizSharedAttributes.TouchOptimize);
    }
  }

  protected get vizImpl(): VizImpl {
    return this._vizImpl;
  }

  public get hideEditInDesktopButton(): boolean {
    return this.hasAttribute(Contract.VizSharedAttributes.HideEditInDesktopButton);
  }

  public set hideEditInDesktopButton(v: boolean) {
    if (v) {
      this.setAttribute(Contract.VizSharedAttributes.HideEditInDesktopButton, '');
    } else {
      this.removeAttribute(Contract.VizSharedAttributes.HideEditInDesktopButton);
    }
  }

  public get suppressDefaultEditBehavior(): boolean {
    return this.hasAttribute(Contract.VizSharedAttributes.SuppressDefaultEditBehavior);
  }

  public set suppressDefaultEditBehavior(v: boolean) {
    if (v) {
      this.setAttribute(Contract.VizSharedAttributes.SuppressDefaultEditBehavior, '');
    } else {
      this.removeAttribute(Contract.VizSharedAttributes.SuppressDefaultEditBehavior);
    }
  }

  public get disableVersionCheck(): boolean {
    return this.hasAttribute(Contract.VizSharedAttributes.DisableVersionCheck);
  }

  public set disableVersionCheck(v: boolean) {
    if (v) {
      this.setAttribute(Contract.VizSharedAttributes.DisableVersionCheck, '');
    } else {
      this.removeAttribute(Contract.VizSharedAttributes.DisableVersionCheck);
    }
  }

  //#endregion

  public getCurrentSrcAsync(): Promise<string> {
    return this.vizImpl.getCurrentSrcAsync();
  }

  //#region For testing
  //#endregion

  // ========================================== End Custom Element definition ============================================
}

// This maybe needed in multiple files, so leaving outside the class for now.
export function attributeToEnumKey(value: string | null): string {
  if (!value || value.length < 1) {
    return '';
  }

  const lowercase = value.toLowerCase();
  const firstUpper = lowercase[0].toUpperCase() + lowercase.substring(1);
  return firstUpper;
}
