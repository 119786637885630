import * as Contract from '@tableau/api-external-contract-js';
import { ParameterImpl } from '@tableau/api-shared-js';

/**
 * Implementation of the Parameter contract. Calls down to the impl
 * class for almost all of the work it does.
 */
export class Parameter implements Contract.EmbeddingParameter {
  public constructor(private parameterImpl: ParameterImpl) {}

  public get name(): string {
    return this.parameterImpl.name;
  }

  public get currentValue(): Contract.DataValue {
    return this.parameterImpl.currentValue;
  }

  public get dataType(): Contract.DataType {
    return this.parameterImpl.dataType;
  }

  public get allowableValues(): Contract.ParameterDomainRestriction {
    return this.parameterImpl.allowableValues;
  }

  public get id(): string {
    return this.parameterImpl.id;
  }

  public changeValueAsync(newValue: string | number | boolean | Date): Promise<Contract.DataValue> {
    return this.parameterImpl.changeValueAsync(newValue);
  }
}
