import { ExecuteParameters, ParameterId, VerbId } from '@tableau/api-internal-contract-js';
import { ServiceImplBase } from '@tableau/api-shared-js';
import { EmbeddingServiceNames } from '../EmbeddingServiceNames';
import { ToolbarService } from '../ToolbarService';

export class ToolbarServiceImpl extends ServiceImplBase implements ToolbarService {
  public get serviceName(): string {
    return EmbeddingServiceNames.ToolbarService;
  }
  public revertAllAsync(): Promise<void> {
    const verb = VerbId.RevertWorkbook;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'revertAllAsync',
    };
    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }

  public redoAsync(): Promise<void> {
    const verb = VerbId.Redo;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'redoAsync',
    };
    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }

  public undoAsync(): Promise<void> {
    const verb = VerbId.Undo;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'undoAsync',
    };
    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }

  public exportImageAsync(): Promise<void> {
    const verb = VerbId.ExportImage;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'exportImageAsync',
    };
    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }

  public setAutoUpdateAsync(state: boolean): Promise<void> {
    const verb = VerbId.SetAutoUpdate;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'setAutoUpdateAsync',
      [ParameterId.State]: state,
    };
    return this.execute(verb, parameters).then<void>(() => {
      return;
    });
  }

  public displayDownloadWorkbookDialogAsync(): Promise<void> {
    const verb = VerbId.DownloadWorkbook;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'displayDialogAsync(Workbook)',
    };
    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }

  public displayExportPdfDialogAsync(): Promise<void> {
    const verb = VerbId.ExportPdf;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'displayDialogAsync(ExportPDF)',
    };
    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }

  public displayExportPowerpointDialogAsync(): Promise<void> {
    const verb = VerbId.ExportPowerpoint;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'displayDialogAsync(ExportPowerPoint)',
    };
    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }

  public displayExportDataDialogAsync(): Promise<void> {
    const verb = VerbId.ExportData;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'displayDialogAsync(ExportData)',
    };
    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }

  public displayExportCrosstabDialogAsync(): Promise<void> {
    const verb = VerbId.ExportCrosstab;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'displayDialogAsync(ExportCrossTab)',
    };
    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }

  public displayShareDialogAsync(): Promise<void> {
    const verb = VerbId.Share;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'displayDialogAsync(Share)',
    };
    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }
}
