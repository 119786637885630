import { ApiMenuType, ContextMenuOptions } from '@tableau/api-external-contract-js';
import { ExecuteParameters, ParameterId, VerbId } from '@tableau/api-internal-contract-js';
import { ExternalContextMenuService } from '../ExternalContextMenuService';
import { ServiceNames } from '../ServiceRegistry';
import { ServiceImplBase } from './ServiceImplBase';

export class ExternalContextMenuServiceImpl extends ServiceImplBase implements ExternalContextMenuService {
  public get serviceName(): string {
    return ServiceNames.ExternalContextMenu;
  }

  public appendContextMenuAsync(sheetName: string, targetMenu: ApiMenuType, config: ContextMenuOptions): Promise<string> {
    const verb = VerbId.AppendExternalMenuItem;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'appendContextMenuAsync',
      [ParameterId.WorksheetName]: sheetName,
      [ParameterId.ExternalMenuItemDisplayName]: config.displayName,
    };

    return this.execute(verb, parameters).then<string>((response) => {
      const newMenuItemId = response.result as string;
      return newMenuItemId;
    });
  }

  public removeContextMenuAsync(sheetName: string, targetMenu: ApiMenuType, menuItemId: string): Promise<void> {
    const verb = VerbId.RemoveExternalMenuItem;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'removeContextMenuAsync',
      [ParameterId.WorksheetName]: sheetName,
      [ParameterId.ExternalMenuItemId]: menuItemId,
    };

    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }

  public executeContextMenuAsync(sheetName: string, targetMenu: ApiMenuType, menuItemId: string): Promise<void> {
    const verb = VerbId.ExecuteExternalMenuItem;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'executeContextMenuAsync',
      [ParameterId.WorksheetName]: sheetName,
      [ParameterId.ExternalMenuItemId]: menuItemId,
    };

    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }

  public renameContextMenuAsync(sheetName: string, targetMenu: ApiMenuType, menuHeader: string, menuDescription: string): Promise<void> {
    const verb = VerbId.RenameExternalMenu;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'renameContextMenuAsync',
      [ParameterId.WorksheetName]: sheetName,
      [ParameterId.ExternalMenuHeader]: menuHeader,
      [ParameterId.ExternalMenuDescription]: menuDescription,
    };

    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }
}
