import * as Contract from '@tableau/api-external-contract-js';
import { SheetPath } from '@tableau/api-internal-contract-js';
import { ClientInfoService } from '../Services/ClientInfoService';
import { ParametersService } from '../Services/ParametersService';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { SizeService } from '../Services/SizeService';
import { TableauError } from '../TableauError';
import { ErrorHelpers } from '../Utils/ErrorHelpers';
import { Param } from '../Utils/Param';
import { SheetUtils } from '../Utils/SheetUtils';
import { ParameterImpl } from './ParameterImpl';
import { SheetInfoImpl } from './SheetInfoImpl';

interface PartialSheetSize {
  /**
   * Contains an enumeration value of one of the following: AUTOMATIC, EXACTLY, RANGE, ATLEAST, and ATMOST.
   */
  readonly behavior: Contract.SheetSizeBehavior;

  /**
   *  This is only defined when behavior is EXACTLY, RANGE, or ATLEAST.
   */
  readonly minSize?: Partial<Contract.Size>;

  /**
   *  This is only defined when behavior is EXACTLY, RANGE or ATMOST.
   */
  readonly maxSize?: Partial<Contract.Size>;
}

export class SheetImpl {
  public constructor(protected _sheetInfoImpl: SheetInfoImpl, protected _registryId: number) {}

  public get name(): string {
    return this._sheetInfoImpl.name;
  }

  public get sheetType(): Contract.SheetType {
    return this._sheetInfoImpl.sheetType;
  }

  public get sheetPath(): SheetPath {
    return this._sheetInfoImpl.sheetPath;
  }

  public get size(): Contract.Size | Contract.SheetSize {
    return this._sheetInfoImpl.sheetSize;
  }

  public get hidden(): boolean {
    if (this._sheetInfoImpl.isHidden !== undefined) {
      return this._sheetInfoImpl.isHidden;
    }
    throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError, `isHidden not implemented`);
  }

  public get active(): boolean {
    if (this._sheetInfoImpl.isActive !== undefined) {
      return this._sheetInfoImpl.isActive;
    }
    throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError, `active not implemented`);
  }

  public set active(active: boolean) {
    if (this._sheetInfoImpl.isActive !== undefined) {
      this._sheetInfoImpl.active = active;
    }
  }

  public get index(): number {
    if (this._sheetInfoImpl.index !== undefined) {
      return this._sheetInfoImpl.index;
    }
    throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError, `index not implemented`);
  }

  public get url(): string {
    if (this._sheetInfoImpl.url !== undefined) {
      return this._sheetInfoImpl.url;
    }
    throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError, `url not implemented`);
  }

  private getSheetSize(): Contract.SheetSize {
    if (!SheetUtils.isValidSheetSize(this.size)) {
      throw new TableauError(Contract.EmbeddingErrorCodes.InternalError, 'size is not of type SheetSize');
    }

    return this.size;
  }

  public findParameterAsync(parameterName: string): Promise<ParameterImpl | undefined> {
    ErrorHelpers.verifyParameter(parameterName, 'parameterName');

    const service = ApiServiceRegistry.get(this._registryId).getService<ParametersService>(ServiceNames.Parameters);
    return service.findParameterByNameAsync(parameterName);
  }

  public getParametersAsync(): Promise<Array<ParameterImpl>> {
    const service = ApiServiceRegistry.get(this._registryId).getService<ParametersService>(ServiceNames.Parameters);
    return service.getParametersForSheetAsync(this.sheetPath);
  }

  public changeSizeAsync(newSize: Contract.SheetSize): Promise<Contract.SheetSize> {
    const invalidSizeError = new TableauError(Contract.EmbeddingErrorCodes.InvalidSize, 'Invalid sheet size parameter');
    if (!newSize || !newSize.behavior) {
      throw invalidSizeError;
    }

    const partialSheetSize = this.normalizeSheetSize(newSize);

    const isAutomatic = partialSheetSize.behavior === Contract.SheetSizeBehavior.Automatic;
    if (!isAutomatic && !partialSheetSize.minSize && !partialSheetSize.maxSize) {
      throw invalidSizeError;
    }

    if (!isAutomatic && this.sheetType === Contract.SheetType.Worksheet) {
      throw new TableauError(
        Contract.EmbeddingErrorCodes.InvalidSizeBehaviorOnWorksheet,
        'Only SheetSizeBehavior.Automatic is allowed on Worksheets',
      );
    }

    if (isAutomatic && this.getSheetSize().behavior === partialSheetSize.behavior) {
      return Promise.resolve(newSize);
    }

    const processedNewSize = this.processNewSize(partialSheetSize);

    const sizeService = ApiServiceRegistry.get(this._registryId).getService<SizeService>(ServiceNames.Size);
    return sizeService.changeSizeAsync(this.name, processedNewSize).then(() => {
      const clientInfoService = ApiServiceRegistry.get(this._registryId).getService<ClientInfoService>(ServiceNames.ClientInfo);

      return clientInfoService.getClientInfoAsync().then((bootstrapInfo) => {
        const sheet = bootstrapInfo.publishedSheets.find((s) => s.name === this.name);
        if (!sheet) {
          throw new TableauError(Contract.SharedErrorCodes.InternalError, `Can't find sheet with name ${this.name}`);
        }

        const sheetSize = SheetUtils.getSheetSizeFromSizeConstraints(sheet.sizeConstraint);
        this._sheetInfoImpl.sheetSize = sheetSize;

        return sheetSize;
      });
    });
  }

  private normalizeSheetSize(newSize: Contract.SheetSize): PartialSheetSize {
    const { behavior } = newSize;

    ErrorHelpers.verifyEnumValue<Contract.SheetSizeBehavior>(behavior, Contract.SheetSizeBehavior, 'SheetSizeBehavior');

    const minSize = SheetImpl.parseDimensions(newSize.minSize);
    const maxSize = SheetImpl.parseDimensions(newSize.maxSize);

    return { behavior, minSize, maxSize };
  }

  private processNewSize(newSize: PartialSheetSize): Contract.SheetSize {
    const { behavior, minSize: minSizeMaybe, maxSize: maxSizeMaybe } = newSize;

    const hasMinWidth = !Param.isNullOrUndefined(minSizeMaybe?.width);
    const hasMinHeight = !Param.isNullOrUndefined(minSizeMaybe?.height);
    const hasMaxWidth = !Param.isNullOrUndefined(maxSizeMaybe?.width);
    const hasMaxHeight = !Param.isNullOrUndefined(maxSizeMaybe?.height);
    const hasValidMinSize = hasMinWidth && hasMinHeight;
    const hasValidMaxSize = hasMaxWidth && hasMaxHeight;

    switch (behavior) {
      case Contract.SheetSizeBehavior.Automatic: {
        return { behavior };
      }

      case Contract.SheetSizeBehavior.AtMost: {
        if (!maxSizeMaybe || !hasValidMaxSize) {
          throw new TableauError(Contract.EmbeddingErrorCodes.MissingMaxSize, 'Missing maxSize for SheetSizeBehavior.AtMost');
        }

        const maxSize = { width: maxSizeMaybe.width!, height: maxSizeMaybe.height! };
        if (maxSize.width < 0 || maxSize.height < 0) {
          throw new TableauError(Contract.EmbeddingErrorCodes.InvalidSize, 'Size value cannot be less than zero');
        }

        return { behavior, maxSize };
      }

      case Contract.SheetSizeBehavior.AtLeast: {
        if (!minSizeMaybe || !hasValidMinSize) {
          throw new TableauError(Contract.EmbeddingErrorCodes.MissingMinSize, 'Missing minSize for SheetSizeBehavior.AtLeast');
        }

        const minSize = { width: minSizeMaybe.width!, height: minSizeMaybe.height! };
        if (minSize.width < 0 || minSize.height < 0) {
          throw new TableauError(Contract.EmbeddingErrorCodes.InvalidSize, 'Size value cannot be less than zero');
        }

        return { behavior, minSize };
      }

      case Contract.SheetSizeBehavior.Range: {
        if (!minSizeMaybe || !maxSizeMaybe || !hasValidMinSize || !hasValidMaxSize) {
          throw new TableauError(Contract.EmbeddingErrorCodes.MissingMinMaxSize, 'Missing minSize or maxSize for SheetSizeBehavior.Range');
        }

        const minSize = { width: minSizeMaybe.width!, height: minSizeMaybe.height! };
        const maxSize = { width: maxSizeMaybe.width!, height: maxSizeMaybe.height! };

        if (
          minSize.width < 0 ||
          minSize.height < 0 ||
          maxSize.width < 0 ||
          maxSize.height < 0 ||
          minSize.width > maxSize.width ||
          minSize.height > maxSize.height
        ) {
          throw new TableauError(Contract.EmbeddingErrorCodes.InvalidSize, 'Missing minSize or maxSize for SheetSizeBehavior.Range');
        }

        return { behavior, minSize, maxSize };
      }

      case Contract.SheetSizeBehavior.Exactly: {
        if (minSizeMaybe && maxSizeMaybe) {
          if (hasValidMinSize && hasValidMaxSize) {
            const minSize = { width: minSizeMaybe.width!, height: minSizeMaybe.height! };
            const maxSize = { width: maxSizeMaybe.width!, height: maxSizeMaybe.height! };

            if (minSize.width !== maxSize.width || minSize.height !== maxSize.height) {
              throw new TableauError(Contract.EmbeddingErrorCodes.InvalidSize, 'Conflicting size values for SheetSizeBehavior.Exactly');
            }

            return { behavior, minSize, maxSize };
          }

          if (hasValidMinSize) {
            const minSize = { width: minSizeMaybe.width!, height: minSizeMaybe.height! };
            return { behavior, minSize, maxSize: minSize };
          }

          if (hasValidMaxSize) {
            const maxSize = { width: maxSizeMaybe.width!, height: maxSizeMaybe.height! };
            return { behavior, minSize: maxSize, maxSize };
          }
        }

        throw new TableauError(Contract.EmbeddingErrorCodes.InvalidSize, 'Invalid sheet size parameter');
      }

      default: {
        throw new TableauError(Contract.SharedErrorCodes.InternalError, `Unsupported sheet size behavior: ${behavior}`);
      }
    }
  }

  private static parseDimensions = (size: Contract.Size | undefined): Partial<Contract.Size> => {
    const empty = { width: undefined, height: undefined };

    if (!size) {
      return empty;
    }

    const { success: widthParsed, parsed: parsedWidth } = Param.tryParseNumber(size.width);
    const { success: heightParsed, parsed: parsedHeight } = Param.tryParseNumber(size.height);

    if (widthParsed && heightParsed) {
      return { width: parsedWidth!, height: parsedHeight! };
    }

    if (widthParsed) {
      return { width: parsedWidth! };
    }

    if (heightParsed) {
      return { height: parsedHeight! };
    }

    return empty;
  };
}
