import * as Contract from '@tableau/api-external-contract-js';
import { CustomViewImpl, DashboardImpl, SheetInfoImpl, StoryImpl, TableauError, WorksheetImpl } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { CustomView } from './CustomView';
import { Dashboard } from './Dashboard';
import { Parameter } from './Parameter';
import { SheetInfo } from './SheetInfo';
import { Story } from './Story';
import { Worksheet } from './Worksheet';

export class Workbook implements Contract.EmbeddingWorkbook {
  public constructor(private _workbookImpl: EmbeddingWorkbookImpl) {}

  public get activeSheet(): Contract.EmbeddingSheet {
    switch (this._workbookImpl.activeSheet.sheetType) {
      case Contract.SheetType.Worksheet: {
        return new Worksheet(this._workbookImpl.activeSheet as WorksheetImpl, this._workbookImpl);
      }
      case Contract.SheetType.Dashboard: {
        return new Dashboard(this._workbookImpl.activeSheet as DashboardImpl, this._workbookImpl);
      }
      case Contract.SheetType.Story: {
        return new Story(this._workbookImpl.activeSheet as StoryImpl, this._workbookImpl);
      }
      default: {
        throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError, 'Unable to initialize ActiveSheet');
      }
    }
  }

  public get publishedSheetsInfo(): Array<Contract.SheetInfo> {
    return this._workbookImpl.publishedSheetsInfo.map((sheetInfoImpl: SheetInfoImpl) => {
      return new SheetInfo(sheetInfoImpl, this._workbookImpl);
    });
  }

  public get name(): string {
    return this._workbookImpl.name;
  }

  public get activeCustomView(): Contract.CustomView | undefined {
    if (this._workbookImpl.activeCustomView) {
      return new CustomView(this._workbookImpl.activeCustomView, this._workbookImpl);
    } else {
      return undefined;
    }
  }

  public activateSheetAsync(sheetNameOrIndex: string | number): Promise<Contract.EmbeddingSheet> {
    return this._workbookImpl.activateSheetAsync(sheetNameOrIndex).then((workbookImpl: EmbeddingWorkbookImpl) => {
      if (this._workbookImpl !== workbookImpl) {
        this._workbookImpl = workbookImpl;
      }
      return this.activeSheet;
    });
  }

  public revertAllAsync(): Promise<void> {
    return this._workbookImpl.revertAllAsync();
  }

  public getCustomViewsAsync(): Promise<Array<Contract.CustomView>> {
    return this._workbookImpl.getCustomViewsAsync().then((customViewsImpl: Array<CustomViewImpl>) => {
      return customViewsImpl.map((customViewImpl) => {
        return new CustomView(customViewImpl, this._workbookImpl);
      });
    });
  }

  public showCustomViewAsync(customViewName: string): Promise<Contract.CustomView> {
    return this._workbookImpl.showCustomViewAsync(customViewName).then((customViewImpl: CustomViewImpl) => {
      return new CustomView(customViewImpl, this._workbookImpl);
    });
  }

  public removeCustomViewAsync(customViewName: string): Promise<Contract.CustomView> {
    return this._workbookImpl.removeCustomViewAsync(customViewName).then((customViewImpl: CustomViewImpl) => {
      return new CustomView(customViewImpl, this._workbookImpl);
    });
  }

  public saveCustomViewAsync(customViewName: string): Promise<Contract.CustomView> {
    return this._workbookImpl.saveCustomViewAsync(customViewName).then((customViewImpl: CustomViewImpl) => {
      return new CustomView(customViewImpl, this._workbookImpl);
    });
  }

  public setActiveCustomViewAsDefaultAsync(): Promise<void> {
    return this._workbookImpl.setActiveCustomViewAsDefaultAsync();
  }

  public getParametersAsync(): Promise<Array<Contract.EmbeddingParameter>> {
    return this._workbookImpl.getParametersAsync().then((parametersImpl) => {
      return parametersImpl.map((parameterImpl) => {
        return new Parameter(parameterImpl);
      });
    });
  }

  public changeParameterValueAsync(name: string, value: string | number | boolean | Date): Promise<Contract.EmbeddingParameter> {
    return this._workbookImpl.changeParameterValueAsync(name, value).then((parameterImpl) => {
      if (parameterImpl) {
        return new Parameter(parameterImpl);
      } else {
        throw new TableauError(Contract.EmbeddingErrorCodes.InvalidParameter, `Invalid parameter:  ${name}`);
      }
    });
  }
}
