import { ErrorCodes } from '@tableau/api-external-contract-js';
import { ExecuteParameters, ExecuteResponse, InternalApiDispatcher, InternalTableauError, VerbId } from '@tableau/api-internal-contract-js';
import { InternalToExternalEnumMappings } from '../../EnumMappings/InternalToExternalEnumMappings';
import { TableauError } from '../../TableauError';
import { ShouldThrow } from '../../Utils/EnumConverter';

/**
 * Each ServceImpl should extend this base class for the sake of
 * proper error handling.  This base handles the conversion
 * from internal errors to external errors that we throw to developers
 */
export class ServiceImplBase {
  public constructor(private _dispatcher: InternalApiDispatcher, protected _registryId: number) {}

  protected execute(verb: VerbId, params: ExecuteParameters): Promise<ExecuteResponse> {
    return this._dispatcher.execute(verb, params).catch((error) => {
      // Any internal error that comes from the dispatcher should be converted
      // to an external error using the enum mapper for error codes.
      const internalError = error as InternalTableauError;
      const externalErrorCode: ErrorCodes = InternalToExternalEnumMappings.errorCode.convert(internalError.errorCode, ShouldThrow.No);
      throw new TableauError(externalErrorCode, internalError.message);
    });
  }
}
