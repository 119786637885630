import * as Contract from '@tableau/api-external-contract-js';
import { EmbeddingTableauEventType } from '@tableau/api-external-contract-js';
import { TableauSheetEvent } from './TableauSheetEvent';

export class TableauWorksheetEvent extends TableauSheetEvent implements Contract.EmbeddingTableauWorksheetEvent {
  public get worksheet(): Contract.EmbeddingWorksheet {
    return this._worksheet;
  }

  public constructor(type: EmbeddingTableauEventType, protected _worksheet: Contract.EmbeddingWorksheet) {
    super(type, _worksheet);
  }
}
