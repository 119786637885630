export enum ExtensionContext {
  Desktop = 'desktop',
  Server = 'server',
  Unknown = 'unknown',
}

export enum ExtensionMode {
  Authoring = 'authoring',
  Viewing = 'viewing',
  Unknown = 'unknown',
}

export enum AnnotateEnum {
  Mark = 'mark',
  Point = 'point',
  Area = 'area',
}

export enum ColumnType {
  Discrete = 'discrete',
  Continuous = 'continuous',
}

export enum DashboardObjectType {
  Blank = 'blank',
  Worksheet = 'worksheet',
  QuickFilter = 'quick-filter',
  ParameterControl = 'parameter-control',
  PageFilter = 'page-filter',
  Legend = 'legend',
  Title = 'title',
  Text = 'text',
  Image = 'image',
  WebPage = 'web-page',
  Extension = 'extension',
}

export enum DataType {
  String = 'string',
  Int = 'int',
  Float = 'float',
  Bool = 'bool',
  Date = 'date',
  DateTime = 'date-time',
  Spatial = 'spatial',
}

export enum DialogStyle {
  Window = 'window',
  Modal = 'modal',
  Modeless = 'modeless',
}

export enum EncodedDataType {
  Number = 'number',
  String = 'string',
  Date = 'date',
  Boolean = 'boolean',
}

export enum ErrorCodes {
  INITIALIZATION_ERROR = 'initialization-error',
  INTERNAL_ERROR = 'internal-error',
  MISSING_ENUM_MAPPING = 'missing-enum-mapping',
  MISSING_PARAMETER = 'missing-parameter',
  PERMISSION_DENIED = 'permission-denied',
  PRES_MODEL_PARSING_ERROR = 'pres-model-parsing-error',
  VERSION_NOT_CONFIGURED = 'version-not-configured',
  VISIBILITY_ERROR = 'visibility-error',
  UNKNOWN_VERB_ID = 'unknown-verb-id',
}

export enum FieldAggregationType {
  Sum = 'sum',
  Avg = 'avg',
  Min = 'min',
  Max = 'max',
  Stdev = 'stdev',
  Stdevp = 'stdevp',
  Var = 'var',
  Varp = 'varp',
  Count = 'count',
  Countd = 'countd',
  Median = 'median',
  Attr = 'attr',
  None = 'none',
  Year = 'year',
  Qtr = 'qtr',
  Month = 'month',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Second = 'second',
  Week = 'week',
  Weekday = 'weekday',
  MonthYear = 'month-year',
  Mdy = 'mdy',
  End = 'end',
  TruncYear = 'trunc-year',
  TruncQtr = 'trunc-qtr',
  TruncMonth = 'trunc-month',
  TruncWeek = 'trunc-week',
  TruncDay = 'trunc-day',
  TruncHour = 'trunc-hour',
  TruncMinute = 'trunc-minute',
  TruncSecond = 'trunc-second',
  Quart1 = 'quart1',
  Quart3 = 'quart3',
  Skewness = 'skewness',
  Kurtosis = 'kurtosis',
  InOut = 'in-out',
  User = 'user',
}

export enum FieldRoleType {
  Dimension = 'dimension',
  Measure = 'measure',
  Unknown = 'unknown',
}

/**
 *  The different update types for applying filter.
 */
export enum FilterUpdateType {
  Add = 'add',
  All = 'all',
  Replace = 'replace',
  Remove = 'remove',
}

export enum SheetType {
  Dashboard = 'dashboard',
  Story = 'story',
  Worksheet = 'worksheet',
}

export enum DomainRestrictionType {
  All = 'all',
  List = 'list',
  Range = 'range',
}

export enum DateStepPeriod {
  Years = 'years',
  Quarters = 'quarters',
  Months = 'months',
  Weeks = 'weeks',
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Seconds = 'seconds',
}

/**
 * The option for specifying which values to include for filtering.
 */
export enum FilterNullOption {
  NullValues = 'nullvalues',
  NonNullValues = 'nonnullvalues',
  AllValues = 'allvalues',
}

/**
 * The type of filter domain
 */
export enum FilterDomainType {
  Relevant = 'relevant',
  Database = 'database',
}

/**
 * Internal enum for specifying the selection type for select marks api.
 */
export enum SelectionUpdateType {
  Replace = 'select-replace',
  Add = 'select-add',
  Remove = 'select-remove',
}

/**
 * Internal enum for specifying the included values type for range selection.
 */
export enum QuantitativeIncludedValues {
  IncludeNull = 'include-null',
  IncludeNonNull = 'include-non-null',
  IncludeAll = 'include-all',
}

/**
 * Internal enum for specifying a hierarchical level selection state.
 */
export enum HierarchicalLevelSelectionState {
  AllSelected = 'all-selected',
  NoneSelected = 'none-selected',
  SomeSelected = 'some-selected',
  UnknownSelected = 'unknown-selected',
}

/**
 * Type of mark for a given marks card in a viz.
 */
export enum MarkType {
  Bar = 'bar',
  Line = 'line',
  Area = 'area',
  Square = 'square',
  Circle = 'circle',
  Shape = 'shape',
  Text = 'text',
  Map = 'map',
  Pie = 'pie',
  GanttBar = 'gantt-bar',
  Polygon = 'polygon',
}

/**
 * Internal enum for specifying the type of filter
 */
export enum FilterType {
  Categorical = 'categorical',
  Range = 'range',
  RelativeDate = 'relativeDate',
  Hierarchical = 'hierarchical',
}

/**
 * Internal enum for specifying the DateRangeType of a relative date filter
 */
export enum DateRangeType {
  /**
   * Refers to the last day, week, month, etc. of the date period.
   */
  Last = 'last',
  /**
   * Refers to the last N days, weeks, months, etc. of the date period.
   */
  LastN = 'lastN',
  /**
   * Refers to the next day, week, month, etc. of the date period.
   */
  Next = 'next',
  /**
   * Refers to the next N days, weeks, months, etc. of the date period.
   */
  NextN = 'nextN',
  /**
   * Refers to the current day, week, month, etc. of the date period.
   */
  Current = 'current',
  /**
   * Refers to everything up to and including the current day, week, month, etc. of the date period.
   */
  ToDate = 'toDate',
}

/**
 * Used to determine if the launching of an extension dialog succeeded or failed.
 */
export enum ExtensionDialogResult {
  DialogAlreadyOpen = 'dialog-already-open',
  InvalidDomain = 'invalid-domain',
  Success = 'success',
}

// Enumeration for ApiShowData table id sentinels.
export enum ApiShowDataTableSentinel {
  // find the one logical table - used in version compatibility check for getUnderlyingDataTable and getLogicalDataTable commands
  SingleTableId = 'single-table-id-sentinel',
  SingleTableCaption = 'Single table id sentinel',
}

/**
 * Used to optimize the getSummaryData/getUnderlyingTableData results
 * Mapped from external IncludeDataValuesOption
 */
export enum ApiShowDataTableFormat {
  NativeValuesOnly = 'native-values-only',
  FormattedValuesOnly = 'formatted-values-only',
  NativeAndFormattedValues = 'native-and-formatted-values',
}

/**
 * Used to denote the sections of formatting within tableau
 */
export const enum ClassNameKey {
  WorksheetTitle = 'tableau-worksheet-title',
  Worksheet = 'tableau-worksheet',
  Tooltip = 'tableau-tooltip',
  StoryTitle = 'tableau-story-title',
  DashboardTitle = 'tableau-dashboard-title',
}
