import * as guid from 'guid';
import { NotificationId } from '../contract/Notifications';
import { VersionNumber } from '../interface/VersionNumber';
import {
  CommandMessage,
  CommandResponseMessage,
  HandshakeMessage,
  InitializeMessage,
  Message,
  MessageType,
  NotificationMessage,
} from './interface/MessageTypes';

const expectedEmptyNotifications = new Set([
  NotificationId.EditButtonClicked,
  NotificationId.EditInDesktopButtonClicked,
  NotificationId.WorkbookPublished,
  NotificationId.WorkbookReadyToClose,
]);

/* tslint:disable no-any */
export function isMessage(data: Message | any): data is Message {
  if (!data) {
    return false;
  }

  const message = data as Message;
  if (!message || !message.msgGuid || !message.msgType) {
    return false;
  }

  if (!guid.isGuid(message.msgGuid)) {
    return false;
  }

  if (typeof message.msgType !== 'string') {
    return false;
  }

  const messageTypes: Array<string> = [
    MessageType.Command,
    MessageType.CommandResponse,
    MessageType.Initialize,
    MessageType.Notification,
    MessageType.Handshake,
  ];

  if (messageTypes.indexOf(message.msgType) < 0) {
    return false;
  }

  return true;
}

export function isVersion(versionNumber: VersionNumber | any): versionNumber is VersionNumber {
  if (!versionNumber) {
    return false;
  }

  const v = versionNumber as VersionNumber;

  if (typeof v !== 'object') {
    return false;
  }

  if (typeof v.fix !== 'number' || typeof v.minor !== 'number' || typeof v.major !== 'number') {
    return false;
  }

  return true;
}

export function isInitMessage(message: InitializeMessage | any): message is InitializeMessage {
  if (!isMessage(message)) {
    return false;
  }

  const initMessage = message as InitializeMessage;
  if (initMessage.msgType !== MessageType.Initialize) {
    return false;
  }

  if (!initMessage.apiVersion || !isVersion(initMessage.apiVersion)) {
    return false;
  }

  if (!initMessage.crossFrameVersion || !isVersion(initMessage.crossFrameVersion)) {
    return false;
  }

  return true;
}

export function isCommandResponseMessage(message: CommandResponseMessage | any): message is CommandResponseMessage {
  if (!isMessage(message)) {
    return false;
  }

  const crMessage = message as CommandResponseMessage;
  if (crMessage.msgType !== MessageType.CommandResponse) {
    return false;
  }

  if (!guid.isGuid(crMessage.commandGuid)) {
    return false;
  }

  if (!crMessage.data && !crMessage.error) {
    return false;
  }

  return true;
}

export function isCommandMessage(message: CommandMessage | any): message is CommandMessage {
  if (!isMessage(message)) {
    return false;
  }

  const commandMessage = message as CommandMessage;
  if (commandMessage.msgType !== MessageType.Command) {
    return false;
  }

  if (!commandMessage.parameters || typeof commandMessage.parameters !== 'object') {
    return false;
  }

  if (!commandMessage.verbId || typeof commandMessage.verbId !== 'string') {
    return false;
  }

  return true;
}

export function isNotificationMessage(message: NotificationMessage | any): message is NotificationMessage {
  if (!isMessage(message)) {
    return false;
  }

  const notificationMessage = message as NotificationMessage;
  if (notificationMessage.msgType !== MessageType.Notification) {
    return false;
  }

  if (!notificationMessage.data && !expectedEmptyNotifications.has(notificationMessage.notificationId)) {
    return false;
  }

  if (!notificationMessage.notificationId || typeof notificationMessage.notificationId !== 'string') {
    return false;
  }

  return true;
}

export function isHandshakeMessage(message: HandshakeMessage | any): message is HandshakeMessage {
  if (!isMessage(message)) {
    return false;
  }

  const handshakeMessage = message as HandshakeMessage;
  if (handshakeMessage.msgType !== MessageType.Handshake) {
    return false;
  }

  return true;
}
