import { ErrorCodes } from '@tableau/api-external-contract-js';
import { ExecuteParameters, Model, ParameterId, ParameterInfo, SheetPath, VerbId } from '@tableau/api-internal-contract-js';
import { ParameterImpl } from '../../Impl/ParameterImpl';
import { TableauError } from '../../TableauError';
import { ParametersService } from '../ParametersService';
import { ServiceNames } from '../ServiceRegistry';
import { ServiceImplBase } from './ServiceImplBase';

export class ParametersServiceImpl extends ServiceImplBase implements ParametersService {
  public get serviceName(): string {
    return ServiceNames.Parameters;
  }

  public getParametersForSheetAsync(sheetPath: SheetPath): Promise<Array<ParameterImpl>> {
    const parameters = {
      [ParameterId.FunctionName]: 'getParametersForSheetAsync',
      [ParameterId.SheetPath]: sheetPath,
    };

    return this.getParametersAsync(parameters);
  }

  public getAllParametersAsync(): Promise<Array<ParameterImpl>> {
    const parameters = {
      [ParameterId.FunctionName]: 'getParametersForSheetAsync',
    };

    return this.getParametersAsync(parameters);
  }

  private getParametersAsync(inputParameters: ExecuteParameters): Promise<Array<ParameterImpl>> {
    return this.execute(VerbId.GetParametersForSheet, inputParameters).then((response) => {
      const result = response.result as Array<ParameterInfo>;
      return result.map((parameterInfo) => {
        return new ParameterImpl(parameterInfo, this._registryId);
      });
    });
  }

  public changeParameterValueAsync(fieldName: string, newValue: string): Promise<ParameterInfo> {
    const parameters = {
      [ParameterId.FunctionName]: 'changeParameterValueAsync',
      [ParameterId.ParameterFieldName]: fieldName,
      [ParameterId.ParameterValue]: newValue,
    };

    return this.execute(VerbId.ChangeParameterValue, parameters).then((response) => {
      const result = response.result as ParameterInfo;
      return result;
    });
  }

  public findParameterByNameAsync(name: string): Promise<ParameterImpl | undefined> {
    return this.findParameterAsync(name, undefined);
  }

  public findParameterByGlobalFieldNameAsync(fieldName: string): Promise<ParameterImpl | undefined> {
    return this.findParameterAsync(undefined, fieldName);
  }

  private findParameterAsync(name: string | undefined, fieldName: string | undefined): Promise<ParameterImpl | undefined> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'findParameterAsync',
    };
    if (name !== undefined) {
      parameters[ParameterId.ParameterCaption] = name;
    } else if (fieldName !== undefined) {
      parameters[ParameterId.ParameterFieldName] = fieldName;
    } else {
      throw new TableauError(ErrorCodes.InvalidParameter, 'name or fieldName must be provided to find parameter');
    }

    return this.execute(VerbId.FindParameter, parameters).then((response) => {
      const instanceOfParameterInfo = (object: Model): object is ParameterInfo => {
        return 'fieldName' in object;
      };

      // We need to check to see if we got a valid response back again
      if (instanceOfParameterInfo(response.result)) {
        const result = response.result;
        return new ParameterImpl(result, this._registryId);
      } else {
        return undefined;
      }
    });
  }
}
