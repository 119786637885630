import * as Contract from '@tableau/api-external-contract-js';

export class UrlActionEvent implements Contract.UrlActionEvent {
  public constructor(private _url: string, private _target: string) {}

  public get url() {
    return this._url;
  }

  public get target() {
    return this._target;
  }
}
