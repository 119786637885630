/**
 * Helper methods for {@link HTMLElement}.
 *
 * @class HtmlElementHelpers
 */
export class HtmlElementHelpers {
  /**
   * Calculates the size of element's content area, which is the area that you'd expect a
   * child element to occupy to fill the available space (the area after padding and borders
   * are subtracted out). This is equivalent in behavior to jQuery's width() and height()
   * functions. See {@link http://api.jquery.com/width/}.
   *
   * @static
   * @param {HTMLElement} element
   * @see workgroup\vqlweb\scriptsharp\src\ApiShared\Internal\Utility.cs
   */
  static getContentSize = (element: HTMLElement): { width: number; height: number } => {
    const style = window.getComputedStyle(element);
    const paddingLeft = parseInt(style.paddingLeft, 10) || 0;
    const paddingTop = parseInt(style.paddingTop, 10) || 0;
    const paddingRight = parseInt(style.paddingRight, 10) || 0;
    const paddingBottom = parseInt(style.paddingBottom, 10) || 0;

    // clientWidth/Height includes padding, but excludes margin and borders and scrollbars,
    // but we also want padding excluded.
    const width = element.clientWidth - Math.round(paddingLeft + paddingRight);
    const height = element.clientHeight - Math.round(paddingTop + paddingBottom);

    return { width, height };
  };
}
