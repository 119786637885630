import * as Contract from '@tableau/api-external-contract-js';
import { DashboardObjectImpl } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { Dashboard } from './Dashboard';
import { Worksheet } from './Worksheet';

/**
 * Implementation of the dashboard objects - the zones in a dashboard.
 */
export class DashboardObject implements Contract.EmbeddingDashboardObject {
  public constructor(private _dashboardObjectImpl: DashboardObjectImpl, private _workbookImpl: EmbeddingWorkbookImpl) {}

  public get dashboard(): Contract.EmbeddingDashboard {
    return new Dashboard(this._dashboardObjectImpl.dashboardImpl, this._workbookImpl);
  }

  public get type(): Contract.DashboardObjectType {
    return this._dashboardObjectImpl.type;
  }

  public get position(): Contract.Point {
    return this._dashboardObjectImpl.position;
  }

  public get size(): Contract.Size {
    return this._dashboardObjectImpl.size;
  }

  public get worksheet(): Contract.EmbeddingWorksheet | undefined {
    if (this._dashboardObjectImpl.worksheetImpl) {
      return new Worksheet(this._dashboardObjectImpl.worksheetImpl, this._workbookImpl);
    }
    return undefined;
  }

  public get name(): string {
    return this._dashboardObjectImpl.name;
  }

  public get isFloating(): boolean {
    return this._dashboardObjectImpl.isFloating;
  }

  public get isVisible(): boolean {
    return this._dashboardObjectImpl.isVisible;
  }

  public get id(): number {
    return this._dashboardObjectImpl.id;
  }
}
