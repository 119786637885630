import * as Contract from '@tableau/api-external-contract-js';
import { ContainedSheetInfoModel, SheetInfo, SheetPath, VisualId } from '@tableau/api-internal-contract-js';
import { TableauError } from '../TableauError';
import { SheetUtils } from '../Utils/SheetUtils';
import { DashboardImpl } from './DashboardImpl';
import { SheetImpl } from './SheetImpl';
import { SheetInfoImpl } from './SheetInfoImpl';
import { StoryImpl } from './StoryImpl';
import { StoryPointInfoImpl } from './StoryPointInfoImpl';
import { WorksheetImpl } from './WorksheetImpl';

export class StoryPointImpl {
  private _containedSheetImpl: SheetImpl;
  constructor(
    private _storyPointInfoImpl: StoryPointInfoImpl,
    sheetInfos: Array<SheetInfo>,
    registryId: number,
    containedSheetInfo?: ContainedSheetInfoModel,
  ) {
    if (containedSheetInfo) {
      this._containedSheetImpl = this.createContainedSheet(containedSheetInfo, sheetInfos, registryId);
    }
  }

  public get index(): number {
    return this._storyPointInfoImpl.index;
  }

  public get caption(): string {
    return this._storyPointInfoImpl.caption;
  }

  public get active(): boolean {
    return this._storyPointInfoImpl.active;
  }

  public get updated(): boolean {
    return this._storyPointInfoImpl.updated;
  }

  public set updated(updated: boolean) {
    this._storyPointInfoImpl.updated = updated;
  }

  public get parentStory(): StoryImpl {
    return this._storyPointInfoImpl.parentStory;
  }

  public get containedSheet(): SheetImpl | undefined {
    return this._containedSheetImpl;
  }

  public get storyPointId(): number {
    return this._storyPointInfoImpl.storyPointId;
  }

  private createContainedSheet(containedSheetInfo: ContainedSheetInfoModel, sheetInfos: Array<SheetInfo>, registryId: number): SheetImpl {
    const containedSheetIdx = -1;

    const publishedSheetInfo = SheetUtils.getPublishedSheetInfoByName(containedSheetInfo.name, sheetInfos);
    const containedSheetSize = SheetUtils.createAutomaticSize();
    const isContainedSheetHidden = publishedSheetInfo === undefined;
    const containedSheetUrl = publishedSheetInfo ? publishedSheetInfo.url : '';
    // Contained sheets aren't active - the containing sheet is active.
    const containedSheetActive = false;

    const containedSheetInfoImpl = new SheetInfoImpl(
      containedSheetInfo.name,
      SheetUtils.getSheetTypeEnum(containedSheetInfo.sheetType),
      containedSheetSize,
      containedSheetIdx,
      containedSheetActive,
      isContainedSheetHidden,
      containedSheetUrl,
    );

    let sheetImpl: SheetImpl;
    switch (containedSheetInfoImpl.sheetType) {
      case Contract.SheetType.Worksheet: {
        const vizId: VisualId = {
          worksheet: containedSheetInfoImpl.name,
        };

        sheetImpl = new WorksheetImpl(containedSheetInfoImpl, registryId, vizId, null, this);
        break;
      }
      case Contract.SheetType.Dashboard: {
        const sheetPath: SheetPath = {
          sheetName: containedSheetInfoImpl.name,
          isDashboard: true,
        };

        sheetImpl = new DashboardImpl(containedSheetInfoImpl, containedSheetInfo.dashboardZones, sheetPath, registryId, this);
        break;
      }
      default: {
        throw new TableauError(Contract.SharedErrorCodes.ServerError, 'Invalid SheetType');
      }
    }

    return sheetImpl;
  }
}
