import {
  CustomParameter,
  WebComponentAttributes,
  WebComponentChildElementAttributes,
  WebComponentChildElements,
} from '@tableau/api-external-contract-js';
import { HtmlElementHelpers } from '../Utils/HtmlElementHelpers';
import { WebComponentManager } from '../WebComponentManager';

export abstract class TableauWebComponent extends HTMLElement {
  // localized strings copied over from Strings.AccessibilityDataVisualizationTitleAttr
  // TFS 1287423: Enable loc pipeline
  private static localizedTitles: Record<string, string> = {
    en: 'Data Visualization',
    'en-GB': 'Data Visualisation',
    fr: 'Visualisation de donn\u00E9es',
    es: 'Visualizaci\u00F3n de datos',
    it: 'Visualizzazione dati',
    pt: 'Visualiza\u00E7\u00E3o de dados',
    ja: '\u30C7\u30FC\u30BF \u30D3\u30B8\u30E5\u30A2\u30E9\u30A4\u30BC\u30FC\u30B7\u30E7\u30F3',
    de: 'Datenvisualisierung',
    ko: '\uB370\uC774\uD130 \uBE44\uC8FC\uC5BC\uB9AC\uC81C\uC774\uC158',
    'zh-CN': '\u6570\u636E\u53EF\u89C6\u5316',
    'zh-TW': '\u8CC7\u6599\u53EF\u8996\u5316',
  };

  public static AttributeDefaults = {
    width: '800px',
    height: '600px',
  };
  protected _iframe: HTMLIFrameElement;
  protected _connected = false;
  protected _embeddingIdCounter = 0;
  private _fixedSize = false;

  protected abstract updateRendering(): void;
  protected abstract updateRenderingIfConnected();

  // https://html.spec.whatwg.org/multipage/custom-elements.html#custom-element-conformance
  public constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  public disconnectedCallback(): void {
    if (this._iframe) {
      this.shadowRoot?.removeChild(this._iframe);
    }
    WebComponentManager.unregisterWebComponent(this._embeddingIdCounter);
    this._connected = false;
  }

  public connectedCallback(): void {
    if (document.readyState === 'loading') {
      // Loading hasn't finished yet
      document.addEventListener('DOMContentLoaded', () => {
        this.initialize();
      });
    } else {
      // `DOMContentLoaded` has already fired
      this.initialize();
    }
  }

  /**
   * Invoked each time one of the custom element's attributes is added, removed, or changed.
   * @param  {string} name - The name of the attribute.
   * @param  {string|null} oldValue - The previous value of the attribute or null if the attribute was just added.
   * @param  {string|null} newValue - The new value of the attribute or null if the attribute was just removed.
   * @returns void
   */
  public attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (!oldValue && oldValue === newValue) {
      // A value-less attribute was reapplied.
      // e.g. hide-tabs=''
      return;
    }

    // if it's width/height, resize the frame
    // TFS 892487: Deal with sizing and scrollbars later
    if (name === WebComponentAttributes.Width || name === WebComponentAttributes.Height) {
      this.setFrameSize();
      return;
    }

    // When there is a change in the other observed attributes, let's unregister the Viz
    // and re-render the viz again with new attribute values
    this.updateRenderingIfConnected();
  }

  public static get observedAttributes(): string[] {
    // Take caution before adding to this list because for every observed attribute change
    // we unregister and re-render the viz
    return Object.values(WebComponentAttributes);
  }

  private initialize(): void {
    if (!this._connected) {
      // The tableau viz component must display as flex so that it is simply a container
      // for the iframe and doesn't take up any room from its children.
      this.style.display = 'flex';

      this.setupFrame();
      this.updateRendering();
      this._connected = true;
    }
  }

  public get fixedSize() {
    return this._fixedSize;
  }

  protected readCustomParamsFromChildren(): CustomParameter[] {
    const params: CustomParameter[] = [];

    Array.from(this.children).forEach((child) => {
      if (
        child.localName === WebComponentChildElements.CustomParameter &&
        child.getAttribute(WebComponentChildElementAttributes.Name) &&
        child.getAttribute(WebComponentChildElementAttributes.Value)
      ) {
        params.push({
          name: child.getAttribute(WebComponentChildElementAttributes.Name)!,
          value: child.getAttribute(WebComponentChildElementAttributes.Value)!,
        });
      }
    });

    return params;
  }

  public localizedTitle(lang): string {
    return (
      TableauWebComponent.localizedTitles[lang] ||
      TableauWebComponent.localizedTitles[lang.substr(0, 2)] ||
      TableauWebComponent.localizedTitles.en
    );
  }

  protected setupFrame(): void {
    this._iframe = document.createElement('iframe');

    const lang = navigator.language;
    const localizedTitle = this.localizedTitle(lang);
    // give context to users using screenreaders as to what kind of iframe they've entered
    this._iframe.setAttribute('title', localizedTitle);

    this._iframe.setAttribute('allowTransparency', 'true');
    this._iframe.setAttribute('allowFullScreen', 'true');

    // reset any box model styles
    this._iframe.style.margin = '0px';
    this._iframe.style.padding = '0px';
    this._iframe.style.border = 'none';
    this._iframe.style.position = 'relative';

    // set iframe name & id
    this._iframe.id = this.id;
    this._iframe.name = this.id;

    this.setFrameSize();

    if (this.shadowRoot) {
      this.shadowRoot.appendChild(this._iframe);
    }
  }

  protected setFrameSize(): void {
    if (this._iframe) {
      this._iframe.style.height = this.height;
      this._iframe.style.width = this.width;
    }
  }

  /**
   * Compute the height and width by checking for the existence of
   * 1. The height and width attributes on the element, and
   * 2. The window computed height and width of the parent element.
   * If neither are defined for both dimensions, then return the default values.
   * @returns height and width to be used in setting the iframe size.
   */

  private computeElementSize(): { width: string; height: string } {
    const heightAttr = this.getPixelAttribute(WebComponentAttributes.Height);
    const widthAttr = this.getPixelAttribute(WebComponentAttributes.Width);
    if (heightAttr && widthAttr) {
      this._fixedSize = true;
      return { height: heightAttr, width: widthAttr };
    }

    if (this.parentElement) {
      const { height, width } = HtmlElementHelpers.getContentSize(this.parentElement);

      if (height && width) {
        this._fixedSize = true;
        return { height: `${height}px`, width: `${width}px` };
      }
    }

    this._fixedSize = false;
    return { height: TableauWebComponent.AttributeDefaults.height, width: TableauWebComponent.AttributeDefaults.width };
  }

  //Simple Getters / Setters
  public get src(): string | null {
    return this.getAttribute(WebComponentAttributes.Src);
  }

  public set src(v: string | null) {
    if (v) {
      this.setAttribute(WebComponentAttributes.Src, v);
    }
  }

  public get width(): string {
    return this.computeElementSize().width;
  }

  // non-valid css lengths will simply turn into '' e.g a number with no units
  public set width(v: string) {
    this.setAttribute(WebComponentAttributes.Width, v);
  }

  public get height(): string {
    return this.computeElementSize().height;
  }

  // non-valid css lengths will simply turn into '' e.g a number with no units
  public set height(v: string) {
    this.setAttribute(WebComponentAttributes.Height, v);
  }

  public get debug(): boolean {
    return this.hasAttribute(WebComponentAttributes.Debug);
  }

  public set debug(v: boolean) {
    if (v) {
      this.setAttribute(WebComponentAttributes.Debug, '');
    } else {
      this.removeAttribute(WebComponentAttributes.Debug);
    }
  }

  public get token(): string | undefined {
    const tokenValue = this.getAttribute(WebComponentAttributes.Token);

    if (!tokenValue) {
      return undefined;
    }

    return tokenValue;
  }

  public set token(v: string | undefined) {
    if (v) {
      this.setAttribute(WebComponentAttributes.Token, v);
    } else {
      this.removeAttribute(WebComponentAttributes.Token);
    }
  }

  private getPixelAttribute(attributeName: string): string {
    const attr = this.getAttribute(attributeName);
    if (attr && attr !== '') {
      return isNaN(Number(attr)) ? attr : `${Math.round(Number(attr))}px`;
    } else {
      // if it was invalid css, it will be blank
      return '';
    }
  }

  public get iframe(): HTMLIFrameElement {
    return this._iframe;
  }
}
