/**
 * Selection Model.
 */
export class SelectionModel {
  public qualifiedFieldCaption: string;
}

/**
 * Value based selection model. Meant for hierarchical, range and categorical selections.
 */
export class ValueSelectionModel extends SelectionModel {
  public selectValues: Array<string> = [];
}

/**
 * Hierarchical value selection model
 */
export class HierarchicalSelectionModel extends ValueSelectionModel {}

/**
 * Range based value selection model
 */
export class RangeSelectionModel extends SelectionModel {
  public minValue: string;
  public maxValue: string;
  public included: string;
}

/**
 * Dimension value selection model
 */
export class DimensionSelectionModel extends ValueSelectionModel {}
/**
 * Tuple based selection model
 */
export class TupleSelectionModel {
  public selectionType: string;
  public objectIds: Array<string> = [];
}

/**
 * Container class to populate all the selection models when parsing input
 */
export class SelectionModelsContainer {
  public hierModelArr: Array<HierarchicalSelectionModel> = [];
  public dimModelArr: Array<DimensionSelectionModel> = [];
  public quantModelArr: Array<RangeSelectionModel> = [];
  public selection: TupleSelectionModel;
}
