import { ExecuteParameters, ExecuteResponse, Notification, VerbId, VersionNumber } from '../JsApiInternalContract';
import { InternalContractVersionConverter } from './InternalContractVersionConverter';
import * as Translations from './VersionTranslations';

// tslint:disable:no-any

/**
 * The version converter is designed to allow the platform and external modules
 * to seemlessly communicate over two different versions of the internal API. The only
 * mode it supports is external's version <= platform's version. When executing
 * commands, it is used to upgrade the external representation to what platform knows on the way in
 * and downgrade the representations on the way out. Similarly for notifications, it can
 * downgrade those on the way from platform to external.
 */
export class StackingVersionConverter implements InternalContractVersionConverter {
  /**
   * Creates a new instance of the StackingVersionConverter
   *
   * @param _externalVersion The version of the internal contract api-external-js is using
   * @param _platformVersion The version of the internal contract the api-platform-js is using
   * @param _upgradeExecuteTranslations Ordered list of the translations to perform when upgrading cmd parameters
   * @param _downgradeExecuteTranslations Ordered list of downgrade translations to perform after a cmd
   * @param _downgradeNotificationTranslations Ordered list of downgrade translations to perform on a notification
   */
  public static fromData(
    externalVersion: VersionNumber,
    platformVersion: VersionNumber,
    upgradeExecuteTranslations: Array<Translations.UpgradeExecuteCall>,
    downgradeExecuteTranslations: Array<Translations.DowngradeExecuteReturn>,
    downgradeNotificationTranslations: Array<Translations.DowngradeNotification>,
  ): StackingVersionConverter {
    return new this(
      externalVersion.major,
      platformVersion.major,
      upgradeExecuteTranslations,
      downgradeExecuteTranslations,
      downgradeNotificationTranslations,
    );
  }

  /**
   * Creates a new instance of the StackingVersionConverter
   *
   * @param _externalMajorVersion The major version of the internal contract api-external-js is using
   * @param _platformMajorVersion The major version of the internal contract the api-platform-js is using
   * @param _upgradeExecuteTranslations Ordered list of the translations to perform when upgrading cmd parameters
   * @param _downgradeExecuteTranslations Ordered list of downgrade translations to perform after a cmd
   * @param _downgradeNotificationTranslations Ordered list of downgrade translations to perform on a notification
   */
  public constructor(
    private _externalMajorVersion: number,
    private _platformMajorVersion: number,
    private _upgradeExecuteTranslations: Array<Translations.UpgradeExecuteCall>,
    private _downgradeExecuteTranslations: Array<Translations.DowngradeExecuteReturn>,
    private _downgradeNotificationTranslations: Array<Translations.DowngradeNotification>,
  ) {
    if (this._externalMajorVersion > this._platformMajorVersion) {
      throw new Error(`Cannot convert between external version ${this._externalMajorVersion} and ${this._platformMajorVersion}`);
    }
  }

  public upgradeExecuteCall(verb: any, parameters: any): { verb: VerbId; parameters: ExecuteParameters } {
    // Perform the upgrade of the verb and parameters to the level that platform is using
    let upgraded = { verb: verb, parameters: parameters };
    for (const upgradeTranslation of this._upgradeExecuteTranslations) {
      upgraded = upgradeTranslation(upgraded.verb, upgraded.parameters);
    }

    return upgraded;
  }

  public downgradeExecuteReturn(executeResponse: ExecuteResponse): ExecuteResponse {
    // Downgrade the response to what the external module is expecting
    let downgraded = executeResponse;
    for (const downgradeTranslation of this._downgradeExecuteTranslations) {
      downgraded = downgradeTranslation(downgraded);
    }

    return downgraded;
  }

  public downgradeNotification(notification: Notification): Notification {
    // Downgrade the notification to what the external module is expecting
    let downgraded = notification;
    for (const downgradeTranslation of this._downgradeNotificationTranslations) {
      downgraded = downgradeTranslation(downgraded);
    }

    return downgraded;
  }
}
