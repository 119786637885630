export enum AskDataOptionNames {
  token = 'token',
  origin = 'origin',
  showEmbed = 'showEmbed',
  showPin = 'showPin',
  showSave = 'showSave',
  showShare = 'showShare',
  debug = ':jsdebug',
  ApiID = ':apiID',
  ApiExternalVersion = ':apiExternalVersion',
  ApiInternalVersion = ':apiInternalVersion',
  AskDataWebComponent = ':askDataWebComponent',
}
