import { EmbeddingBootstrapInfo, ExecuteParameters, ParameterId, VerbId } from '@tableau/api-internal-contract-js';
import { ClientInfoService } from '../ClientInfoService';
import { ServiceNames } from '../ServiceRegistry';
import { ServiceImplBase } from './ServiceImplBase';

export class ClientInfoServiceImpl extends ServiceImplBase implements ClientInfoService {
  public get serviceName(): string {
    return ServiceNames.ClientInfo;
  }

  public getClientInfoAsync(): Promise<EmbeddingBootstrapInfo> {
    const verb = VerbId.GetEmbeddingClientInfo;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getClientInfoAsync',
    };

    return this.execute(verb, parameters).then<EmbeddingBootstrapInfo>((response) => {
      const clientInfo = response.result as EmbeddingBootstrapInfo;
      return clientInfo;
    });
  }
}
