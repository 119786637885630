import * as Contract from '@tableau/api-external-contract-js';
import { AnnotateEnum, Annotation, ExecuteParameters, ParameterId, VerbId, VisualId } from '@tableau/api-internal-contract-js';
import { InternalToExternalEnumMappings } from '../../EnumMappings/InternalToExternalEnumMappings';
import { SelectionModelsContainer, TupleSelectionModel } from '../../Models/SelectionModels';
import { TableauError } from '../../TableauError';
import { AnnotationService } from '../AnnotationService';
import { ServiceNames } from '../ServiceRegistry';
import { ServiceImplBase } from './ServiceImplBase';

export class AnnotationServiceImpl extends ServiceImplBase implements AnnotationService {
  public get serviceName(): string {
    return ServiceNames.Annotation;
  }

  /**
   * Method to annotate a mark on the given worksheet.
   *
   * @param visualId
   * @param mark
   * @param annotationText
   */
  public annotateMarkAsync(visualId: VisualId, mark: Contract.MarkInfo, annotationText: string): Promise<void> {
    const selectionModelContainer: SelectionModelsContainer = this.parseMarkSelectionIds([mark]);
    const dummyTargetPoint = { x: 0, y: 0 };
    const formattedText = `<formatted-text><run>${annotationText}</run></formatted-text>`;

    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'annotateMarkAsync',
      [ParameterId.VisualId]: visualId,
      [ParameterId.AnnotateEnum]: AnnotateEnum.Mark,
      [ParameterId.TargetPoint]: dummyTargetPoint,
      [ParameterId.SelectionList]: [selectionModelContainer.selection],
      [ParameterId.FormattedText]: formattedText,
    };
    return this.execute(VerbId.CreateAnnotation, parameters).then<void>((response) => {
      // Expecting an empty model and hence the void response.
      return;
    });
  }

  /**
   * Method to retrieve annotations for the given worksheet.
   *
   * @param visualId
   * @returns {Promise<Array<Annotation>>}
   */
  public getAnnotationsAsync(visualId: VisualId): Promise<Array<Contract.Annotation>> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getAnnotationsAsync',
      [ParameterId.VisualId]: visualId,
    };
    return this.execute(VerbId.GetAnnotations, parameters).then<Array<Contract.Annotation>>((response) => {
      const annotationsList = response.result as Array<Annotation>;
      return this.annotationFilterMap(annotationsList);
    });
  }

  /**
   * Method to remove an annotation from a given worksheet.
   *
   * @param visualId
   * @param annotation
   */
  public removeAnnotationAsync(visualId: VisualId, annotation: Contract.Annotation): Promise<void> {
    const selectionModelContainer: SelectionModelsContainer = this.parseAnnotationSelectionIds([annotation]);

    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'removeAnnotationAsync',
      [ParameterId.VisualId]: visualId,
      [ParameterId.SelectionList]: [selectionModelContainer.selection],
    };
    return this.execute(VerbId.RemoveAnnotation, parameters).then<void>((response) => {
      // Expecting an empty model and hence the void response.
      return;
    });
  }

  /**
   * Method to prepare the pres models for selection by MarksInfo
   * @param marks
   */
  private parseMarkSelectionIds(marks: Array<Contract.MarkInfo>): SelectionModelsContainer {
    const ids: Array<string> = [];
    const selectionModelContainer: SelectionModelsContainer = new SelectionModelsContainer();
    marks.forEach((mark) => {
      const tupleId: number | undefined = mark.tupleId;
      if (tupleId !== undefined && tupleId !== null && tupleId > 0) {
        ids.push(tupleId.toString()); // collect the tuple ids
      } else {
        throw new TableauError(Contract.ErrorCodes.InternalError, 'invalid tupleId');
      }
    });
    if (ids.length !== 0) {
      // tuple ids based selection
      const tupleSelectionModel: TupleSelectionModel = new TupleSelectionModel();
      tupleSelectionModel.selectionType = 'tuples';
      tupleSelectionModel.objectIds = ids;
      selectionModelContainer.selection = tupleSelectionModel;
    }
    return selectionModelContainer;
  }

  /**
   * Method to prepare the pres models for selection by MarkAnnotationInfo
   * @param marks
   */
  private parseAnnotationSelectionIds(annotations: Array<Contract.Annotation>): SelectionModelsContainer {
    const ids: Array<string> = [];
    const selectionModelContainer: SelectionModelsContainer = new SelectionModelsContainer();
    annotations.forEach((annotation) => {
      const annotationId: number | undefined = annotation.annotationId;
      if (annotationId !== undefined && annotationId !== null && annotationId >= 0) {
        ids.push(annotationId.toString()); // collect the annotation ids
      } else {
        throw new TableauError(Contract.ErrorCodes.InternalError, 'invalid annotationId');
      }
    });
    if (ids.length !== 0) {
      // annotation ids based selection
      const tupleSelectionModel: TupleSelectionModel = new TupleSelectionModel();
      tupleSelectionModel.selectionType = 'annotations';
      tupleSelectionModel.objectIds = ids;
      selectionModelContainer.selection = tupleSelectionModel;
    }
    return selectionModelContainer;
  }

  /**
   * Method to map Annotation to MarkAnnotationInfo
   * @param annotation
   * @returns {Annotation}
   */
  private mapAnnotation(annotation: Annotation): Contract.Annotation {
    return {
      annotationHTML: annotation.annotationText,
      annotationId: annotation.annotationId,
      annotationText: annotation.annotationPlainText,
      annotationType: InternalToExternalEnumMappings.annotationType.convert(annotation.annotateEnum),
      tupleId: annotation.tupleId!,
    };
  }

  /**
   * Filter the Annotations to Mark Annotations, and map them to MarkAnnotationInfo
   * @param annotations
   * @returns {Array<Annotation>}
   */
  private annotationFilterMap(annotations: Array<Annotation>): Array<Contract.Annotation> {
    const annotationInfos = annotations.map((annotation) => this.mapAnnotation(annotation));

    return annotationInfos;
  }
}
