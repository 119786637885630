import { ReplaySpeedType } from '@tableau/api-external-contract-js';
import { ParameterId, VerbId } from '@tableau/api-internal-contract-js';
import { ExternalToInternalEnumMappings as ExternalEnumConverter } from '../../EnumMappings/ExternalToInternalEnumMappings';
import { ErrorHelpers } from '../../Utils/ErrorHelpers';
import { AnimationService } from '../AnimationService';
import { ServiceNames } from '../ServiceRegistry';
import { ServiceImplBase } from './ServiceImplBase';

export class AnimationServiceImpl extends ServiceImplBase implements AnimationService {
  public get serviceName(): string {
    return ServiceNames.Animation;
  }

  public replayAsync(replaySpeed: ReplaySpeedType): Promise<void> {
    const parameters = {
      [ParameterId.ReplaySpeed]: 1.0,
    };

    ErrorHelpers.verifyEnumValue<ReplaySpeedType>(replaySpeed, ReplaySpeedType, 'ReplaySpeedType');
    parameters[ParameterId.ReplaySpeed] = ExternalEnumConverter.setReplaySpeedType.convert(replaySpeed);

    return this.execute(VerbId.ReplayAnimation, parameters).then<void>((response) => {
      return;
    });
  }
}
