/**
 * These strings were *manually copied* from the server,
 * workgroup/src/silos/tableau-server/libraries/tab-controller-vizql/src/com/tableausoftware/
 * controller/vizql/constants/ParameterKeys.java#L9
 * should be exported instead
 * parameters that vizql will accept in an embedding URL
 **/
enum VqlParameterNames {
  ONE = '1',
  ALERTS = ':alerts',
  ALIAS = 'alias',
  ALLOW_DEFERRED_BOOTSTRAP = ':allowDeferredBootstrap',
  ALLOW_AUTOGEN_WORKSHEET_PHONE_LAYOUTS = 'allowAutogenWorksheetPhoneLayouts',
  API_ID = ':apiID',
  APP = ':app',
  ATTACHMENT = 'attachment',
  IS_IPAD_APP = 'app',
  ANIMATE_TRANSITION = ':animate_transition',
  ASCENDING = 'ascending',
  AUTHENTICATION = 'authentication',
  ATTRIBUTES = 'attributes',
  AUTHORING_SHEET = ':authSheet',
  AXIS = 'axis',
  BOOTSTRAP_WHEN_NOTIFIED = ':bootstrapWhenNotified',
  BROWSER_BACK_BUTTON_UNDO = ':browserBackButtonUndo',
  BROWSER_RENDERING_ON = 'isBrowserRendering',
  BROWSER_RENDERING_THRESHOLD = 'browserRenderingThreshold',
  CHARSET = 'charset',
  CLIENT_DIMENSION = 'clientDimension',
  CLIENT_IP = 'client_ip',
  CLIENT_NUM = 'clientNum',
  COLON_EMBEDDED = ':embedded',
  COLON_MOBILE = ':mobile',
  COLON_DEEP_LINKING_DISABLED = ':deepLinkingDisabled',
  COLON_WATERMARK = ':watermark',
  COMMENTS = ':comments',
  COMMENTING_ENABLED = ':commentingEnabled',
  COMMENTING_MENTIONS_ENABLED = ':commentingMentions',
  CONNECTION = 'connection',
  CREATED_BY_FEATURE = 'createdByFeature',
  CRYPTED_PASSWORD = 'crypted',
  CSSDEBUG = ':cssdebug',
  CSV = 'csv',
  CUSTOM_VIEWS = ':customViews',
  CV = ':cv',
  CV_ID = 'cvId',
  DASHBOARD_PORT_SIZE = 'dashboardPortSize',
  DATA_DETAILS = ':dataDetails',
  DATASOURCE = 'datasource',
  DEBUG = ':debug',
  DELAY = ':delay',
  DEPTH = 'depth',
  DEVICE_TYPE = ':device',
  DEVICE_PIXEL_RATIO = 'devicePixelRatio',
  DEVICE_PREVIEW_MODE = ':devicepreviewmode',
  DIMENSIONS = ':dimensions',
  DISABLE_SHARED_SESSIONS = ':disable_shared_sessions',
  DISABLE_URL_ACTIONS_POPUPS = ':disableUrlActionsPopups',
  DIRECTION = 'direction',
  DISPLAY_OVERLAY = ':display_overlay',
  DISPLAY_SHARE = ':display_share',
  DISPLAY_SPINNER = ':display_spinner',
  DISPLAY_STATIC_IMAGE = ':display_static_image',
  DISPLAY_TABS = 'display_tabs',
  DISPLAY_VIEW_COUNT = ':display_count',
  IGNORE_STICKY_SESSION = ':ignore_sticky_session',
  INCREMENT_VIEW_COUNT = ':increment_view_count',
  DOCMODE = 'docmode',
  DOMAIN = 'domain',
  DO_WORK = 'do_work',
  DX = 'dx',
  DY = 'dy',
  EDIT_DATA_ALERT = ':editDataAlert',
  EMBED = ':embed',
  EMBEDDED = 'embedded',
  ENCRYPTED = 'encrypted',
  API_EXTERNAL_VERSION = ':apiExternalVersion',
  API_INTERNAL_VERSION = ':apiInternalVersion',
  EXCLUDE = 'exclude',
  EXPORT_FORMAT = ':export_format',
  FIELDNAME = 'fieldname',
  FIELD_NAME = 'field_name',
  FIELD_SEP = 'field_sep',
  FILE = 'file',
  FILTER_TILE_SIZE = 'filterTileSize',
  FN = 'fn',
  FOCUS = 'focus',
  FORCE_CACHE = 'force_cache',
  FORMAT = 'format',
  FORMAT_DATA_VALUE_LOCALLY = 'formatDataValueLocally',
  FROM_WG = ':from_wg',
  GRAPHQL_DATABASE_ID = ':graphQLDatabaseId',
  GRAPHQL_TABLE_ID = ':graphQLTableId',
  GUID = ':guid',
  H = 'h',
  HIDDEN = 'hidden',
  HIDE_CLOSE_BUTTON = ':hideCloseButton',
  HIDE_EDIT_BUTTON = ':hideEditButton',
  HIDE_EDIT_IN_DESKTOP_BUTTON = ':hideEditInDesktopButton',
  HIGH_DPI = ':highdpi',
  IMAGE_SIZE_LIMIT = 'imageSizeLimit',
  TRANSPARENT_LINE_MARKS_LIMIT = 'transparentLineMarkLimit',
  CLIENT_RENDER_PIXEL_LIMIT = 'clientRenderPixelLimit',
  IN = 'in',
  INSTANCE_ID = ':iid',
  IS_GUEST_REDIRECT_FROM_VIZPORTAL = ':isGuestRedirectFromVizportal',
  IS_MARK_ANIMATION_ENABLED = 'isMarkAnimationEnabled',
  IS_VIZPORTAL = ':isVizPortal',
  INPUT_ = 'input_string',
  JSDEBUG = ':jsdebug',
  JSON = 'json',
  JSON_ONLY = ':jsonOnly',
  KEEPFILE = 'keepfile',
  KEY = 'key',
  LANGUAGE = 'language',
  LANGUAGE_REQUESTED = ':language',
  LINKTARGET = ':linktarget',
  LOAD_ORDER_ID = ':loadOrderID', // used for Public
  LOAD_NEWEST_REVISION = ':loadNewestRevision',
  LOCAL_PATHS = ':localPaths',
  LOCALE = 'locale',
  LOCATION_ID = 'locationid',
  LOCATION_TYPE = 'locationtype',
  WORKBOOK_LOCALE = 'workbookLocale',
  MAXROWS = 'maxrows',
  MAX_AGE = 'maxAge',
  MAX_ROWS = 'maxRows',
  METHOD = 'method',
  MOBILE = 'mobile',
  MODIFIER = 'modifier',
  NAME = 'name',
  NAV_TYPE = 'navType',
  NAV_SRC = 'navSrc',
  NOATTACHMENT = 'no-attachment',
  NO_OVERWRITE = 'no_overwrite',
  OPEN_AUTHORING_IN_TOP_WINDOW = ':openAuthoringInTopWindow',
  OPENID_HD = ':openid_hd',
  OPENID_LOGIN_HINT = ':openid_login_hint',
  OAUTHREQUEST = 'oauthrequest',
  OAUTH_CREDENTIAL_IDS = 'oauth_credential_ids',
  OFFLINE_MODE = ':offlinemode',
  ORIGIN = ':origin',
  OVERLAY = ':overlay',
  P = 'p',
  PATH = 'path',
  PANE_RESOLVER_X = 'paneResolverX',
  PANE_RESOLVER_Y = 'paneResolverY',
  PARAM = 'param',
  PARENT_ID = 'parent_id',
  PASSWORD = 'password',
  PATTERN = 'pattern',
  PIXEL_RATIO = ':pixelratio',
  PREFIX = ':prefix',
  PREVIEW = ':preview',
  PRIMARY_CONTENT_URL = 'primary_content_url',
  PROJECT = 'project',
  PROTOCOL = 'protocol',
  PSEUDO = ':pseudo',
  QUERY = 'query',
  R = 'r',
  RANGE_TYPE = 'range_type',
  RECORD_SEP = 'record_sep',
  RECORD_CLIENT_PERFORMANCE = ':record_client_performance',
  RECORD_PERFORMANCE = ':record_performance',
  RECOVER_OLD_SESSION_ID = ':recover_old_session_id',
  RECOVER_SESSION_STATE_TICKETS = ':recover_session_state_tickets',
  REFRESH = ':refresh',
  REFRESHUNMODIFIED = ':refreshunmodified',
  REGION = 'region',
  RELOAD_ON_CUSTOM_VIEW_SAVE = ':reloadOnCustomViewSave',
  RENDER = ':render',
  RENDER_MAPS_CLIENT_SIDE = 'renderMapsClientSide',
  RETRY = ':retry',
  REUSE = 'reuse',
  REUSE_FOREVER = 'reuse_forever',
  REVERT = ':revert',
  SAVE_DB_PASSWORDS = 'save_db_passwords',
  SELECT_X = 'select_x',
  SELECT_Y = 'select_y',
  SESSION_FEATURE_PREFIX = ':ff_',
  SESSION_FLAGS = ':session_feature_flags',
  SHARE_BUTTON = 'share_button',
  SHEET_ID = 'sheet_id',
  SHARED = 'shared',
  SHOWALL = 'showall',
  SHOW_APP_BANNER = ':showAppBanner',
  SHOW_ASK_DATA = ':showAskData',
  SHOW_PARAMS = 'showParams',
  SHOW_SHARE_OPTIONS = ':showShareOptions',
  SIZE = ':size',
  SOURCE = 'source',
  STATE = 'state',
  STICKY_SESSION_KEY = 'stickySessionKey',
  SUBSCRIPTIONS = ':subscriptions',
  SUMMARY = 'summary',
  SUPPRESS_DEFAULT_EDIT_BEHAVIOR = ':suppressDefaultEditBehavior',
  SYNC_SESSION = ':sync_session',
  SYNC_SESSION_STATE = 'sync_session_state',
  TABS = ':tabs',
  TARGET = 'target',
  TARGET_SITE = 'target_site',
  TOOLBAR = ':toolbar',
  TOOLTIP = ':tooltip',
  TS = 'ts',
  UNDERLYING_TABLE_ID = 'underlying_table_id',
  UNDERLYING_TABLE_CAPTION = 'underlying_table_caption',
  UPDATE_TYPE = 'update_type',
  USE_GUEST = ':useGuest',
  USE_INLINE_IMAGES = 'use_inline_images',
  USERAGENT = 'useragent',
  USERNAME = 'username',
  VALUE = 'value',
  VERBOSE_MODE = 'verboseMode',
  VIEW_ID = 'views_id',
  VIZ_SNAPSHOT_ID = 'viz_snapshot_id',
  VW = ':vw',
  W = 'w',
  WORKSHEET_PORT_SIZE = 'worksheetPortSize',
  WATERMARK = 'watermark',
  WATERMARK_MARGIN = ':watermark_margin',
  WB = ':wb',
  WHICH = 'which',
  X = 'x',
  Y = 'y',
  ZID = 'zid',
  ZONE_ID = 'zone_id',
  KEYCHAIN_VERSION = 'keychain_version',
  DATASOURCE_ID = 'datasource_id',
  DATASOURCE_NAME = 'datasource_name',
  LENS_ID = 'lens_id',
  WIDTH = 'width',
  HEIGHT = 'height',

  // Default Layout params
  LAYOUT_VIEWS = 'views',
  LAYOUT_MENUS = 'menus',
  LAYOUT_GEOMETRY = 'geometry',
  LAYOUT_PAGES = 'pages',
  LAYOUT_CACHEURL = 'cacheurl',
  LAYOUT_SHEET = 'sheet',
  LAYOUT_DEPTH = 'depth',
}

/**
 * parameters that are only relevant when working with Tableau Public
 * maybe not really necessry to split them out
 */
enum PublicParameterNames {
  SHOW_VIZ_HOME = ':showVizHome',
  // display view count
}

/**
 * Map the parameter names as used in the url to what we call them publicly in the embedding library.
 * The only parameters listed here should be the ones that get sent to vizql
 *  - this should match the interface at external-contract:VizInterfaces:VizSettings
 */
export enum VizOptionNames {
  // publicName = internalStringValue
  Size = VqlParameterNames.SIZE,
  Embed = VqlParameterNames.EMBED,
  ApiInternalVersion = VqlParameterNames.API_INTERNAL_VERSION,
  ApiExternalVersion = VqlParameterNames.API_EXTERNAL_VERSION,
  ShowVizHome = PublicParameterNames.SHOW_VIZ_HOME,
  Jsdebug = VqlParameterNames.JSDEBUG,
  BootstrapWhenNotified = VqlParameterNames.BOOTSTRAP_WHEN_NOTIFIED,
  NavType = VqlParameterNames.NAV_TYPE,
  NavSrc = VqlParameterNames.NAV_SRC,
  debug = VqlParameterNames.JSDEBUG,
  ApiID = VqlParameterNames.API_ID,

  disableUrlActionsPopups = VqlParameterNames.DISABLE_URL_ACTIONS_POPUPS,
  hideTabs = VqlParameterNames.TABS,
  toolbar = VqlParameterNames.TOOLBAR,
  device = VqlParameterNames.DEVICE_TYPE,
  instanceIdToClone = VqlParameterNames.INSTANCE_ID,
  touchOptimize = VqlParameterNames.MOBILE,
  hideEditButton = VqlParameterNames.HIDE_EDIT_BUTTON,
  hideEditInDesktopButton = VqlParameterNames.HIDE_EDIT_IN_DESKTOP_BUTTON,
  suppressDefaultEditBehavior = VqlParameterNames.SUPPRESS_DEFAULT_EDIT_BEHAVIOR,
  hideCloseButton = VqlParameterNames.HIDE_CLOSE_BUTTON,
}
