import * as Contract from '@tableau/api-external-contract-js';
import { SheetImpl, SheetUtils, TableauError } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { Workbook } from './Workbook';

export abstract class Sheet implements Contract.EmbeddingSheet {
  public constructor(private _sheetImpl: SheetImpl, protected _workbookImpl: EmbeddingWorkbookImpl) {}

  public get isHidden(): boolean {
    return this._sheetImpl.hidden;
  }

  public get isActive(): boolean {
    return this._sheetImpl.active;
  }

  public get name(): string {
    return this._sheetImpl.name;
  }

  public get sheetType(): Contract.SheetType {
    return this._sheetImpl.sheetType;
  }

  public get size(): Contract.SheetSize {
    if (SheetUtils.isValidSheetSize(this._sheetImpl.size)) {
      return this._sheetImpl.size;
    }
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }

  public get url(): string {
    if (this.isHidden) {
      throw new TableauError(Contract.EmbeddingErrorCodes.NoUrlForHiddenWorksheet, 'No URL for hidden worksheet');
    }
    return this._sheetImpl.url;
  }

  public get index(): number {
    return this._sheetImpl.index;
  }

  public get workbook(): Contract.EmbeddingWorkbook {
    return new Workbook(this._workbookImpl);
  }

  public changeSizeAsync(newSize: Contract.SheetSize): Promise<Contract.SheetSize> {
    return this._sheetImpl.changeSizeAsync(newSize);
  }
}
