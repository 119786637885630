import * as Contract from '@tableau/api-external-contract-js';
import { DashboardObjectVisibilityType } from '@tableau/api-external-contract-js';
import { ParameterId, VerbId } from '@tableau/api-internal-contract-js';
import { ExternalToInternalEnumMappings as ExternalEnumConverter } from '../../EnumMappings/ExternalToInternalEnumMappings';
import { DashboardObjectImpl } from '../../Impl/DashboardObjectImpl';
import { ErrorHelpers } from '../../Utils/ErrorHelpers';
import { Param } from '../../Utils/Param';
import { ServiceNames } from '../ServiceRegistry';
import { ZoneService } from '../ZoneService';
import { ServiceImplBase } from './ServiceImplBase';

export class ZoneServiceImpl extends ServiceImplBase implements ZoneService {
  public get serviceName(): string {
    return ServiceNames.Zone;
  }

  public setVisibilityAsync(
    dashboard: string,
    dashboardZoneMap: Map<number, DashboardObjectImpl>,
    zoneVisibilityMap: Contract.ZoneVisibilityMap,
  ): Promise<void> {
    const parameters = {
      [ParameterId.FunctionName]: 'setVisibilityAsync',
      [ParameterId.Dashboard]: dashboard,
      [ParameterId.ZoneIdsVisibilityMap]: {},
    };

    // zoneVisibilityMap can be either an object or a Map - convert it to a Map
    const parameterAsMap = Param.convertParameterToMap(zoneVisibilityMap, 'zoneVisibilityMap');

    parameterAsMap.forEach((value, key) => {
      ErrorHelpers.verifyZoneIsValid(dashboardZoneMap, Number.parseInt(key, 10));
      ErrorHelpers.verifyEnumValue<DashboardObjectVisibilityType>(value, DashboardObjectVisibilityType, 'DashboardObjectVisibilityType');
      parameters[ParameterId.ZoneIdsVisibilityMap][key] = ExternalEnumConverter.setVisibilityType.convert(value);
    });

    return this.execute(VerbId.SetZoneVisibility, parameters).then<void>((response) => {
      return;
    });
  }

  public moveAndResizeAsync(
    dashboard: string,
    dashboardZoneMap: Map<number, DashboardObjectImpl>,
    dashboardObjectPositionAndSizeUpdateArray: Contract.DashboardObjectPositionAndSizeUpdateArray,
  ): Promise<void> {
    const parameters = {
      [ParameterId.Dashboard]: dashboard,
      [ParameterId.DashboardObjectPositionAndSizeUpdateList]: dashboardObjectPositionAndSizeUpdateArray,
    };

    dashboardObjectPositionAndSizeUpdateArray.forEach((dashboardObject: Contract.DashboardObjectPositionAndSizeUpdate) => {
      ErrorHelpers.verifyZoneIsValidAndFloating(dashboardZoneMap, dashboardObject.dashboardObjectID);
      ErrorHelpers.verifyWidthAndHeightOfDashboardObjectPositionAndSizeUpdate(dashboardObject);
    });

    return this.execute(VerbId.MoveAndResizeZones, parameters).then<void>((response) => {
      return;
    });
  }
}
