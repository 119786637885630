import * as Contract from '@tableau/api-external-contract-js';
import { StoryImpl, TableauError } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { Sheet } from './Sheet';
import { StoryPoint } from './StoryPoint';
import { StoryPointInfo } from './StoryPointInfo';

export class Story extends Sheet implements Contract.Story {
  public constructor(private _storyImpl: StoryImpl, _workbookImpl: EmbeddingWorkbookImpl) {
    super(_storyImpl, _workbookImpl);
  }

  public get activeStoryPoint(): Contract.StoryPoint {
    if (!this._storyImpl.activeStoryPoint) {
      throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError, 'Active story point has not been initialized');
    }
    return new StoryPoint(this._storyImpl.activeStoryPoint, this._workbookImpl);
  }

  public get storyPointsInfo(): Contract.StoryPointInfo[] {
    return this._storyImpl.storyPointsInfo.map((storyPointInfoImpl) => {
      return new StoryPointInfo(storyPointInfoImpl, this._workbookImpl);
    });
  }

  public activateNextStoryPointAsync(): Promise<Contract.StoryPoint> {
    return this._storyImpl.activateNextStoryPointAsync().then((storyPointImpl) => {
      return new StoryPoint(storyPointImpl, this._workbookImpl);
    });
  }

  public activatePreviousStoryPointAsync(): Promise<Contract.StoryPoint> {
    return this._storyImpl.activatePreviousStoryPointAsync().then((storyPointImpl) => {
      return new StoryPoint(storyPointImpl, this._workbookImpl);
    });
  }

  public activateStoryPointAsync(index: number): Promise<Contract.StoryPoint> {
    return this._storyImpl.activateStoryPointAsync(index).then((storyPointImpl) => {
      return new StoryPoint(storyPointImpl, this._workbookImpl);
    });
  }

  public revertStoryPointAsync(index: number): Promise<Contract.StoryPointInfo> {
    return this._storyImpl.revertStoryPointAsync(index).then((storyPointInfoImpl) => {
      return new StoryPointInfo(storyPointInfoImpl, this._workbookImpl);
    });
  }
}
