import { InternalApiDispatcher, Model, Notification, NotificationId } from '@tableau/api-internal-contract-js';
import { UnregisterFn } from '../NotificationService';

export class Registration {
  public constructor(private _filterFn: (notificationModel: Model) => boolean, private _callbackFn: (notificationModel: Model) => void) {
    // Nothing Here
  }

  public onNotification(notificationModel: Model): void {
    if (this._filterFn(notificationModel)) {
      this._callbackFn(notificationModel);
    }
  }
}

export class NotificationServiceImplBase {
  private _handlers: { [notificationId: string]: Array<Registration> };

  public constructor(private dispatcher: InternalApiDispatcher) {
    this._handlers = {};
    this.dispatcher.registerNotificationHandler(this.onNotification.bind(this));
  }

  public registerHandler(id: NotificationId, filterFn: (model: Model) => boolean, handler: (model: Model) => void): UnregisterFn {
    const handlers = this._handlers[id] || new Array<Registration>();
    const registration = new Registration(filterFn, handler);
    handlers.push(registration);
    this._handlers[id] = handlers;
    return () => this.removeRegistration(id, registration);
  }

  private hasHandlersForNotificationType(id: NotificationId): boolean {
    // eslint-disable-next-line no-prototype-builtins
    return this._handlers.hasOwnProperty(id);
  }

  private onNotification(notification: Notification): void {
    if (!this.hasHandlersForNotificationType(notification.notificationId)) {
      return;
    }

    // Go through and check for all the handlers of this particular notification
    this._handlers[notification.notificationId].forEach((h) => h.onNotification(notification.data));
  }

  private removeRegistration(id: NotificationId, registration: Registration): void {
    if (!this.hasHandlersForNotificationType(id)) {
      return;
    }

    this._handlers[id] = this._handlers[id].filter((reg) => reg !== registration);
  }
}
