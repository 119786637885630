import * as Contract from '@tableau/api-external-contract-js';
import { GetDataService } from '../Services/GetDataService';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { ErrorHelpers } from '../Utils/ErrorHelpers';

export class DataTableReader implements Contract.DataTableReader {
  public constructor(protected _id: string, protected _totalRowCount: number, protected _pageRowCount, private _registryId: number) {
    this._pageCount = Math.ceil(_totalRowCount / _pageRowCount);
  }
  protected _pageCount: number;

  public get totalRowCount(): number {
    return this._totalRowCount;
  }

  public get pageCount(): number {
    return this._pageCount;
  }

  public getPageAsync(pageNumber: number): Promise<Contract.DataTable> {
    ErrorHelpers.verifyRange(pageNumber, 0, this._pageCount);
    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    return service.getPageAsync(this._id, pageNumber, this._pageRowCount);
  }

  public async getAllPagesAsync(maxRows?: number): Promise<Contract.DataTable> {
    const firstPage: Contract.DataTable = await this.getPageAsync(0);
    maxRows = maxRows || this.totalRowCount;
    const rowsRequested = Math.min(maxRows, this.totalRowCount);
    const pagesRequested = Math.ceil(rowsRequested / this._pageRowCount);

    // Fetch up to 400 pages, with a default of 10,000 pageRowCount that gives us 4,000,000 rows
    const pagesToFetch = Math.min(pagesRequested, 400);
    const isDataLimited = pagesToFetch < pagesRequested;
    let remainingData: Array<Array<Array<Contract.DataValue>>> = [];
    for (let i = 1; i < pagesToFetch; i++) {
      const page = await this.getPageAsync(i);
      remainingData.push(page.data);
    }

    let fullData = firstPage.data.concat(...remainingData);
    // This slices the array only if maxRows has been set and is less than totalRowCount
    fullData.length = rowsRequested;

    return {
      name: firstPage.name,
      data: fullData,
      columns: firstPage.columns,
      totalRowCount: isDataLimited ? pagesToFetch * this._pageRowCount : rowsRequested,
      isTotalRowCountLimited: isDataLimited,
      isSummaryData: firstPage.isSummaryData,
    };
  }

  public releaseAsync(): Promise<void> {
    const service = ApiServiceRegistry.get(this._registryId).getService<GetDataService>(ServiceNames.GetData);
    return service.releaseAsync(this._id);
  }
}
