import { StoryImpl } from './StoryImpl';

export class StoryPointInfoImpl {
  public constructor(
    private _caption: string,
    private _index: number,
    private _storyPointId: number,
    private _active: boolean,
    private _updated: boolean,
    private _parentStoryImpl: StoryImpl,
  ) {}

  public get caption() {
    return this._caption;
  }

  public set caption(caption: string) {
    this._caption = caption;
  }

  public get index() {
    return this._index;
  }

  public set index(index: number) {
    this._index = index;
  }

  public get storyPointId() {
    return this._storyPointId;
  }

  public get active() {
    return this._active;
  }

  public set active(isActive: boolean) {
    this._active = isActive;
  }

  public get updated() {
    return this._updated;
  }

  public set updated(isUpdated: boolean) {
    this._updated = isUpdated;
  }

  public get parentStory() {
    return this._parentStoryImpl;
  }
}
