import * as Contract from '@tableau/api-external-contract-js';
import { IncludeDataValuesOption } from '@tableau/api-external-contract-js';
import { VisualId } from '@tableau/api-internal-contract-js';
import { ApiService } from './ServiceRegistry';

/**
 * Defines which type of getData call to make.
 */
export enum GetDataType {
  Summary = 'summary',
  Underlying = 'underlying',
}

/**
 * Service for implementing the logic for various getData calls
 *
 * @interface GetDataService
 * @extends {ApiService}
 */
export interface GetDataService extends ApiService {
  /**
   * Gets the limit of rows for getUnderlyingDataAsync
   */
  getMaxRowLimit(): number;

  /**
   * Gets the underlying data for a particular visual
   *
   * @param {VisualId} visualId  The visual id
   * @param {GetDataType} getType  The type of getData call to make
   * @param {boolean} ignoreAliases  Whether or not aliases should be ignored
   * @param {boolean} ignoreSelection  Whether or not selection should be ignored
   * @param {boolean} includeAllColumns  Should all columns be included
   * @param {Array} columnsToIncludeById Overrides includeAllColumns, lists the columns to include. Empty means all columns.
   * @param {number} maxRows  Maximum number of rows to return
   * @returns {Promise<Contract.DataTable>}  Data table with the requested data
   */
  getUnderlyingDataAsync(
    visualId: VisualId,
    getType: GetDataType,
    ignoreAliases: boolean,
    ignoreSelection: boolean,
    includeAllColumns: boolean,
    columnsToIncludeById: Array<string>,
    maxRows: number,
    includeDataValuesOption: IncludeDataValuesOption,
  ): Promise<Contract.DataTable>;

  /**
   * @param {VisualId} visualId  The visual id
   * @param {number} pageRowCount The number of rows per page. The default and maximum is 10,000 rows.
   * @param {boolean} ignoreAliases  Whether alias values should be ignored in the returned data
   * @param {boolean} ignoreSelection  Whether selections in this viz should be ignored in the returned data
   * @param {boolean} includeAllColumns  Should all columns be included
   * @param {Array} columnsToIncludeById Overrides includeAllColumns, lists the columns to include. Empty means all columns
   * @returns {Promise<Contract.DataTableReader>}  Data table reader to access the underlying data
   */
  getSummaryDataReaderAsync(
    visualId: VisualId,
    pageRowCount: number,
    ignoreAliases: boolean,
    ignoreSelection: boolean,
    includeAllColumns: boolean,
    columnsToIncludeById: Array<string>,
    includeDataValuesOption: IncludeDataValuesOption,
  ): Promise<Contract.DataTableReader>;

  /**
   * Gets the currently selected marks for a given visual
   *
   * @param {VisualId} visualId  The visual id
   * @returns {Promise<ActiveMarks>}  Collection of data tables with the active marks
   */
  getSelectedMarksAsync(visualId: VisualId): Promise<Contract.MarksCollection>;

  /**
   * Gets the currently highlighted marks for a given visual
   *
   * @param {VisualId} visualId  The visual id
   * @returns {Promise<ActiveMarks>}  Collection of data tables with the active marks
   */
  getHighlightedMarksAsync(visualId: VisualId): Promise<Contract.MarksCollection>;

  /**
   * @param {string} datasourceId  The id of the datasource
   * @param {boolean} ignoreAliases  Whether alias values should be ignored in the returned data
   * @param {number} maxRows The maximum number of rows to retrieve
   * @param {Array<string>} columnsToInclude Columns which should be returned specified by caption. Empty means all columns
   * @param {Array<string>} columnsToIncludeById Columns which should be returned specified by name. Empty means all columns
   * @returns {Promise<Contract.DataTable>}  Data table with the requested data
   */
  getDataSourceDataAsync(
    datasourceId: string,
    ignoreAliases: boolean,
    maxRows: number,
    columnsToInclude: Array<string>,
    columnsToIncludeById: Array<string>,
    includeDataValuesOption: IncludeDataValuesOption,
  ): Promise<Contract.DataTable>;

  /**
   * @param {string} datasourceId  The id of the datasource
   * @param {string} logicalTableId  The id of the logcial table
   * @param {boolean} ignoreAliases  Whether alias values should be ignored in the returned data
   * @param {number} maxRows The maximum number of rows to retrieve
   * @param {Array<string>} columnsToInclude Columns which should be returned specified by caption. Empty means all columns
   * @param {Array<string>} columnsToIncludeById Columns which should be returned specified by name. Empty means all columns
   * @returns {Promise<Contract.DataTable>}  Data table with the requested data
   */
  getLogicalTableDataAsync(
    datasourceId: string,
    logicalTableId: string,
    ignoreAliases: boolean,
    maxRows: number,
    columnsToInclude: Array<string>,
    columnsToIncludeById: Array<string>,
    includeDataValuesOption: IncludeDataValuesOption,
  ): Promise<Contract.DataTable>;

  /**
   * @param {string} datasourceId  The id of the datasource
   * @param {string} logicalTableId  The id of the logcial table
   * @param {number} pageRowCount The number of rows per page. The default and maximum is 10,000 rows.
   * @param {boolean} ignoreAliases  Whether alias values should be ignored in the returned data
   * @param {Array<string>} columnsToIncludeById Columns which should be returned specified by name. Empty means all columns
   * @returns {Promise<Contract.DataTable>}  Data table with the requested data
   */
  getLogicalTableDataReaderAsync(
    datasourceId: string,
    logicalTableId: string,
    pageRowCount: number,
    ignoreAliases: boolean,
    columnsToIncludeById: Array<string>,
    includeDataValuesOption: IncludeDataValuesOption,
  ): Promise<Contract.DataTableReader>;

  /**
   * @param {VisualId} visualId  The visual id
   * @param {string} logicalTableId  The id of the logcial table
   * @param {boolean} ignoreAliases  Whether alias values should be ignored in the returned data
   * @param {boolean} ignoreSelection  Whether selections in this viz should be ignored in the returned data
   * @param {boolean} includeAllColumns  Whether to include all columns or only the referenced columns
   * @param {Array} columnsToIncludeById Overrides includeAllColumns, lists the columns to include. Empty means all columns.
   * @param {number} maxRows The maximum number of rows to retrieve
   * @returns {Promise<Contract.DataTable>}  Data table with the requested data
   */
  getUnderlyingTableDataAsync(
    visualId: VisualId,
    logicalTableId: string,
    ignoreAliases: boolean,
    ignoreSelection: boolean,
    includeAllColumns: boolean,
    columnsToIncludeById: Array<string>,
    maxRows: number,
    includeDataValuesOption: IncludeDataValuesOption,
  ): Promise<Contract.DataTable>;

  /**
   * @param {VisualId} visualId  The visual id
   * @param {string} logicalTableId  The id of the logcial table
   * @param {number} pageRowCount The number of rows per page. The default and maximum is 10,000 rows.
   * @param {boolean} ignoreAliases  Whether alias values should be ignored in the returned data
   * @param {boolean} ignoreSelection  Whether selections in this viz should be ignored in the returned data
   * @param {boolean} includeAllColumns  Whether to include all columns or only the referenced columns
   * @param {Array} columnsToIncludeById Overrides includeAllColumns, lists the columns to include. Empty means all columns.
   * @returns {Promise<Contract.DataTable>}  Data table with the requested data
   */
  getUnderlyingTableDataReaderAsync(
    visualId: VisualId,
    logicalTableId: string,
    pageRowCount: number,
    ignoreAliases: boolean,
    ignoreSelection: boolean,
    includeAllColumns: boolean,
    columnsToIncludeById: Array<string>,
    includeDataValuesOption: IncludeDataValuesOption,
  ): Promise<Contract.DataTableReader>;

  /**
   * @param {VisualId} visualId  The visual id
   */
  getSummaryColumnsInfoAsync(visualId: VisualId): Promise<Array<Contract.Column>>;

  /**
   * @param tableId     The table id used to fetch data.
   * @param pageNumber  The page number (zero-based).
   * @param pageSize    The page size of the owning DataTableReader
   * @returns           A DataTable containing the requested page. The number of rows returned
   *                    can be less than requested at the end of the data.
   */
  getPageAsync(tableId: string, pageNumber: number, pageSize: number): Promise<Contract.DataTable>;

  /**
   * @param tableId     The table id used to fetch data.
   */
  releaseAsync(tableId: string): Promise<void>;
}
