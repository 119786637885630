import * as Contract from '@tableau/api-external-contract-js';
import { TableauDialogType } from '@tableau/api-external-contract-js/lib/src/ExternalContract/Embedding/Enums';
import {
  CustomMarkClickedContextMenuEvent,
  FilterEvent,
  NotificationId,
  StoryPointModel,
  UrlActionModel,
  VisualId,
} from '@tableau/api-internal-contract-js';
import { StoryImpl, TableauError } from '@tableau/api-shared-js';
import { CustomMarkContextMenuEvent } from '../Events/CustomMarkContextMenuEvent';
import { FilterChangedEvent } from '../Events/FilterChangedEvent';
import { MarksSelectedEvent } from '../Events/MarksSelectedEvent';
import { ParameterChangedEvent } from '../Events/ParameterChangedEvent';
import { StoryPointSwitchedEvent } from '../Events/StoryPointSwitchedEvent';
import { UrlActionEvent } from '../Events/UrlActionEvent';
import { VizImpl } from '../Impl/VizImpl';
import { EmbeddingUrlMode } from '../Models/EmbeddingVizUrl';
import { Workbook } from '../Models/Workbook';
import { WebComponentManager } from '../WebComponentManager';
import { AttributeEventType, attributeToEnumKey, EventHandlerFn, TableauVizBase } from './TableauVizBase';

/**
 * Represents the entry point for the `<tableau-viz>` custom HTML element.
 * This class is specifically focused on transferring information between the HTML and
 * the Viz, so it should have as little logic as possible.  Most of the logic should be
 * in {@link VizImpl}.
 */
export class TableauViz extends TableauVizBase implements Contract.Viz {
  // ========================================== Begin Custom Element definition ==========================================

  // This stores filters added via addFilter()
  private preInitFilters: Contract.FilterParameters[] = [];

  //#region Reactions

  //#region Filters
  private readFiltersFromChild(): Contract.FilterParameters[] {
    const filters: Contract.FilterParameters[] = [];
    [].forEach.call(this.children, (child) => {
      if (
        child.localName === Contract.VizChildElements.VizFilter &&
        !!child.getAttribute(Contract.VizChildElementAttributes.Field) &&
        child.getAttribute(Contract.VizChildElementAttributes.Value) !== undefined
      ) {
        filters.push({
          field: child.getAttribute(Contract.VizChildElementAttributes.Field),
          value: child.getAttribute(Contract.VizChildElementAttributes.Value),
        });
      }
    });
    return filters;
  }
  //#endregion Filters

  //#region Parameters
  private readParametersFromChild(): Contract.VizParameter[] {
    const params: Contract.VizParameter[] = [];
    [].forEach.call(this.children, (child) => {
      if (
        child.localName === Contract.VizChildElements.VizParameter &&
        !!child.getAttribute(Contract.VizChildElementAttributes.Name) &&
        !!child.getAttribute(Contract.VizChildElementAttributes.Value)
      ) {
        params.push({
          name: child.getAttribute(Contract.VizChildElementAttributes.Name),
          value: child.getAttribute(Contract.VizChildElementAttributes.Value),
        });
      }
    });
    return params;
  }

  public static get observedAttributes(): string[] {
    // Take caution before adding to this list because for every observed attribute change
    // we unregister and re-render the viz
    return [...super.observedAttributes, ...Object.values(Contract.VizAttributes)];
  }

  public disconnectedCallback(): void {
    this.preInitFilters = [];
    super.disconnectedCallback();
  }
  //#endregion Reaction

  protected createVizImpl(customParams: Contract.CustomParameter[]): VizImpl {
    if (!this.src) {
      throw new TableauError(
        Contract.EmbeddingErrorCodes.InternalError,
        'We should not have attempted to render the component without a src',
      );
    }

    const vizqlOptions = this.constructVizqlOptions();
    const filters = this.readFiltersFromChild().concat(this.preInitFilters);
    const params = this.readParametersFromChild();
    this._embeddingIdCounter = WebComponentManager.registerWebComponent(this);
    const vizImpl = new VizImpl(
      this,
      this.iframe,
      this.src,
      EmbeddingUrlMode.Viewing,
      vizqlOptions,
      filters,
      params,
      customParams,
      this._embeddingIdCounter,
    );
    vizImpl.initializeViz();

    return vizImpl;
  }

  private constructVizqlOptions(): Contract.VizSettings {
    const options: Contract.VizSettings = {
      disableUrlActionsPopups: this.disableUrlActionsPopups,
      hideTabs: this.hideTabs,
      toolbar: this.toolbar,
      instanceIdToClone: this.instanceIdToClone,
      device: this.device,
      token: this.token,
      touchOptimize: this.touchOptimize,
      hideEditButton: this.hideEditButton,
      hideEditInDesktopButton: this.hideEditInDesktopButton,
      suppressDefaultEditBehavior: this.suppressDefaultEditBehavior,
      debug: this.debug,
    };

    return options;
  }

  protected getAttributeEvents(): AttributeEventType[] {
    return [
      [Contract.VizAttributes.OnCustomMarkContextMenuEvent, Contract.EmbeddingTableauEventType.CustomMarkContextMenuEvent],
      [Contract.VizAttributes.OnEditButtonClicked, Contract.EmbeddingTableauEventType.EditButtonClicked],
      [Contract.VizSharedAttributes.OnEditInDesktopButtonClicked, Contract.EmbeddingTableauEventType.EditInDesktopButtonClicked],
      [Contract.VizAttributes.OnFilterChanged, Contract.EmbeddingTableauEventType.FilterChanged],
      [Contract.VizSharedAttributes.OnFirstInteractive, Contract.EmbeddingTableauEventType.FirstInteractive],
      [Contract.VizSharedAttributes.OnFirstVizSizeKnown, Contract.EmbeddingTableauEventType.FirstVizSizeKnown],
      [Contract.VizAttributes.OnMarkSelectionChanged, Contract.EmbeddingTableauEventType.MarkSelectionChanged],
      [Contract.VizAttributes.OnParameterChanged, Contract.EmbeddingTableauEventType.ParameterChanged],
      [Contract.VizAttributes.OnTabSwitched, Contract.EmbeddingTableauEventType.TabSwitched],
      [Contract.VizAttributes.OnToolbarStateChanged, Contract.EmbeddingTableauEventType.ToolbarStateChanged],
      [Contract.VizAttributes.OnUrlAction, Contract.EmbeddingTableauEventType.UrlAction],
      [Contract.VizAttributes.OnCustomViewLoaded, Contract.EmbeddingTableauEventType.CustomViewLoaded],
      [Contract.VizAttributes.OnCustomViewRemoved, Contract.EmbeddingTableauEventType.CustomViewRemoved],
      [Contract.VizAttributes.OnCustomViewSaved, Contract.EmbeddingTableauEventType.CustomViewSaved],
      [Contract.VizAttributes.OnCustomViewSetDefault, Contract.EmbeddingTableauEventType.CustomViewSetDefault],
      [Contract.VizAttributes.OnStoryPointSwitched, Contract.EmbeddingTableauEventType.StoryPointSwitched],
    ];
  }

  protected getRegisteredEvents(): EventHandlerFn[] {
    return super.getRegisteredEvents().concat([
      [
        NotificationId.SelectedMarksChanged,
        (model: VisualId) => {
          return this.shouldNotifyEvent(model);
        },
        (visualId: VisualId) => this.handleSelectedMarksChangedEvent(visualId),
      ],
      [
        NotificationId.FilterChanged,
        (model: FilterEvent) => this.shouldNotifyEvent(model.visualId),
        (event: FilterEvent) => this.handleFilterChangedEvent(event),
      ],
      [NotificationId.EditButtonClicked, () => true, () => this.handleEditButtonClicked()],
      [
        NotificationId.CustomMarkContextMenuClicked,
        (model: CustomMarkClickedContextMenuEvent) => {
          return this.shouldNotifyEvent(model.visualId);
        },
        (event: CustomMarkClickedContextMenuEvent) => this.handleCustomMarkClickedContextMenuEvent(event),
      ],
      [
        NotificationId.ParameterChanged,
        () => true, // Notify for any parameter change across the workbook
        (fieldName: string) => this.handleParameterChangedEvent(fieldName),
      ],
      [NotificationId.UrlAction, () => true, (event: UrlActionModel) => this.handleUrlAction(event)],
      [NotificationId.StoryPointSwitched, () => true, (event: StoryPointModel) => this.handleStoryPointSwitch(event)],
    ]);
  }

  private shouldNotifyEvent(visualId: VisualId): boolean {
    switch (this.workbook.activeSheet.sheetType) {
      case Contract.SheetType.Worksheet:
        return this.workbook.activeSheet.name === visualId.worksheet;
      case Contract.SheetType.Dashboard: {
        const dashboard = this.workbook.activeSheet as Contract.EmbeddingDashboard;
        const length = dashboard.worksheets.filter((ws) => ws.name === visualId.worksheet).length;
        return length === 1;
      }
      case Contract.SheetType.Story: {
        const story = this.workbook.activeSheet as Contract.Story;
        const containedSheet = story.activeStoryPoint.containedSheet;
        if (!containedSheet) {
          return false;
        }

        if (containedSheet.sheetType === Contract.SheetType.Worksheet) {
          return containedSheet.name === visualId.worksheet;
        } else if (containedSheet.sheetType === Contract.SheetType.Dashboard) {
          const dashboard = containedSheet as Contract.EmbeddingDashboard;
          const length = dashboard.worksheets.filter((ws) => ws.name === visualId.worksheet).length;
          return length === 1;
        } else {
          return false;
        }
      }
      default:
        return false;
    }
  }

  private handleSelectedMarksChangedEvent(visualId: VisualId): void {
    const event = new MarksSelectedEvent(this.getWorksheetForNotificationHandler(visualId));
    this.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.MarkSelectionChanged, { detail: event }));
  }

  private handleFilterChangedEvent(event: FilterEvent): void {
    const filterChangeEvent = new FilterChangedEvent(
      this.getWorksheetForNotificationHandler(event.visualId),
      event.fieldName,
      event.fieldId,
    );

    this.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.FilterChanged, { detail: filterChangeEvent }));
  }

  private handleEditButtonClicked(): void {
    this.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.EditButtonClicked));
  }

  private handleCustomMarkClickedContextMenuEvent(event: CustomMarkClickedContextMenuEvent): void {
    const customMarkClickedEvent = new CustomMarkContextMenuEvent(
      this.getWorksheetForNotificationHandler(event.visualId),
      event.contextMenuId,
    );

    this.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.CustomMarkContextMenuEvent, { detail: customMarkClickedEvent }));
  }

  private handleParameterChangedEvent(fieldName: string) {
    const event = new ParameterChangedEvent(fieldName, this.vizImpl.embeddingId);
    this.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.ParameterChanged, { detail: event }));
  }

  private handleUrlAction(event: UrlActionModel): void {
    const urlActionEvent = new UrlActionEvent(event.url, event.target);
    this.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.UrlAction, { detail: urlActionEvent }));
  }

  private handleStoryPointSwitch(newStoryPointModel: StoryPointModel): void {
    const storyImpl = this.vizImpl.workbookImpl.activeSheet as StoryImpl;
    const storyPointInfoImpl = storyImpl.storyPointsInfo.find((storyPointInfo) => storyPointInfo.active === true);
    if (storyImpl.activeStoryPoint && storyPointInfoImpl) {
      if (storyImpl.activeStoryPoint.index !== newStoryPointModel.index) {
        storyImpl.updateStory(newStoryPointModel);
        const storyPointSwitchedEvent = new StoryPointSwitchedEvent(
          storyPointInfoImpl,
          storyImpl.activeStoryPoint,
          this.vizImpl.workbookImpl,
        );
        this.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.StoryPointSwitched, { detail: storyPointSwitchedEvent }));
      }
    }
  }

  private getWorksheetForNotificationHandler(visualId: VisualId): Contract.EmbeddingWorksheet {
    let worksheet: Contract.EmbeddingWorksheet;

    switch (this.workbook.activeSheet.sheetType) {
      case Contract.SheetType.Worksheet: {
        worksheet = this.workbook.activeSheet as Contract.EmbeddingWorksheet;
        break;
      }

      case Contract.SheetType.Dashboard: {
        const dashboard = this.workbook.activeSheet as Contract.EmbeddingDashboard;
        const worksheetArr = dashboard.worksheets.filter((ws) => ws.name === visualId.worksheet);
        if (worksheetArr.length === 1) {
          worksheet = worksheetArr[0];
        } else {
          throw new TableauError(Contract.EmbeddingErrorCodes.IndexOutOfRange, 'Worksheet not found');
        }
        break;
      }
      case Contract.SheetType.Story: {
        const story = this.workbook.activeSheet as Contract.Story;
        const containedSheet = story.activeStoryPoint.containedSheet;
        if (!containedSheet) {
          throw new TableauError(Contract.EmbeddingErrorCodes.IndexOutOfRange, 'Worksheet not found');
        }

        if (containedSheet.sheetType === Contract.SheetType.Worksheet) {
          worksheet = containedSheet as Contract.EmbeddingWorksheet;
        } else if (containedSheet.sheetType === Contract.SheetType.Dashboard) {
          const dashboard = containedSheet as Contract.EmbeddingDashboard;
          const worksheetArr = dashboard.worksheets.filter((ws) => ws.name === visualId.worksheet);
          if (worksheetArr.length === 1) {
            worksheet = worksheetArr[0];
          } else {
            throw new TableauError(Contract.EmbeddingErrorCodes.IndexOutOfRange, 'Worksheet not found');
          }
        } else {
          throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError, 'Could not find sheetType');
        }
        break;
      }
      default:
        throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError, 'Could not find sheetType');
    }

    return worksheet;
  }

  //#region Simple Getters / Setters
  public get disableUrlActionsPopups(): boolean {
    return this.hasAttribute(Contract.VizAttributes.DisableUrlActionsPopups);
  }

  public set disableUrlActionsPopups(v: boolean) {
    if (v) {
      this.setAttribute(Contract.VizAttributes.DisableUrlActionsPopups, '');
    } else {
      this.removeAttribute(Contract.VizAttributes.DisableUrlActionsPopups);
    }
  }

  public get hideTabs(): boolean {
    return this.hasAttribute(Contract.VizAttributes.HideTabs);
  }

  public set hideTabs(v: boolean) {
    if (v) {
      this.setAttribute(Contract.VizAttributes.HideTabs, '');
    } else {
      this.removeAttribute(Contract.VizAttributes.HideTabs);
    }
  }
  public get toolbar(): Contract.Toolbar {
    const toolbarKey = attributeToEnumKey(this.getAttribute(Contract.VizAttributes.Toolbar));
    const position = Contract.Toolbar[toolbarKey];
    if (!position) {
      return TableauVizBase.VizAttributeDefaults.toolbar;
    }

    return position;
  }

  public set toolbar(v: Contract.Toolbar) {
    if (v) {
      this.setAttribute(Contract.VizAttributes.Toolbar, v);
    }
  }

  public get instanceIdToClone(): string | undefined {
    const idToClone = this.getAttribute(Contract.VizAttributes.InstanceIdToClone);
    if (!idToClone) {
      return undefined;
    }

    return idToClone;
  }

  public set instanceIdToClone(v: string | undefined) {
    if (v) {
      this.setAttribute(Contract.VizAttributes.InstanceIdToClone, v);
    } else {
      this.removeAttribute(Contract.VizAttributes.InstanceIdToClone);
    }
  }

  public get device(): Contract.DeviceType {
    const deviceKey = attributeToEnumKey(this.getAttribute(Contract.VizAttributes.Device));
    const device = Contract.DeviceType[deviceKey];
    if (!device) {
      return TableauVizBase.VizAttributeDefaults.device; // it was not a valid device type
    }

    return device;
  }

  public set device(v: Contract.DeviceType) {
    this.setAttribute(Contract.VizAttributes.Device, v);
  }

  public get hideEditButton(): boolean {
    return this.hasAttribute(Contract.VizAttributes.HideEditButton);
  }

  public set hideEditButton(v: boolean) {
    if (v) {
      this.setAttribute(Contract.VizAttributes.HideEditButton, '');
    } else {
      this.removeAttribute(Contract.VizAttributes.HideEditButton);
    }
  }
  //#endregion

  // ========================================== End Custom Element definition ============================================

  // =========================================== Begin Viz Model definiton ===============================================

  public get automaticUpdatesArePaused(): boolean {
    return this.vizImpl.automaticUpdatesArePaused;
  }

  public pauseAutomaticUpdatesAsync(): Promise<void> {
    return this.vizImpl.pauseAutomaticUpdatesAsync();
  }

  public resumeAutomaticUpdatesAsync(): Promise<void> {
    return this.vizImpl.resumeAutomaticUpdatesAsync();
  }

  public toggleAutomaticUpdatesAsync(): Promise<void> {
    return this.vizImpl.toggleAutomaticUpdatesAsync();
  }

  public revertAllAsync(): Promise<void> {
    return this.vizImpl.revertAllAsync();
  }

  public refreshDataAsync(): Promise<void> {
    return this.vizImpl.refreshDataAsync();
  }

  public exportImageAsync(): Promise<void> {
    return this.vizImpl.exportImageAsync();
  }

  public displayDialogAsync(dialogType: TableauDialogType): Promise<void> {
    return this.vizImpl.displayDialogAsync(dialogType);
  }

  public redoAsync(): Promise<void> {
    return this.vizImpl.redoAsync();
  }

  public undoAsync(): Promise<void> {
    return this.vizImpl.undoAsync();
  }

  public addFilter(fieldName: string, value: string): void {
    this.preInitFilters.push({ field: fieldName, value: value });
    this.updateRenderingIfConnected();
  }

  /**
   * This is the public entry point for users to get a reference to the whole data model
   */

  public get workbook(): Contract.EmbeddingWorkbook {
    return new Workbook(this.vizImpl.workbookImpl);
  }

  // =========================================== End Viz Model definiton =================================================
}
