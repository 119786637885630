import { ExecuteParameters, ParameterId, StoryPointModel, VerbId } from '@tableau/api-internal-contract-js';
import { ServiceNames } from '../ServiceRegistry';
import { StoryActivationService } from '../StoryActivationService';
import { ServiceImplBase } from './ServiceImplBase';

export class StoryActivationServiceImpl extends ServiceImplBase implements StoryActivationService {
  public get serviceName(): string {
    return ServiceNames.StoryActivation;
  }

  activateNextStoryPointAsync(): Promise<void> {
    const verb = VerbId.ActivateNextStoryPoint;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'activateNextStoryPointAsync',
    };

    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }

  activatePreviousStoryPointAsync(): Promise<void> {
    const verb = VerbId.ActivatePreviousStoryPoint;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'activatePreviousStoryPointAsync',
    };

    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }

  activateStoryPointAsync(index: number): Promise<void> {
    const verb = VerbId.ActivateStoryPoint;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'activateStoryPointAsync',
    };
    parameters[ParameterId.StoryPointIndex] = index;

    return this.execute(verb, parameters).then<void>((response) => {
      return;
    });
  }

  revertStoryPointAsync(index: number): Promise<StoryPointModel> {
    const verb = VerbId.RevertStoryPoint;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'revertStoryPointAsync',
    };
    parameters[ParameterId.StoryPointIndex] = index;

    return this.execute(verb, parameters).then<StoryPointModel>((response) => {
      return response.result as StoryPointModel;
    });
  }
}
