import * as Contract from '@tableau/api-external-contract-js';
import { EmbeddingTableauEventType } from '@tableau/api-external-contract-js';
import { TableauWorksheetEvent } from './TableauWorksheetEvent';

export class CustomMarkContextMenuEvent extends TableauWorksheetEvent implements Contract.EmbeddingCustomMarkContextMenuEvent {
  private _contextMenuId: string;

  public constructor(worksheet: Contract.EmbeddingWorksheet, contextMenuId: string) {
    super(EmbeddingTableauEventType.CustomMarkContextMenuEvent, worksheet);
    this._contextMenuId = contextMenuId;
  }

  public getContextMenuId(): string {
    return this._contextMenuId;
  }

  public getSelectedMarksAsync(): Promise<Contract.MarksCollection> {
    return this.worksheet.getSelectedMarksAsync();
  }
}
