import { EmbeddingErrorCodes, ErrorCodes, SharedErrorCodes } from '@tableau/api-external-contract-js';

/**
 * Custom error class that extends the default JavaScript Error object.
 * This allows us to provide a field with a specific error code
 * so that developers can more easily programmatically respond
 * to error scenarios.
 */
export class TableauError extends Error {
  public constructor(private _errorCode: ErrorCodes | EmbeddingErrorCodes | SharedErrorCodes, message: string) {
    super(`${_errorCode}: ${message}`);

    /*tslint:disable-next-line */
    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    // Error inheritance does not work propertly when compiling to ES5, this is a workaround to force
    // the proto chain to be built correctly.  See the github link above for details.
    Object.setPrototypeOf(this, TableauError.prototype);
  }

  public get errorCode(): ErrorCodes | EmbeddingErrorCodes | SharedErrorCodes {
    return this._errorCode;
  }
}
