import * as Contract from '@tableau/api-external-contract-js';
import { CustomViewImpl } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { Workbook } from './Workbook';

export class CustomView implements Contract.CustomView {
  public constructor(private _customViewImpl: CustomViewImpl, private _workbookImpl: EmbeddingWorkbookImpl) {}

  public get name() {
    return this._customViewImpl.name;
  }

  public set name(name: string) {
    this._customViewImpl.name = name;
  }

  public get shared() {
    return this._customViewImpl.shared;
  }

  public set shared(shared: boolean) {
    this._customViewImpl.shared = shared;
  }

  public get default() {
    return this._customViewImpl.isDefault;
  }

  public set default(isDefault: boolean) {
    this._customViewImpl.isDefault = isDefault;
  }

  public get ownerName() {
    return this._customViewImpl.ownerName;
  }

  public get url() {
    return this._customViewImpl.url;
  }

  public get workbook() {
    return new Workbook(this._workbookImpl);
  }

  public saveAsync(): Promise<CustomView> {
    return this._customViewImpl.saveAsync().then((customViewImpl) => {
      return new CustomView(customViewImpl, this._workbookImpl);
    });
  }
}
