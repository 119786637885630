import * as Contract from '@tableau/api-external-contract-js';
import { DataType, ParameterValueType, PeriodType } from '@tableau/api-external-contract-js';
import { ParameterInfo } from '@tableau/api-internal-contract-js';
import { InternalToExternalEnumMappings } from '../EnumMappings/InternalToExternalEnumMappings';
import { DataValue } from '../Models/GetDataModels';
import { ParametersService } from '../Services/ParametersService';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { DataValueFactory } from '../Utils/DataValueFactory';
import { ErrorHelpers } from '../Utils/ErrorHelpers';
import { Param } from '../Utils/Param';

export class ParameterImpl {
  private _allowableValues: Contract.ParameterDomainRestriction;
  private _globalFieldName: string;
  private _parameterInfo: ParameterInfo;

  public constructor(parameterInfo: ParameterInfo, private _registryId: number) {
    this.setParameterInfo(parameterInfo);
  }

  public get name(): string {
    return this._parameterInfo.name;
  }

  public get currentValue(): DataValue {
    return DataValueFactory.MakeParameterDataValue(this._parameterInfo.currentValue, this._parameterInfo.dataType);
  }

  public get dataType(): DataType {
    return InternalToExternalEnumMappings.dataType.convert(this._parameterInfo.dataType);
  }

  public get id(): string {
    return this._globalFieldName;
  }

  public get allowableValues(): Contract.ParameterDomainRestriction {
    return this._allowableValues;
  }

  public changeValueAsync(newValue: string | number | boolean | Date): Promise<DataValue> {
    ErrorHelpers.verifyParameter(newValue, 'newValue');

    const coercedValue = Param.serializeParameterValue(newValue);
    const parametersService = ApiServiceRegistry.get(this._registryId).getService<ParametersService>(ServiceNames.Parameters);
    return parametersService.changeParameterValueAsync(this._globalFieldName, coercedValue).then((parameterInfo) => {
      this.setParameterInfo(parameterInfo);
      return this.currentValue;
    });
  }

  private setParameterInfo(parameterInfo: ParameterInfo): void {
    this._parameterInfo = parameterInfo;
    this._globalFieldName = parameterInfo.fieldName;

    const type = InternalToExternalEnumMappings.allowableValues.convert(parameterInfo.allowableValuesType);
    let listValues: Array<DataValue> | undefined;
    let minValue: DataValue | undefined;
    let maxValue: DataValue | undefined;
    let stepSize: number | undefined;
    let dateStepPeriod: PeriodType | undefined;

    if (type === ParameterValueType.List) {
      const values = parameterInfo.allowableValues || [];
      listValues = values.map((val) => DataValueFactory.MakeParameterDataValue(val, parameterInfo.dataType));
    } else if (type === ParameterValueType.Range) {
      minValue = parameterInfo.minValue && DataValueFactory.MakeParameterDataValue(parameterInfo.minValue, parameterInfo.dataType);
      maxValue = parameterInfo.maxValue && DataValueFactory.MakeParameterDataValue(parameterInfo.maxValue, parameterInfo.dataType);
      stepSize = parameterInfo.stepSize;
      dateStepPeriod = parameterInfo.dateStepPeriod && InternalToExternalEnumMappings.dateStepPeriod.convert(parameterInfo.dateStepPeriod);
    }

    this._allowableValues = {
      type: type,
      allowableValues: listValues,
      minValue: minValue,
      maxValue: maxValue,
      stepSize: stepSize,
      dateStepPeriod: dateStepPeriod,
    };
  }
}
