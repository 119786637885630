import * as Contract from '@tableau/api-external-contract-js';
import { EmbeddingTableauEventType } from '@tableau/api-external-contract-js';

export class TableauSheetEvent implements Contract.EmbeddingTableauSheetEvent {
  private _sheet: Contract.EmbeddingSheet;

  public get sheet(): Contract.EmbeddingSheet {
    return this._sheet;
  }

  public constructor(type: EmbeddingTableauEventType, sheet: Contract.EmbeddingSheet) {
    this._sheet = sheet;
  }
}
