import * as Contract from '@tableau/api-external-contract-js';
import { SheetType } from '@tableau/api-external-contract-js';
import { SheetInfoImpl, SheetUtils, TableauError } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { Workbook } from './Workbook';

export class SheetInfo implements Contract.SheetInfo {
  public constructor(private _sheetInfoImpl: SheetInfoImpl, private _workbookImpl: EmbeddingWorkbookImpl) {}

  public get name(): string {
    return this._sheetInfoImpl.name;
  }

  public get sheetType(): SheetType {
    return this._sheetInfoImpl.sheetType;
  }

  public get index(): number {
    if (this._sheetInfoImpl.index !== undefined) {
      return this._sheetInfoImpl.index;
    }

    throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError, `index not implemented`);
  }

  public get isActive(): boolean {
    if (this._sheetInfoImpl.isActive) {
      return this._sheetInfoImpl.isActive;
    }

    return false;
  }

  public get isHidden(): boolean {
    if (this._sheetInfoImpl.isHidden) {
      return this._sheetInfoImpl.isHidden;
    }
    return false;
  }

  public get workbook(): Contract.EmbeddingWorkbook {
    return new Workbook(this._workbookImpl);
  }

  public get url(): string {
    if (this._sheetInfoImpl.url) {
      return this._sheetInfoImpl.url;
    }
    return '';
  }

  public get size(): Contract.SheetSize {
    if (SheetUtils.isValidSheetSize(this._sheetInfoImpl.sheetSize)) {
      return this._sheetInfoImpl.sheetSize;
    }
    throw new TableauError(Contract.EmbeddingErrorCodes.NotImplemented, 'Not implemented');
  }
}
