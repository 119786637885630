import { Message } from './interface/MessageTypes';
import { PreparedMessage } from './interface/PreparedMessage';

/**
 * Implementation of the PreparedMessage interface used to post messages between
 * two frames using window.postMessage
 */
export class CrossFramePreparedMessage implements PreparedMessage {
  /**
   * Creates an instance of CrossFramePreparedMessage.
   * @param _message The message to be sent
   * @param _target The target window where the message will be sent
   * @param _origin The targetOrigin where this message can be received
   */
  public constructor(private _message: Message, private _target: Window, private _origin: string) {}

  public get messageGuid(): string {
    return this._message.msgGuid;
  }

  public send(): PreparedMessage {
    this._target.postMessage(this._message, this._origin);
    return this;
  }
}
