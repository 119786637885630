import * as Contract from '@tableau/api-external-contract-js';
import { TableauDialogType } from '@tableau/api-external-contract-js/lib/src/ExternalContract/Embedding/Enums';
import {
  CrossFrameMessenger,
  CustomViewInfoModel,
  EmbeddingBootstrapInfo,
  FirstVizSizeKnownEvent as FirstVizSizeKnownModel,
  InternalApiDispatcher,
  INTERNAL_CONTRACT_VERSION,
  NotificationId,
  ToolbarStateEvent,
  VersionEqualTo,
  VersionLessThan,
  VersionNumber,
} from '@tableau/api-internal-contract-js';
import {
  ApiServiceRegistry,
  CrossFrameDispatcher,
  CustomViewImpl,
  DataSourceService,
  NotificationService,
  registerAllSharedServices,
  ServiceNames,
  SheetUtils,
  TableauError,
  VizService,
} from '@tableau/api-shared-js';
import { TableauVizBase } from '../Components/TableauVizBase';
import { FirstVizSizeKnownEvent } from '../Events/FirstVizSizeKnownEvent';
import { TabSwitchedEvent } from '../Events/TabSwitchedEvent';
import { ToolbarStateChangedEvent } from '../Events/ToolbarStateChangedEvent';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { CustomView } from '../Models/CustomView';
import { createVizUrl, EmbeddingUrlMode } from '../Models/EmbeddingVizUrl';
import { VizSize } from '../Models/VizSize';
import { EmbeddingServiceNames, registerAllEmbeddingServices, registerInitializationEmbeddingServices, ToolbarService } from '../Services';
import { HtmlElementHelpers } from '../Utils/HtmlElementHelpers';

export class VizImpl {
  private _workbookImpl: EmbeddingWorkbookImpl;
  private _frameUrl: URL;
  private _vizSize: VizSize;
  private _automaticUpdatesArePaused = false;
  private _dispatcher: InternalApiDispatcher;
  private _messenger: CrossFrameMessenger;
  private readonly _resizeEventType = 'resize';
  private _windowResizeHandler?: () => void;

  // Used to store the custom view when the custom view event is fired before the interactive event
  private _customViewsTemp: CustomViewInfoModel | null;

  public constructor(
    private _viz: TableauVizBase,
    private _iframe: HTMLIFrameElement,
    private _src: string,
    desiredMode: EmbeddingUrlMode,
    private _options: Contract.VizSettings | Contract.VizAuthoringSettings,
    private _filters: Contract.FilterParameters[],
    private _params: Contract.VizParameter[],
    private _customParams: Contract.CustomParameter[],
    private _embeddingId: number,
  ) {
    if (!this._src) {
      throw new TableauError(
        Contract.EmbeddingErrorCodes.InternalError,
        'We should not have attempted to render the component without a src',
      );
    }
    if (!this._iframe) {
      throw new TableauError(Contract.EmbeddingErrorCodes.InternalError, 'Iframe has not been created yet');
    }

    this._frameUrl = createVizUrl(
      this._src,
      desiredMode,
      this._options,
      this._embeddingId,
      this._filters,
      this._params,
      this._customParams,
    );
  }

  public get workbookImpl(): EmbeddingWorkbookImpl {
    return this._workbookImpl;
  }

  public get iframe(): HTMLIFrameElement {
    return this._iframe;
  }

  public get embeddingId(): number {
    return this._embeddingId;
  }

  public get automaticUpdatesArePaused(): boolean {
    return this._automaticUpdatesArePaused;
  }

  // TODO: TFS 892510 TabSwitchEvent etc is resposible for updating the state
  public set automaticUpdatesArePaused(isAutoUpdate: boolean) {
    this._automaticUpdatesArePaused = isAutoUpdate;
  }

  public initializeViz(): void {
    const iframeWindow = this._iframe.contentWindow;
    if (!iframeWindow) {
      throw new TableauError(Contract.EmbeddingErrorCodes.InternalError, 'Iframe has not been created yet');
    }
    try {
      this._messenger = new CrossFrameMessenger(window, iframeWindow, this._frameUrl.origin);

      // We need the notification service for the bootstrap flow.
      this._dispatcher = new CrossFrameDispatcher(this._messenger);
      registerInitializationEmbeddingServices(this._dispatcher, this.embeddingId);

      const initializationService = ApiServiceRegistry.get(this.embeddingId).getService<NotificationService>(ServiceNames.Initialization);
      const vizSizeKnownUnregister = initializationService.registerHandler(
        NotificationId.FirstVizSizeKnown,
        () => true,
        (model: FirstVizSizeKnownModel) => {
          this.handleVizSizeKnownEvent(model);
          vizSizeKnownUnregister();
        },
      );
      const vizInteractiveUnregister = initializationService.registerHandler(
        NotificationId.VizInteractive,
        () => true,
        (model: EmbeddingBootstrapInfo) => {
          this.handleVizInteractiveEvent(model);
          vizInteractiveUnregister();
        },
      );
      initializationService.registerHandler(
        NotificationId.ToolbarStateChanged,
        () => true,
        (model: ToolbarStateEvent) => this.handleToolbarStateEvent(model),
      );
      initializationService.registerHandler(
        NotificationId.TabSwitched,
        () => true,
        (model: EmbeddingBootstrapInfo) => this.handleTabSwitch(model),
      );
      initializationService.registerHandler(
        NotificationId.CustomViewsLoaded,
        () => true,
        (model: CustomViewInfoModel) => this.handleCustomViews(model),
      );
      initializationService.registerHandler(
        NotificationId.CustomViewRemoved,
        () => true,
        (model: CustomViewInfoModel) => this.handleCustomViewRemoved(model),
      );
      initializationService.registerHandler(
        NotificationId.CustomViewSaved,
        () => true,
        (model: CustomViewInfoModel) => this.handleCustomViewSaved(model),
      );
      initializationService.registerHandler(
        NotificationId.CustomViewSetDefault,
        () => true,
        (model: CustomViewInfoModel) => this.handleCustomViewSetDefault(model),
      );
      this._messenger.startListening();
      this._iframe.src = this._frameUrl.toString();
    } catch (e) {
      throw new TableauError(Contract.EmbeddingErrorCodes.InternalError, 'Unexpected error during initialization.');
    }
  }

  public dispose(): void {
    if (this._messenger) {
      this._messenger.stopListening();
    }
    this.removeWindowResizeHandler();
  }

  public getCurrentSrcAsync(): Promise<string> {
    const service = ApiServiceRegistry.get(this.embeddingId).getService<VizService>(ServiceNames.Viz);
    return service.getCurrentSrcAsync();
  }

  public revertAllAsync(): Promise<void> {
    const service = ApiServiceRegistry.get(this.embeddingId).getService<ToolbarService>(EmbeddingServiceNames.ToolbarService);
    return service.revertAllAsync();
  }

  public redoAsync(): Promise<void> {
    const service = ApiServiceRegistry.get(this.embeddingId).getService<ToolbarService>(EmbeddingServiceNames.ToolbarService);
    return service.redoAsync();
  }

  public undoAsync(): Promise<void> {
    const service = ApiServiceRegistry.get(this.embeddingId).getService<ToolbarService>(EmbeddingServiceNames.ToolbarService);
    return service.undoAsync();
  }

  public refreshDataAsync(): Promise<void> {
    const service = ApiServiceRegistry.get(this.embeddingId).getService<DataSourceService>(ServiceNames.DataSourceService);
    return service.refreshAsync();
  }

  public pauseAutomaticUpdatesAsync(): Promise<void> {
    if (this._automaticUpdatesArePaused) {
      return Promise.resolve();
    }
    return this.setAutoUpdateAsync(false);
  }

  public resumeAutomaticUpdatesAsync(): Promise<void> {
    if (!this._automaticUpdatesArePaused) {
      return Promise.resolve();
    }
    return this.setAutoUpdateAsync(true);
  }

  public toggleAutomaticUpdatesAsync(): Promise<void> {
    return this.setAutoUpdateAsync(this._automaticUpdatesArePaused);
  }

  public exportImageAsync(): Promise<void> {
    const service = ApiServiceRegistry.get(this.embeddingId).getService<ToolbarService>(EmbeddingServiceNames.ToolbarService);
    return service.exportImageAsync();
  }

  public displayDialogAsync(dialogType: TableauDialogType): Promise<void> {
    const service = ApiServiceRegistry.get(this.embeddingId).getService<ToolbarService>(EmbeddingServiceNames.ToolbarService);
    switch (dialogType) {
      case TableauDialogType.ExportWorkbook:
        if (!this.workbookImpl.canDownloadWorkbook) {
          throw new TableauError(Contract.EmbeddingErrorCodes.DownloadWorkbookNotAllowed, 'Download workbook is not allowed');
        }
        return service.displayDownloadWorkbookDialogAsync();
      case TableauDialogType.ExportPDF:
        return service.displayExportPdfDialogAsync();
      case TableauDialogType.ExportPowerPoint:
        return service.displayExportPowerpointDialogAsync();
      case TableauDialogType.ExportData:
        return service.displayExportDataDialogAsync();
      case TableauDialogType.ExportCrossTab:
        return service.displayExportCrosstabDialogAsync();
      case TableauDialogType.Share:
        return service.displayShareDialogAsync();
      default:
        throw new TableauError(Contract.EmbeddingErrorCodes.UnknownDialogType, 'Unknown dialog type');
    }
  }

  private setAutoUpdateAsync(state: boolean): Promise<void> {
    const service = ApiServiceRegistry.get(this.embeddingId).getService<ToolbarService>(EmbeddingServiceNames.ToolbarService);
    return service.setAutoUpdateAsync(state).then<void>(() => {
      this._automaticUpdatesArePaused = !state;
      return;
    });
  }

  private isVersionCompatible(platformVersion: VersionNumber | undefined): boolean {
    // Platform version will be undefined in 2021.4.
    // Return false when loading a 3.1+ library against 2021.4.
    if (!platformVersion) {
      return false;
    }
    // If our platform is less than the external library version, return false
    return VersionLessThan(INTERNAL_CONTRACT_VERSION, platformVersion) || VersionEqualTo(INTERNAL_CONTRACT_VERSION, platformVersion);
  }

  private handleVizInteractiveEvent(bootstrapInfo: EmbeddingBootstrapInfo): void {
    // Embedding API will currently block all api calls/notifications if there is an incompatible version.
    if (!this._viz.disableVersionCheck && !this.isVersionCompatible(bootstrapInfo.platformVersion)) {
      this._messenger.stopListening();
      throw new TableauError(
        Contract.EmbeddingErrorCodes.IncompatibleVersionError,
        'The version of the Embedding library is not compatible with the version of Tableau.' +
          ' The visualization will load, but the Embedding API methods and events are not available.',
      );
    }

    registerAllSharedServices(this._dispatcher, this.embeddingId);
    registerAllEmbeddingServices(this._dispatcher, this.embeddingId);

    this._viz.initializeEvents();

    // These are the steps involved. It's critical that this is in order
    // 1. Create the workbook
    // 2. Process Custom Views
    // 3. Send FirstInteractive event
    // 4. Send CustomViewLoaded event
    this._workbookImpl = new EmbeddingWorkbookImpl(bootstrapInfo, this.embeddingId);
    let updatedCustomViews: Array<CustomViewImpl | undefined> = [];
    if (this._customViewsTemp) {
      updatedCustomViews = this._workbookImpl.processCustomViews(NotificationId.CustomViewsLoaded, this._customViewsTemp);
    }
    this._viz.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.FirstInteractive));
    if (this._customViewsTemp) {
      this.sendCustomViewEvents(Contract.EmbeddingTableauEventType.CustomViewLoaded, updatedCustomViews);
      this._customViewsTemp = null;
    }
  }

  private handleVizSizeKnownEvent(model: FirstVizSizeKnownModel): void {
    const sheetSize = SheetUtils.getSheetSizeFromSizeConstraints(model.sheetSize);
    this._vizSize = new VizSize(sheetSize, model.chromeHeight);
    const vizSizeEvent = new FirstVizSizeKnownEvent(this._vizSize);
    this._viz.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.FirstVizSizeKnown, { detail: vizSizeEvent }));

    if (this._viz.fixedSize) {
      return;
    }

    this.refreshSize();
    this.addWindowResizeHandler();
  }

  private refreshSize(): void {
    const { height, width } = this.calculateLayoutSize();

    if (height === this._vizSize.chromeHeight) {
      // The chromeHeight is what is calculated for any UI contained within the iframe that
      // isn't the viz (e.g. toolbar, sheet tabs). If we calculate the height to be only a
      // big as a chromeHeight, then we are probably too early (e.g. mid-fullscreen change).
      // Related to defect 570417.
      return;
    }
    this._iframe.style.height = height + 'px';
    this._iframe.style.width = width + 'px';
  }

  private calculateLayoutSize(): { height: number; width: number } {
    const availableSize = this._viz.parentElement ? HtmlElementHelpers.getContentSize(this._viz.parentElement) : { height: 0, width: 0 };

    const { chromeHeight, sheetSize } = this._vizSize;

    let width = 0;
    let height = 0;

    const minSize = sheetSize.minSize || { height: 0, width: 0 };
    const maxSize = sheetSize.maxSize || { height: 0, width: 0 };

    // If it's an exact size, use it. The size of the container is disregarded.
    if (sheetSize.behavior === Contract.SheetSizeBehavior.Exactly) {
      width = maxSize.width;
      height = maxSize.height + chromeHeight;
    } else {
      let minWidth: number;
      let maxWidth: number;
      let minHeight: number;
      let maxHeight: number;

      switch (sheetSize.behavior) {
        case Contract.SheetSizeBehavior.Range:
          // The iframe should obey the range. As the size of the container changes,
          // the iframe changes size if it can remain within the range
          minWidth = minSize.width;
          maxWidth = maxSize.width;
          minHeight = minSize.height + chromeHeight;
          maxHeight = maxSize.height + chromeHeight;
          width = Math.max(minWidth, Math.min(maxWidth, availableSize.width));
          height = Math.max(minHeight, Math.min(maxHeight, availableSize.height));
          break;

        case Contract.SheetSizeBehavior.AtLeast:
          // The iframe should be no smaller than the minimum. As the size of the container changes,
          // the iframe changes size if it can remain above the minimum size.
          minWidth = minSize.width;
          minHeight = minSize.height + chromeHeight;
          width = Math.max(minWidth, availableSize.width);
          height = Math.max(minHeight, availableSize.height);
          break;

        case Contract.SheetSizeBehavior.AtMost:
          // The iframe should be no larger than the maximum. As the size of the container changes,
          // the iframe changes size if it can remain below the maximum size
          maxWidth = maxSize.width;
          maxHeight = maxSize.height + chromeHeight;
          width = Math.min(maxWidth, availableSize.width);
          height = Math.min(maxHeight, availableSize.height);
          break;

        case Contract.SheetSizeBehavior.Automatic:
          // the iframe should fill the containing element
          width = availableSize.width;
          height = Math.max(availableSize.height, chromeHeight);
          break;

        default:
          // We should never get here. The given size behavior is not one we know about. That would be a bug
          throw new TableauError(
            Contract.EmbeddingErrorCodes.InvalidSizeBehavior,
            'Unknown SheetSizeBehavior for viz: ' + sheetSize.behavior,
          );
      }
    }
    return { height, width };
  }

  private removeWindowResizeHandler(): void {
    if (!this._windowResizeHandler) {
      return;
    }

    window.removeEventListener(this._resizeEventType, this._windowResizeHandler);
  }

  private addWindowResizeHandler(): void {
    if (this._windowResizeHandler) {
      return;
    }

    this._windowResizeHandler = this.refreshSize.bind(this);
    window.addEventListener(this._resizeEventType, this._windowResizeHandler!);
  }

  private handleToolbarStateEvent(model: ToolbarStateEvent): void {
    const toolbarStateChangedEvent = new ToolbarStateChangedEvent(model.toolbarState.canRedo, model.toolbarState.canUndo);
    this._viz.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.ToolbarStateChanged, { detail: toolbarStateChangedEvent }));
  }

  private handleTabSwitch(bootstrapInfo: EmbeddingBootstrapInfo): void {
    // If we didn't receive an interactive event that initializes the workbook, then ignore the tabswitch event
    if (!this._workbookImpl) {
      return;
    }
    if (!bootstrapInfo.oldSheetName) {
      return;
    }

    const pendingTabSwitchPromise = this._workbookImpl.pendingTabSwitchPromise;
    this._workbookImpl.updateExistingActiveSheetReferences(bootstrapInfo.currWorksheetName);
    this._workbookImpl = new EmbeddingWorkbookImpl(bootstrapInfo, this.embeddingId);
    if (pendingTabSwitchPromise) {
      pendingTabSwitchPromise.resolve(this._workbookImpl);
    }

    const tabSwitchedEvent = new TabSwitchedEvent(bootstrapInfo.oldSheetName, bootstrapInfo.currWorksheetName);
    this._viz.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.TabSwitched, { detail: tabSwitchedEvent }));
  }

  private handleCustomViews(customViewsInfo: CustomViewInfoModel): void {
    // If workbook is not initialized, temporarily store the custom views info in VizImpl
    if (!this._workbookImpl) {
      this._customViewsTemp = customViewsInfo;
    } else {
      const updatedCustomViews = this._workbookImpl.processCustomViews(NotificationId.CustomViewsLoaded, customViewsInfo);

      const pendingShowCustomViewPromise = this._workbookImpl.pendingShowCustomViewPromise;
      if (pendingShowCustomViewPromise) {
        if (updatedCustomViews[0]) {
          pendingShowCustomViewPromise.resolve(updatedCustomViews[0]);
        } else {
          pendingShowCustomViewPromise.reject('No custom view.');
        }

        this._workbookImpl.clearPendingShowCustomViewPromise();
      }

      this.sendCustomViewEvents(Contract.EmbeddingTableauEventType.CustomViewLoaded, updatedCustomViews);
    }
  }

  private handleCustomViewRemoved(customViewsInfo: CustomViewInfoModel): void {
    const updatedCustomViews = this._workbookImpl.processCustomViews(NotificationId.CustomViewRemoved, customViewsInfo);
    this.sendCustomViewEvents(Contract.EmbeddingTableauEventType.CustomViewRemoved, updatedCustomViews);
  }

  private handleCustomViewSaved(customViewsInfo: CustomViewInfoModel): void {
    const updatedCustomViews = this._workbookImpl.processCustomViews(NotificationId.CustomViewSaved, customViewsInfo);
    this.sendCustomViewEvents(Contract.EmbeddingTableauEventType.CustomViewSaved, updatedCustomViews);
  }

  private handleCustomViewSetDefault(customViewsInfo: CustomViewInfoModel): void {
    const updatedCustomViews = this._workbookImpl.processCustomViews(NotificationId.CustomViewSetDefault, customViewsInfo);
    this.sendCustomViewEvents(Contract.EmbeddingTableauEventType.CustomViewSetDefault, updatedCustomViews);
  }

  private sendCustomViewEvents(tableauEvent: Contract.EmbeddingTableauEventType, updatedCustomViews: Array<CustomViewImpl | undefined>) {
    // Send an event only if there's an updated custom view
    for (let customView of updatedCustomViews) {
      if (customView) {
        const customViewEvent = { customView: new CustomView(customView, this._workbookImpl) } as Contract.CustomViewEvent;
        this._viz.dispatchEvent(new CustomEvent(tableauEvent, { detail: customViewEvent }));
      }
    }
  }
}
