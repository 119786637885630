import * as Contract from '@tableau/api-external-contract-js';
import { EmbeddingErrorCodes } from '@tableau/api-external-contract-js';
import { ApiServiceRegistry, ParametersService, ServiceNames, TableauError } from '@tableau/api-shared-js';
import { Parameter } from '../Models/Parameter';

export class ParameterChangedEvent implements Contract.EmbeddingParameterChangedEvent {
  public constructor(private _globalFieldName: string, private _embeddingId: number) {}

  public getParameterAsync(): Promise<Contract.EmbeddingParameter> {
    // Call down to our service to get the parameter back via its field name
    const service = ApiServiceRegistry.get(this._embeddingId).getService<ParametersService>(ServiceNames.Parameters);
    return service.findParameterByGlobalFieldNameAsync(this._globalFieldName).then((parameterImpl) => {
      if (parameterImpl === undefined) {
        throw new TableauError(EmbeddingErrorCodes.MissingParameter, `Cannot find parameter: ${this._globalFieldName}`);
      }

      return new Parameter(parameterImpl);
    });
  }
}
