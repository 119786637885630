import { ExecuteParameters, Model, NotificationId, VerbId, VersionNumber } from '../../JsApiInternalContract';
import { InitializationOptions } from '../../interface/InitializationOptions';

/**
 * Enum defining the 4 different types of messages we have defined
 */
export enum MessageType {
  Initialize = 'initialize',
  Notification = 'notification',
  Command = 'command',
  CommandResponse = 'command-response',
  Handshake = 'v-handshake',
  Ack = 'v-ack',
}

/**
 * The Message interface is the base interface for all the other
 * message type interfaces.
 */
export interface Message {
  /**
   * A unique id for this message
   */
  msgGuid: string;

  /**
   * The type of this message
   */
  msgType: MessageType;
}

/**
 * The initialize message is the first message which will be sent
 * from the javascript to set up communications
 */
export interface InitializeMessage extends Message {
  /**
   * The version of the api which the sender wants to use
   */
  apiVersion: VersionNumber;

  /**
   * The version of this messaging contract to be used. For now, there
   * should only be a single version but sending this along should help
   * if we need to add a new version in a future release
   */
  crossFrameVersion: VersionNumber;

  /**
   * Additional options that can be passed at the time of initialization
   */
  options?: InitializationOptions;
}

/**
 * This message is sent when a notification occurs from the preslayer
 */
export interface NotificationMessage extends Message {
  /**
   * The id for this type of notification
   */
  notificationId: NotificationId;

  /**
   * The data which came along with the notification
   */
  data: Model;
}

/**
 * Represents calling an internal contract command
 */
export interface CommandMessage extends Message {
  /**
   * The id of the command which should be executed
   */
  verbId: VerbId;

  /**
   * An object containing the parameters for the command
   */
  parameters: ExecuteParameters;
}

/**
 * This message is sent in response to a CommandMessage with the
 * result of that commands invocation
 */
export interface CommandResponseMessage extends Message {
  /**
   * Guid of the CommandMessage which this is in response to
   */
  commandGuid: string;

  /**
   * If there was an error returned from the command, this will be defined
   * and contain the error
   */
  error?: Model;

  /**
   * If the command executed successfully, this will contain the command result
   */
  data?: Model;
}

export interface HandshakeMessage extends Message {
  /**
   * Internal API contract version
   */
  platformVersion: VersionNumber;
}
