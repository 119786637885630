import * as Contract from '@tableau/api-external-contract-js';
import { SheetInfo, StoryModel, StoryPointModel } from '@tableau/api-internal-contract-js';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { StoryActivationService } from '../Services/StoryActivationService';
import { TableauError } from '../TableauError';
import { ErrorHelpers } from '../Utils/ErrorHelpers';
import { ShortLivedDeferred } from '../Utils/ShortLivedDeferred';
import { SheetImpl } from './SheetImpl';
import { SheetInfoImpl } from './SheetInfoImpl';
import { StoryPointImpl } from './StoryPointImpl';
import { StoryPointInfoImpl } from './StoryPointInfoImpl';

export class StoryImpl extends SheetImpl {
  private _activeStoryPointImpl: StoryPointImpl;
  private _storyPointInfoImpls: Array<StoryPointInfoImpl> = [];
  private _deferred: ShortLivedDeferred<StoryPointImpl>;

  public constructor(
    protected _sheetInfoImpl: SheetInfoImpl,
    storyModel: StoryModel,
    private _publishedSheetInfos: Array<SheetInfo>,
    protected _registryId: number,
  ) {
    super(_sheetInfoImpl, _registryId);
    this._deferred = new ShortLivedDeferred<StoryPointImpl>();
    this.initializeStory(storyModel);
  }

  private initializeStory(storyModel: StoryModel) {
    storyModel.storyPoints.forEach((storyPointModel) => {
      const isActive = storyPointModel.index === storyModel.activeStoryPointIndex;
      const storyPointInfoImpl = new StoryPointInfoImpl(
        storyPointModel.caption,
        storyPointModel.index,
        storyPointModel.storyPointId,
        isActive,
        storyPointModel.updated,
        this,
      );
      this._storyPointInfoImpls.push(storyPointInfoImpl);

      if (isActive) {
        this._activeStoryPointImpl = new StoryPointImpl(
          storyPointInfoImpl,
          this._publishedSheetInfos,
          this._registryId,
          storyPointModel.containedSheetInfo,
        );
      }
    });
  }

  private updateStoryInfo(index: number, storyPointModel: StoryPointModel) {
    if (!this._storyPointInfoImpls) {
      return;
    }

    let storyInfoImpl = this._storyPointInfoImpls[index];
    if (storyInfoImpl.storyPointId !== storyPointModel.storyPointId) {
      throw new TableauError(
        Contract.EmbeddingErrorCodes.StoryPointIdMismatch,
        `We should not be updating a story point when the IDs don't match. Existing storyPointID=${storyInfoImpl.storyPointId}, newStoryPointID=${storyPointModel.storyPointId}`,
      );
    }
    storyInfoImpl.caption = storyPointModel.caption;
    storyInfoImpl.updated = storyPointModel.updated;

    if (this._activeStoryPointImpl.storyPointId === storyPointModel.storyPointId) {
      this._activeStoryPointImpl.updated = storyInfoImpl.updated;
    }
  }

  public updateStory(storyPointModel: StoryPointModel) {
    if (!this._storyPointInfoImpls) {
      return;
    }

    this._storyPointInfoImpls.forEach((storyPointInfoImpl) => {
      const isActive = storyPointInfoImpl.storyPointId === storyPointModel.storyPointId;
      if (isActive) {
        // update the state
        storyPointInfoImpl.caption = storyPointModel.caption;
        storyPointInfoImpl.index = storyPointModel.index;
        storyPointInfoImpl.active = true;
        storyPointInfoImpl.updated = storyPointModel.updated;

        // re-initialize activeStoryPointImpl
        this._activeStoryPointImpl = new StoryPointImpl(
          storyPointInfoImpl,
          this._publishedSheetInfos,
          this._registryId,
          storyPointModel.containedSheetInfo,
        );
      } else {
        // set old ones to false
        storyPointInfoImpl.active = false;
      }
    });

    if (this.activeStoryPoint) {
      this._deferred.resolve(this.activeStoryPoint);
    }
  }

  public get activeStoryPoint(): StoryPointImpl | undefined {
    return this._activeStoryPointImpl;
  }

  public get storyPointsInfo(): Array<StoryPointInfoImpl> {
    return this._storyPointInfoImpls;
  }

  public get isActive(): boolean {
    return this._sheetInfoImpl.active;
  }

  public get isHidden(): boolean {
    return !!this._sheetInfoImpl.isHidden;
  }

  public activateNextStoryPointAsync(): Promise<StoryPointImpl> {
    if (this._activeStoryPointImpl.index === this._storyPointInfoImpls.length - 1) {
      return Promise.resolve(this._activeStoryPointImpl);
    }

    let promise = this._deferred.getNewPromiseOrThrowIfBusy();

    const service = ApiServiceRegistry.get(this._registryId).getService<StoryActivationService>(ServiceNames.StoryActivation);
    service.activateNextStoryPointAsync();
    return promise;
  }

  public activatePreviousStoryPointAsync(): Promise<StoryPointImpl> {
    if (this._activeStoryPointImpl.index === 0) {
      return Promise.resolve(this._activeStoryPointImpl);
    }

    let promise = this._deferred.getNewPromiseOrThrowIfBusy();

    const service = ApiServiceRegistry.get(this._registryId).getService<StoryActivationService>(ServiceNames.StoryActivation);
    service.activatePreviousStoryPointAsync();
    return promise;
  }

  public activateStoryPointAsync(index: number): Promise<StoryPointImpl> {
    ErrorHelpers.verifyParameter(index, 'index');
    ErrorHelpers.verifyParameterType(index, 'number', 'index');

    if (index < 0 || index >= this._storyPointInfoImpls.length) {
      throw new TableauError(Contract.EmbeddingErrorCodes.IndexOutOfRange, 'The index passed to this command is out of range.');
    }

    if (index === this._activeStoryPointImpl.index) {
      return Promise.resolve(this._activeStoryPointImpl);
    }

    let promise = this._deferred.getNewPromiseOrThrowIfBusy();

    const service = ApiServiceRegistry.get(this._registryId).getService<StoryActivationService>(ServiceNames.StoryActivation);
    service.activateStoryPointAsync(index);
    return promise;
  }

  public revertStoryPointAsync(index: number): Promise<StoryPointInfoImpl> {
    ErrorHelpers.verifyParameter(index, 'index');
    ErrorHelpers.verifyParameterType(index, 'number', 'index');

    if (index < 0 || index >= this._storyPointInfoImpls.length) {
      throw new TableauError(Contract.EmbeddingErrorCodes.IndexOutOfRange, 'The index passed to this command is out of range.');
    }

    const service = ApiServiceRegistry.get(this._registryId).getService<StoryActivationService>(ServiceNames.StoryActivation);

    return service.revertStoryPointAsync(index).then<StoryPointInfoImpl>((response) => {
      this.updateStoryInfo(response.index, response);
      const storyPointInfoImpl = new StoryPointInfoImpl(
        response.caption,
        response.index,
        response.storyPointId,
        false,
        response.updated,
        this,
      );
      return storyPointInfoImpl;
    });
  }

  public clearPendingPromises(): void {
    if (this._deferred) {
      this._deferred.reject('All pending promises cleared');
    }
  }
}
