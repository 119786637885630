import { EmbeddingErrorCodes } from '@tableau/api-external-contract-js';
import {
  CommandResponseMessage,
  ExecuteParameters,
  ExecuteResponse,
  InternalApiDispatcher,
  Messenger,
  Model,
  NotificationHandler,
  NotificationMessage,
  VerbId,
} from '@tableau/api-internal-contract-js';
import { TableauError } from '../TableauError';

/**
 * This is an implementation of the InternalApiDispatcher interface which functions by passing messages
 * across a frame boundary. This is usually between the code where our javscript library has been included
 * by a 3rd party dev and another frame where Tableau server has content.
 */
export class CrossFrameDispatcher implements InternalApiDispatcher {
  // Collection of pending promises which are waiting to be resolved. When we receive a response back from the other frame,
  // these promises can be either resolved or rejected
  private _pendingPromises: { [messageGuid: string]: { resolve: (response: ExecuteResponse) => void; reject: (error: Model) => void } } =
    {};

  // The collection of notification handlers which have been registered with this dispatcher
  private _notificationHandlers: Array<NotificationHandler> = [];

  /**
   * Creates an instance of CrossFrameDispatcher which will use the given messenger to communicate
   * @param _messenger an instantiated and listening messenger object
   */
  public constructor(private _messenger: Messenger) {
    if (!this._messenger) {
      throw 'Missing messenger object';
    }

    // Set up our message handlers. We only care about incoming notifications and command responses
    this._messenger.setCommandResponseMessageHandler(this.onCommandResponse.bind(this));
    this._messenger.setNotificationMessageHandler(this.onNotification.bind(this));
  }

  ////// Start InternalApiDispatcher implementation

  public execute(verb: VerbId, parameters: ExecuteParameters): Promise<ExecuteResponse> {
    // To execute a verb, we first prepare a command message and then define a promise.
    const preparedMessage = this._messenger.prepareCommandMessage(verb, parameters);
    const promise = new Promise<ExecuteResponse>((resolve, reject) => {
      // Save off the pending promise by the messageGuid we are about to send. When a response is
      // received, we'll be able to resolve this promise with the result
      this._pendingPromises[preparedMessage.messageGuid] = { resolve: resolve, reject: reject };
    });

    // Actually send the message and return the promise
    preparedMessage.send();
    return promise;
  }

  public registerNotificationHandler(handler: NotificationHandler): void {
    this._notificationHandlers.push(handler);
  }

  public unregisterNotificationHandler(handler: NotificationHandler): void {
    this._notificationHandlers = this._notificationHandlers.filter((h) => h !== handler);
  }

  ////// End InternalApiDispatcher implementation

  private onCommandResponse(response: CommandResponseMessage): void {
    // We got a command response, look through the pending promises and resolve
    if (Object.keys(this._pendingPromises).indexOf(response.commandGuid) < 0) {
      return; // We don't have any reference to this command, just return
    }

    const pendingPromise = this._pendingPromises[response.commandGuid];

    // If we have an error defined, reject the promise
    if (response.error) {
      pendingPromise.reject(response.error);
    }

    // If we have data defined, resolve the promise
    if (response.data) {
      pendingPromise.resolve({ result: response.data });
    }

    // Clean up our pending promises object
    delete this._pendingPromises[response.commandGuid];
  }

  private onNotification(notificationMessage: NotificationMessage): void {
    // Go through each notification handler we have registered and let them know a notification came in
    for (const handler of this._notificationHandlers) {
      try {
        handler({ notificationId: notificationMessage.notificationId, data: notificationMessage.data });
      } catch (e) {
        // Incase of a IncompatibleVersionError, re-throw the error.
        if ((e as TableauError).errorCode === EmbeddingErrorCodes.IncompatibleVersionError) {
          throw e;
        }
        // Else Ignore  so if one handler errors, the other still get the message.
      }
    }
  }
}
