import * as Contract from '@tableau/api-external-contract-js';
import { TableauError } from '../TableauError';
import { Deferred, UnregisterTimeoutInMilliseconds } from './Deferred';

/**
 * A Deferred wrapper class adding functionality to reject unresponsive promises after timeout
 *
 * this class handles two things:

 *    1. Adds timeout logic for deferred
 *    2. It blocks multiple calls from executing at the same time.
 *
 * @export
 * @class ShortLivedDeferred
 * @template TResolve The type used to resolve the promise.
 * @template TReject The type used to reject the promise. Defaults to any.
 *
 */
export class ShortLivedDeferred<TResolve, TReject = any> {
  private _deferred: Deferred<TResolve, TReject> | undefined;
  private _timeoutId;
  constructor(private _timeoutInMilliseconds = UnregisterTimeoutInMilliseconds) {}

  public getNewPromiseOrThrowIfBusy(): Promise<TResolve> {
    if (this._deferred) {
      throw new TableauError(Contract.SharedErrorCodes.ApiExecutionError, 'This api cannot be executed till previous call is resolved.');
    }
    this._deferred = new Deferred();

    this._timeoutId = setTimeout(() => {
      const error = new TableauError(
        Contract.SharedErrorCodes.Timeout,
        `Api failed to complete in ${this._timeoutInMilliseconds / 1000} seconds`,
      );
      this.reject(error);
    }, this._timeoutInMilliseconds);

    return this._deferred.promise;
  }

  public resolve(response: TResolve | PromiseLike<TResolve>): void {
    if (this._deferred) {
      clearTimeout(this._timeoutId);
      this._deferred.resolve(response);
      this._deferred = undefined;
    }
  }

  public reject(error: any): void {
    if (this._deferred) {
      clearTimeout(this._timeoutId);
      this._deferred.reject(error);
      this._deferred = undefined;
    }
  }
}
