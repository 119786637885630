import * as Contract from '@tableau/api-external-contract-js';
import { NotificationId, WorkbookPublishedAsModel } from '@tableau/api-internal-contract-js';
import { TableauError } from '@tableau/api-shared-js';
import { VizImpl } from '../Impl/VizImpl';
import { EmbeddingUrlMode } from '../Models/EmbeddingVizUrl';
import { WebComponentManager } from '../WebComponentManager';
import { AttributeEventType, EventHandlerFn, TableauVizBase } from './TableauVizBase';

/**
 * Represents the entry point for the `<tableau-authoring-viz>` custom HTML element.
 * This class is specifically focused on transferring information between the HTML and
 * the Viz, so it should have as little logic as possible.  Most of the logic should be
 * in {@link VizImpl}.
 */
export class TableauAuthoringViz extends TableauVizBase implements Contract.AuthoringViz {
  // ========================================== Begin Custom Element definition ==========================================

  //#region Reactions
  public static get observedAttributes(): string[] {
    // Take caution before adding to this list because for every observed attribute change
    // we unregister and re-render the viz
    return [...super.observedAttributes, ...Object.values(Contract.VizAuthoringAttributes)];
  }
  //#endregion Reaction

  protected getAttributeEvents(): AttributeEventType[] {
    return [
      [Contract.VizSharedAttributes.OnEditInDesktopButtonClicked, Contract.EmbeddingTableauEventType.EditInDesktopButtonClicked],
      [Contract.VizSharedAttributes.OnFirstInteractive, Contract.EmbeddingTableauEventType.FirstInteractive],
      [Contract.VizSharedAttributes.OnFirstVizSizeKnown, Contract.EmbeddingTableauEventType.FirstVizSizeKnown],
      [Contract.VizAuthoringAttributes.OnWorkbookPublished, Contract.EmbeddingTableauEventType.WorkbookPublished],
      [Contract.VizAuthoringAttributes.OnWorkbookPublishedAs, Contract.EmbeddingTableauEventType.WorkbookPublishedAs],
      [Contract.VizAuthoringAttributes.OnWorkbookReadyToClose, Contract.EmbeddingTableauEventType.WorkbookReadyToClose],
    ];
  }

  protected createVizImpl(customParams: Contract.CustomParameter[]): VizImpl {
    if (!this.src) {
      throw new TableauError(
        Contract.EmbeddingErrorCodes.InternalError,
        'We should not have attempted to render the component without a src',
      );
    }

    const vizqlOptions = this.constructVizqlOptions();
    this._embeddingIdCounter = WebComponentManager.registerWebComponent(this);
    const vizImpl = new VizImpl(
      this,
      this.iframe,
      this.src,
      EmbeddingUrlMode.Authoring,
      vizqlOptions,
      [] /* filters */,
      [] /* params */,
      customParams,
      this._embeddingIdCounter,
    );

    vizImpl.initializeViz();

    return vizImpl;
  }

  private constructVizqlOptions(): Contract.VizAuthoringSettings {
    const options: Contract.VizAuthoringSettings = {
      token: this.token,
      touchOptimize: this.touchOptimize,
      hideEditInDesktopButton: this.hideEditInDesktopButton,
      suppressDefaultEditBehavior: this.suppressDefaultEditBehavior,
      hideCloseButton: this.hideCloseButton,
      debug: this.debug,
    };

    return options;
  }

  protected getRegisteredEvents(): EventHandlerFn[] {
    return super.getRegisteredEvents().concat([
      [
        NotificationId.WorkbookPublished,
        () => true,
        () => this.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.WorkbookPublished)),
      ],
      [NotificationId.WorkbookPublishedAs, () => true, (model: WorkbookPublishedAsModel) => this.handleWorkbookPublishedAsEvent(model)],
      [
        NotificationId.WorkbookReadyToClose,
        () => true,
        () => this.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.WorkbookReadyToClose)),
      ],
    ]);
  }

  private handleWorkbookPublishedAsEvent(model: WorkbookPublishedAsModel): void {
    const workbookPublishedAsEvent: Contract.WorkbookPublishedAsEvent = {
      newUrl: model.newUrl,
    };

    this.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.WorkbookPublishedAs, { detail: workbookPublishedAsEvent }));
  }

  //#region Simple Getters / Setters
  public set hideCloseButton(v: boolean) {
    if (v) {
      this.setAttribute(Contract.VizAuthoringAttributes.HideCloseButton, '');
    } else {
      this.removeAttribute(Contract.VizAuthoringAttributes.HideCloseButton);
    }
  }

  public get hideCloseButton(): boolean {
    return this.hasAttribute(Contract.VizAuthoringAttributes.HideCloseButton);
  }
  //#endregion

  // ========================================== End Custom Element definition ============================================
}
