import * as InternalContract from '@tableau/api-internal-contract-js';
import { DataValue } from '../Models/GetDataModels';

/**
 * In version 2 of the interface, we should collapse value into nativeValue, and do all this work over
 * in the platform.
 */
export class DataValueFactory {
  public static MakeParameterDataValue(internalDataValue: InternalContract.DataValue, dataType: InternalContract.DataType): DataValue {
    // Parameter values are all strings, convert from string to value
    return new DataValue(
      internalDataValue.value,
      InternalContract.DataTypeConverter.convertStringValueToNativeValue(internalDataValue.value, dataType),
      internalDataValue.formattedValue,
    );
  }

  public static MakeFilterDataValue(internalDataValue: InternalContract.DataValue): DataValue {
    // Filters already have native type in value, just use that for nativeValue also
    return new DataValue(internalDataValue.value, internalDataValue.value, internalDataValue.formattedValue);
  }

  public static MakeTableDataValue(internalDataValue: InternalContract.DataValue, dataType: InternalContract.DataType): DataValue {
    // DataTables contain boolean | number | string | date as string | '%null%
    // convertValueToNativeValue converts dates as string to dates, and any special to null
    // (boolean, numbers, strings are passed through)
    // Because of IncludeDataValuesOption - we can have undefined values, which are different than any special
    return new DataValue(
      internalDataValue.value,
      internalDataValue.value !== undefined
        ? InternalContract.DataTypeConverter.convertValueToNativeValue(internalDataValue.value, dataType)
        : undefined,
      internalDataValue.formattedValue,
    );
  }
}
