import { AskDataSettings, CustomParameter, EmbeddingErrorCodes } from '@tableau/api-external-contract-js';
import { AskDataOptionNames, INTERNAL_CONTRACT_VERSION } from '@tableau/api-internal-contract-js';
import { ApiVersion, TableauError } from '@tableau/api-shared-js';
import { EmbeddingUrlBuilder, ParametersMap, validateUrl } from './EmbeddingUrlBuilder';

export function createAskDataUrl(src: string, options: AskDataSettings, embeddingId: number, customParams: CustomParameter[]) {
  // strip params in URL, all custom params should come through 'askDataOptions', 'filters' or 'customParams'.
  const srcWithoutQueryParams = src.split('?')[0];

  let url: URL;
  try {
    url = new URL(srcWithoutQueryParams);
    validateUrl(url);
  } catch (error) {
    throw new TableauError(EmbeddingErrorCodes.InvalidUrl, (error as Error).message);
  }
  const defaultParams = createAskDataDefaultParameters(url, embeddingId);
  const builder = new EmbeddingAskDataUrlBuilder(url)
    .appendDefaultParameters(defaultParams)
    .appendUserOptions(options)
    .appendCustomParams(customParams)
    .setToken(options.token);

  return builder.build();
}

function createAskDataDefaultParameters(url: URL, embeddingId: number): ParametersMap {
  const defaultParameters: ParametersMap = new Map();
  const internalVersion = `${INTERNAL_CONTRACT_VERSION.major}.${INTERNAL_CONTRACT_VERSION.minor}.${INTERNAL_CONTRACT_VERSION.fix}`;
  defaultParameters.set(AskDataOptionNames.ApiInternalVersion, internalVersion);

  const externalVersion = ApiVersion.Instance.formattedValue; // maj.min.fix (no build)
  defaultParameters.set(AskDataOptionNames.ApiExternalVersion, externalVersion);
  defaultParameters.set(AskDataOptionNames.ApiID, `embhost${embeddingId}`);
  defaultParameters.set(AskDataOptionNames.AskDataWebComponent, 'true');

  return defaultParameters;
}

export class EmbeddingAskDataUrlBuilder extends EmbeddingUrlBuilder {
  public constructor(_url: URL) {
    super();
    this._url = _url;
    this._optionNames = AskDataOptionNames;
  }

  /**
   * Sanitizes parameter values before they are added to the search params.
   * @param parameterName The name of the parameter. Some parameters require special handling.
   * @param value The raw value of the parameter.
   */
  protected sanitizeParameterValue(parameterName: string, value: unknown): string {
    return this.sanitizeValue(value);
  }
}
