import * as Contract from '@tableau/api-external-contract-js';

export class ToolbarStateChangedEvent implements Contract.EmbeddingToolbarStateChangedEvent {
  public constructor(private _canRedo: boolean, private _canUndo: boolean) {}

  public get canRedo(): boolean {
    return this._canRedo;
  }

  public get canUndo(): boolean {
    return this._canUndo;
  }
}
