/**
 * Enumerates the different modes that are represented with a URL relating to a Tableau
 * visualization.
 *
 * The names of these modes are important! They are used in the URL to indicate the mode (called
 * module in VizPortal) and are part of the VizPortal and VizClient routing schemes.
 */
export const VizUrlMode = {
  Viewing: 'views',
  Authoring: 'authoring',
} as const;
export type VizUrlMode = typeof VizUrlMode[keyof typeof VizUrlMode];

/**
 * Represents a URL that contains a mode of operation (viewing, authoring, etc.), along with a
 * specific workbook/sheet. Site names are also tracked. Query parameters are preserved, but this
 * class is ignorant of any semantic meaning assigned to them.
 *
 * This class is designed to be immutable. You can use the various `withX` methods to change state
 * on a cloned version.
 *
 * @example <caption>How to create an instance</caption>
 * const vizUrl = VizUrl.create('http://www.example.com/t/site/views/workbook/sheet');
 *
 * @example <caption>How to clone an instance</caption>
 * const cloned = VizUrl.create(vizUrl);
 *
 * @example <caption>How to change values</caption>
 * const vizUrl = VizUrl.create('http://www.example.com/t/site/views/workbook/sheet')
 *   .withMode(VizUrlMode.Authoring)
 *   .withWorkbookId('newWorkbook')
 *   .withSheetId('newSheet');
 * expect(vizUrl.toString()).toBe('http://www.example.com/t/site/authoring/newWorkbook/newSheet');
 *
 * TODO: TFSID 1351171: Move this out into its own module and use it in VizClient.
 */
export class VizUrl {
  private readonly _url: URL;
  private readonly _segments: VizUrlPathSegments;

  public get mode(): VizUrlMode {
    return this._segments.mode;
  }

  public get siteId(): string | undefined {
    return this._segments.siteId;
  }

  public get workbookId(): string | undefined {
    return this._segments.workbookId;
  }

  public get sheetId(): string | undefined {
    return this._segments.sheetId;
  }

  public get customView(): CustomViewPathPart | undefined {
    return this._segments.customView;
  }

  private constructor(originalUrl: URL, segments: VizUrlPathSegments) {
    this._url = originalUrl;
    this._segments = segments;
  }

  /**
   * Constructs a new URL representing a particular workbook/sheet in a particular mode (viewing,
   * authoring, etc.).
   * @param url An existing URL or href to parse.
   */
  public static create(url: URL | VizUrl | string): VizUrl {
    const clonedUrl: URL = VizUrl._canonicalizeVizPortalRoutingHashes(new URL(url.toString()));
    const segments: VizUrlPathSegments = VizUrl._parsePathName(clonedUrl.pathname);

    clonedUrl.pathname = VizUrl._buildPathName(segments);
    return new VizUrl(clonedUrl, segments);
  }

  public toURL(): URL {
    return new URL(this.toString());
  }

  public toString(): string {
    return this._url.toString();
  }

  /**
   * This returns a {@link VizUrl} in the requested mode, if the mode is valid.
   * @param desiredMode The desired {@link VizUrlMode} for a viz.
   * @returns A {@link VizUrl} in the desired mode; throws if the requested mode change is invalid.
   */
  public withMode(desiredMode: VizUrlMode): VizUrl {
    // no need to do anything if the mode isn't changing
    if (this._segments.mode === desiredMode) {
      return this;
    }

    const modeSegments = this._getSegmentsForMode(desiredMode);
    return this._makeVizUrlFromPathSegments(modeSegments);
  }

  /**
   * This returns a {@link VizUrl} with the requested custom view.
   * @param customView The desired {@link CustomViewPathPart} for a viz.
   * @returns A {@link VizUrl} with the requested view parameters, or no-op if the URL is a non-viewing URL.
   */
  public withCustomView(customView: CustomViewPathPart): VizUrl {
    if (customView.luid === this.customView?.luid && customView.name === this.customView.name) {
      return this;
    }

    const modeSegments = this._getSegmentsForMode(this.mode);
    modeSegments.customView = customView;
    return this._makeVizUrlFromPathSegments(modeSegments);
  }

  /**
   * This returns a {@link VizUrl} with the requested sheet ID.
   * @param sheetId The desired {@link sheetId} for a viz.
   * @returns A {@link VizUrl} with the desired sheet ID, or no-op if the URL is already for the desired sheet.
   */
  public withSheetId(sheetId: string): VizUrl {
    if (this.sheetId === sheetId) {
      return this;
    }

    const modeSegments = { ...this._segments, sheetId: sheetId };
    return this._makeVizUrlFromPathSegments(modeSegments);
  }

  /**
   * This returns a {@link VizUrl} with the requested workbook ID.
   * @param workbookId The desired {@link workbookId} for a viz.
   * @returns A {@link VizUrl} with the desired workbook ID, or no-op if the URL is already for the desired workbook.
   */
  public withWorkbookId(workbookId: string): VizUrl {
    if (this.workbookId === workbookId) {
      return this;
    }

    const modeSegments = { ...this._segments, workbookId: workbookId };
    return this._makeVizUrlFromPathSegments(modeSegments);
  }

  private _getSegmentsForMode(desiredMode: VizUrlMode): VizUrlPathSegments {
    if (desiredMode === VizUrlMode.Authoring) {
      this._segments.customView = undefined;
    }

    return { ...this._segments, mode: desiredMode };
  }

  private _makeVizUrlFromPathSegments(modeSegments: VizUrlPathSegments): VizUrl {
    const modePathName = VizUrl._buildPathName(modeSegments);
    const modeUrl = new URL(this._url.toString());
    modeUrl.pathname = modePathName;

    return new VizUrl(modeUrl, modeSegments);
  }

  /**
   * Parses the input url and returns all the parts in its pathname.
   */
  private static _parsePathName(pathName: string): VizUrlPathSegments {
    // Split up the constituent parts of the path.
    // For example, 'https://devplat.tableautest.com/t/site/authoring/Workbook/Sheet'
    //   parts = ['t', 'site', 'authoring', 'Workbook', 'Sheet']
    const parts: string[] = pathName.split('/').filter((x) => x);
    if (parts.length === 0) {
      throw new Error('Invalid path name');
    }

    let siteId: string | undefined;

    // check if the site root is in the t/siteName form
    if (parts[0] === 't') {
      if (parts.length < 2) {
        throw new Error(`Invalid site in path '${pathName}'`);
      }

      siteId = parts[1];
      parts.splice(0, 2);
    }

    // Extract the mode.
    //   parts = ['authoring', 'Workbook', 'Sheet']
    const modePathPart: string | undefined = parts.shift();
    if (!modePathPart) {
      throw new Error(`Missing mode in path '${pathName}'`);
    }

    const mode: VizUrlMode = modePathPart as VizUrlMode;
    if (!Object.values(VizUrlMode).includes(mode)) {
      throw new Error(`Invalid Viz Url Mode '${modePathPart}' in path '${pathName}'`);
    }

    // Extract the workbook and sheet
    //   parts = ['Workbook', 'Sheet']
    if (parts.length === 0) {
      throw new Error(`Missing workbook/sheet name in path '${pathName}'`);
    }

    const workbookId: string = parts.shift() ?? '';
    const sheetId: string | undefined = parts.shift();

    if (parts.length !== 0 && parts.length !== 2) {
      throw new Error(`Invalid path name: unknown parts after sheet id: '${pathName}'`);
    }

    // Handle the possibility that the URL has a 2 part custom view consisting of an ID and a name
    let customView: CustomViewPathPart | undefined = undefined;
    if (parts.length === 2) {
      const viewId: string = parts.shift() ?? '';
      const viewName: string = parts.shift() ?? '';
      customView = { luid: viewId, name: viewName };
    }

    return { mode, siteId, workbookId, sheetId, customView };
  }

  /**
   * This canonicalizes any URL that contains '/#/site' or '/#/'.
   * Examples:
   * 'https://tableau.com/#/site/alpodev/views/Workbook/Sheet' would return 'https://tableau.com/t/alpodev/views/Workbook/Sheet';
   * 'https://tableau.com/#/views/Workbook/Sheet' would return 'https://tableau.com/views/Workbook/Sheet'.
   */
  private static _canonicalizeVizPortalRoutingHashes(url: URL): URL {
    let urlStr = url.toString();
    urlStr = urlStr.replace('/#/site/', '/t/').replace('/#/', '/');
    return new URL(urlStr);
  }

  /**
   * Builds the pathname of a URL from its parts.
   * @param segments Parts of a URL pathname.
   * @returns A string that represents a URL pathname.
   */
  private static _buildPathName(segments: VizUrlPathSegments): string {
    const parts: string[] = [];

    if (segments.siteId) {
      parts.push('t');
      parts.push(segments.siteId);
    }

    parts.push(segments.mode);

    if (segments.workbookId) {
      parts.push(segments.workbookId);
    }

    if (segments.sheetId) {
      parts.push(segments.sheetId);
    }

    if (segments.customView && segments.mode === VizUrlMode.Viewing) {
      parts.push(segments.customView.luid);
      parts.push(segments.customView.name);
    }
    const path = parts.join('/');
    return path;
  }
}

/**
 * This interface contains the two URL components of a custom view: a locally unique identifier (LUID), and a human readable name.
 * CustomViewPathParts can only be used to modify URLs in viewing mode.
 *
 * @example <caption>How to add a custom view path to a URL</caption>
 * const vizUrl = VizUrl.create('https://tableau.com/views/workbook/sheet');
 * const customViewPathPart: CustomViewPathPart = new CustomViewPathPart('viewLuid', 'viewName');
 * expect(vizUrl.withCustomView(customViewPathPart)).toEqual(
 *   VizUrl.create('https://tableau.com/views/workbook/sheet/viewLuid/viewName'));
 *
 */
export interface CustomViewPathPart {
  readonly luid: string;
  readonly name: string;
}

/**
 * Represents a parsed {@link URL.pathname} corresponding to parts of a Tableau Viz.
 */
interface VizUrlPathSegments {
  readonly mode: VizUrlMode;
  readonly siteId?: string;
  readonly workbookId?: string;
  readonly sheetId?: string;
  customView?: CustomViewPathPart;
}
