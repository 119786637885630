import * as Contract from '@tableau/api-external-contract-js';
import { StoryPointImpl, StoryPointInfoImpl } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { StoryPoint } from '../Models/StoryPoint';
import { StoryPointInfo } from '../Models/StoryPointInfo';

export class StoryPointSwitchedEvent implements Contract.StoryPointSwitchedEvent {
  public constructor(
    private _oldStoryPointInfoImpl: StoryPointInfoImpl,
    private _currStoryPointImpl: StoryPointImpl,
    private _workbookImpl: EmbeddingWorkbookImpl,
  ) {}

  public get oldStoryPointInfo(): StoryPointInfo {
    return new StoryPointInfo(this._oldStoryPointInfoImpl, this._workbookImpl);
  }

  public get newStoryPoint(): StoryPoint {
    return new StoryPoint(this._currStoryPointImpl, this._workbookImpl);
  }
}
