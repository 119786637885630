import * as Contract from '@tableau/api-external-contract-js';
import { SheetInfo, SheetType, SizeConstraint } from '@tableau/api-internal-contract-js';
import { TableauError } from '../TableauError';

export class SheetUtils {
  // This is a direct port from SheetSize.cs - SheetSizeFactory
  public static getSheetSizeFromSizeConstraints(sizeConstraint: SizeConstraint): Contract.SheetSize {
    const { minWidth, minHeight, maxWidth, maxHeight } = sizeConstraint;

    if (minHeight === 0 && minWidth === 0) {
      if (maxHeight === 0 && maxWidth === 0) {
        return {
          behavior: Contract.SheetSizeBehavior.Automatic,
        };
      }

      return {
        behavior: Contract.SheetSizeBehavior.AtMost,
        maxSize: { width: maxWidth, height: maxHeight },
      };
    }

    if (maxHeight === 0 && maxWidth === 0) {
      return {
        behavior: Contract.SheetSizeBehavior.AtLeast,
        minSize: { width: minWidth, height: minHeight },
      };
    }

    if (maxHeight === minHeight && maxWidth === minWidth && minWidth > 0) {
      return {
        behavior: Contract.SheetSizeBehavior.Exactly,
        minSize: { width: minWidth, height: minHeight },
        maxSize: { width: minWidth, height: minHeight },
      };
    }

    // 'Fit Width' for a DSD layout
    const isDsdLayout = minWidth === 0 && maxWidth === 0;

    return {
      behavior: Contract.SheetSizeBehavior.Range,
      minSize: { width: minWidth, height: minHeight },
      maxSize: { width: isDsdLayout ? 2147483647 : maxWidth, height: maxHeight },
    };
  }

  public static createAutomaticSize(): Contract.SheetSize {
    let behavior = Contract.SheetSizeBehavior.Automatic;
    let minSize = { width: 0, height: 0 };
    let maxSize = { width: 0, height: 0 };
    return { behavior, minSize, maxSize };
  }

  public static getPublishedSheetInfoByName(name: string, sheetInfos: Array<SheetInfo>): SheetInfo | undefined {
    const sheetInfo = sheetInfos.find((sheet) => sheet.name === name);
    return sheetInfo;
  }

  public static getSheetTypeEnum(internalSheetType: SheetType): Contract.SheetType {
    let sheetType: Contract.SheetType;
    switch (internalSheetType) {
      case SheetType.Worksheet: {
        sheetType = Contract.SheetType.Worksheet;
        break;
      }
      case SheetType.Dashboard: {
        sheetType = Contract.SheetType.Dashboard;
        break;
      }
      case SheetType.Story: {
        sheetType = Contract.SheetType.Story;
        break;
      }
      default: {
        throw new TableauError(Contract.SharedErrorCodes.ServerError, 'Invalid SheetType');
      }
    }
    return sheetType;
  }

  public static isValidSheetSize(sizeObject: Contract.Size | Contract.SheetSize): sizeObject is Contract.SheetSize {
    return (<Contract.SheetSize>sizeObject).behavior !== undefined;
  }
}
