import * as Contract from '@tableau/api-external-contract-js';
import { DashboardImpl } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { DashboardObject } from './DashboardObject';
import { Sheet } from './Sheet';
import { StoryPoint } from './StoryPoint';
import { Worksheet } from './Worksheet';

export class Dashboard extends Sheet implements Contract.EmbeddingDashboard {
  public constructor(private _dashboardImpl: DashboardImpl, workbookImpl: EmbeddingWorkbookImpl) {
    super(_dashboardImpl, workbookImpl);
    _dashboardImpl.initializeWithPublicInterfaces();
  }

  public get objects(): Array<Contract.EmbeddingDashboardObject> {
    return this._dashboardImpl.objects.map((object) => {
      return new DashboardObject(object, this._workbookImpl);
    });
  }

  public get worksheets(): Array<Contract.EmbeddingWorksheet> {
    return this._dashboardImpl.worksheetsImpl.map((object) => {
      return new Worksheet(object, this._workbookImpl);
    });
  }

  public get parentStoryPoint(): Contract.StoryPoint | null {
    if (this._dashboardImpl.parentStoryPoint == null) {
      // Dashboard doesn't belong to a story point. Return null.
      return null;
    }

    return new StoryPoint(this._dashboardImpl.parentStoryPoint, this._workbookImpl);
  }

  public getFiltersAsync(): Promise<Array<Contract.Filter>> {
    return this._dashboardImpl.getFiltersAsync();
  }

  public applyFilterAsync(
    fieldName: string,
    values: Array<string>,
    updateType: Contract.FilterUpdateType,
    options: Contract.FilterOptions,
  ): Promise<string> {
    return this._dashboardImpl.applyFilterAsync(fieldName, values, updateType, options);
  }
}
