import * as Contract from '@tableau/api-external-contract-js';
import { ErrorCodes, IncludeDataValuesOption } from '@tableau/api-external-contract-js';
import {
  ApiShowDataTableFormat,
  DataTable as DataTableInternalContract,
  DataTableReader as DataTableReaderInternalContract,
  ExecuteParameters,
  HighlightedMarksTable,
  ParameterId,
  SelectedMarksTable,
  UnderlyingDataTable,
  VerbId,
  VisualId,
} from '@tableau/api-internal-contract-js';
import { v4 as uuidv4 } from 'uuid';
import { TableauError } from '../../../ApiShared';
import { ExternalToInternalEnumMappings } from '../../EnumMappings/ExternalToInternalEnumMappings';
import { Column, DataTable, MarkInfo } from '../../Models/GetDataModels';
import { DataTableReader } from '../../Models/GetDataTableReaderModels';
import { DataValueFactory } from '../../Utils/DataValueFactory';
import { GetDataService, GetDataType } from '../GetDataService';
import { ServiceNames } from '../ServiceRegistry';
import { ServiceImplBase } from './ServiceImplBase';

export class GetDataServiceImpl extends ServiceImplBase implements GetDataService {
  // The extension/embedding instance uses the same cache for all DataTableReaders.
  private viewDataTableCacheId: string = uuidv4();

  public get serviceName(): string {
    return ServiceNames.GetData;
  }

  public getMaxRowLimit(): number {
    return 10000;
  }

  public getViewDataTableCacheId(): string {
    return this.viewDataTableCacheId;
  }

  private getLimitedMaxRows(requestedRows: number): number {
    const rowCountLimit = this.getMaxRowLimit() + 1;
    return requestedRows > 0 && requestedRows < rowCountLimit ? requestedRows : rowCountLimit;
  }

  public getUnderlyingDataAsync(
    visualId: VisualId,
    getType: GetDataType,
    ignoreAliases: boolean,
    ignoreSelection: boolean,
    includeAllColumns: boolean,
    columnsToIncludeById: Array<string>,
    maxRows: number,
    includeDataValuesOption: IncludeDataValuesOption,
  ): Promise<DataTable> {
    // Create all of our parameters
    const summaryData = getType === GetDataType.Summary;
    const functionName = summaryData ? 'getSummaryDataAsync' : 'getUnderlyingDataAsync';
    const verb = summaryData ? VerbId.GetDataSummaryData : VerbId.GetUnderlyingData;
    const requestMaxRows = verb === VerbId.GetUnderlyingData ? this.getLimitedMaxRows(maxRows) : maxRows;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: functionName,
    };
    parameters[ParameterId.VisualId] = visualId;
    parameters[ParameterId.IgnoreAliases] = ignoreAliases;
    parameters[ParameterId.IgnoreSelection] = ignoreSelection;
    parameters[ParameterId.IncludeAllColumns] = includeAllColumns;
    parameters[ParameterId.ColumnsToIncludeById] = this.verifyIncludeColumnArray(columnsToIncludeById);
    parameters[ParameterId.MaxRows] = requestMaxRows;
    parameters[ParameterId.ShowDataTableFormat] = ExternalToInternalEnumMappings.showDataTableFormatType.convert(includeDataValuesOption);

    return this.execute(verb, parameters).then<DataTable>((response) => {
      const responseData = response.result as UnderlyingDataTable;
      return this.processResultsTable(responseData.data, responseData.isSummary);
    });
  }

  public getSummaryDataReaderAsync(
    visualId: VisualId,
    pageRowCount: number,
    ignoreAliases: boolean,
    ignoreSelection: boolean,
    includeAllColumns: boolean,
    columnsToIncludeById: Array<string>,
    includeDataValuesOption: IncludeDataValuesOption,
  ): Promise<DataTableReader> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getSummaryDataReaderAsync',
      [ParameterId.ViewDataTableCacheId]: this.getViewDataTableCacheId(),
      [ParameterId.VisualId]: visualId,
      [ParameterId.PageRowCount]: pageRowCount,
      [ParameterId.IgnoreAliases]: ignoreAliases,
      [ParameterId.IgnoreSelection]: ignoreSelection,
      [ParameterId.IncludeAllColumns]: includeAllColumns,
      [ParameterId.ColumnsToIncludeById]: this.verifyIncludeColumnArray(columnsToIncludeById),
      [ParameterId.ShowDataTableFormat]: ExternalToInternalEnumMappings.showDataTableFormatType.convert(includeDataValuesOption),
    };

    return this.execute(VerbId.GetDataSummaryDataReader, parameters).then<DataTableReader>((response) => {
      const responseData = response.result as DataTableReaderInternalContract;

      return new DataTableReader(responseData.id, responseData.totalRowCount, pageRowCount, this._registryId);
    });
  }

  public getSummaryColumnsInfoAsync(visualId: VisualId): Promise<Array<Contract.Column>> {
    // Create all the parameters for GetDataType of Summary with 1 row, and only native values
    // Then return just the columns
    const verb = VerbId.GetDataSummaryData;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getSummaryColumnsInfoAsync',
      [ParameterId.VisualId]: visualId,
      [ParameterId.IgnoreAliases]: true,
      [ParameterId.IgnoreSelection]: true,
      [ParameterId.IncludeAllColumns]: true,
      [ParameterId.MaxRows]: 1,
      [ParameterId.ShowDataTableFormat]: ApiShowDataTableFormat.NativeValuesOnly,
    };

    return this.execute(verb, parameters).then<Array<Contract.Column>>((response) => {
      const underlyingDataTable = response.result as UnderlyingDataTable;
      const dataTable = underlyingDataTable.data;
      const columns = dataTable.headers.map((h) => new Column(h.fieldCaption, h.fieldName, h.dataType, h.isReferenced, h.index));
      return columns;
    });
  }

  public getSelectedMarksAsync(visualId: VisualId): Promise<Contract.MarksCollection> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getSelectedMarksAsync',
      [ParameterId.VisualId]: visualId,
    };
    return this.execute(VerbId.GetSelectedMarks, parameters).then<Contract.MarksCollection>((response) => {
      const responseData = response.result as SelectedMarksTable;
      return {
        data: responseData.data.map((table) => this.processResultsTable(table, true)),
      };
    });
  }

  public getHighlightedMarksAsync(visualId: VisualId): Promise<Contract.MarksCollection> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getHighlightedMarksAsync',
      [ParameterId.VisualId]: visualId,
    };
    return this.execute(VerbId.GetHighlightedMarks, parameters).then<Contract.MarksCollection>((response) => {
      const responseData = response.result as HighlightedMarksTable;
      return {
        data: responseData.data.map((table) => this.processResultsTable(table, true)),
      };
    });
  }

  public getDataSourceDataAsync(
    dataSourceId: string,
    ignoreAliases: boolean,
    maxRows: number,
    columnsToInclude: Array<string>,
    columnsToIncludeById: Array<string>,
    includeDataValuesOption: IncludeDataValuesOption,
  ): Promise<DataTable> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getDataSourceDataAsync',
      [ParameterId.DataSourceId]: dataSourceId,
      [ParameterId.IgnoreAliases]: ignoreAliases,
      [ParameterId.MaxRows]: this.getLimitedMaxRows(maxRows),
      [ParameterId.ColumnsToInclude]: this.verifyIncludeColumnArray(columnsToInclude),
      [ParameterId.ColumnsToIncludeById]: this.verifyIncludeColumnArray(columnsToIncludeById),
      [ParameterId.ShowDataTableFormat]: ExternalToInternalEnumMappings.showDataTableFormatType.convert(includeDataValuesOption),
    };

    return this.execute(VerbId.GetDataSourceData, parameters).then<DataTable>((response) => {
      const responseData = response.result as UnderlyingDataTable;
      return this.processResultsTable(responseData.data, false);
    });
  }

  public getLogicalTableDataAsync(
    datasourceId: string,
    logicalTableId: string,
    ignoreAliases: boolean,
    maxRows: number,
    columnsToInclude: Array<string>,
    columnsToIncludeById: Array<string>,
    includeDataValuesOption: IncludeDataValuesOption,
  ): Promise<DataTable> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getLogicalTableDataAsync',
      [ParameterId.ColumnsToInclude]: columnsToInclude,
      [ParameterId.ColumnsToIncludeById]: this.verifyIncludeColumnArray(columnsToIncludeById),
      [ParameterId.DataSourceId]: datasourceId,
      [ParameterId.IgnoreAliases]: ignoreAliases,
      [ParameterId.LogicalTableId]: logicalTableId,
      [ParameterId.MaxRows]: this.getLimitedMaxRows(maxRows),
      [ParameterId.ShowDataTableFormat]: ExternalToInternalEnumMappings.showDataTableFormatType.convert(includeDataValuesOption),
    };

    return this.execute(VerbId.GetLogicalTableData, parameters).then<DataTable>((response) => {
      const responseData = response.result as UnderlyingDataTable;
      return this.processResultsTable(responseData.data, false);
    });
  }

  public getLogicalTableDataReaderAsync(
    datasourceId: string,
    logicalTableId: string,
    pageRowCount: number,
    ignoreAliases: boolean,
    columnsToIncludeById: Array<string>,
    includeDataValuesOption: IncludeDataValuesOption,
  ): Promise<DataTableReader> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getLogicalTableDataReaderAsync',
      [ParameterId.ViewDataTableCacheId]: this.getViewDataTableCacheId(),
      [ParameterId.DataSourceId]: datasourceId,
      [ParameterId.LogicalTableId]: logicalTableId,
      [ParameterId.PageRowCount]: pageRowCount,
      [ParameterId.IgnoreAliases]: ignoreAliases,
      [ParameterId.ColumnsToIncludeById]: this.verifyIncludeColumnArray(columnsToIncludeById),
      [ParameterId.ShowDataTableFormat]: ExternalToInternalEnumMappings.showDataTableFormatType.convert(includeDataValuesOption),
    };

    return this.execute(VerbId.GetLogicalTableDataReader, parameters).then<DataTableReader>((response) => {
      const responseData = response.result as DataTableReaderInternalContract;

      return new DataTableReader(responseData.id, responseData.totalRowCount, pageRowCount, this._registryId);
    });
  }

  public getUnderlyingTableDataAsync(
    visualId: VisualId,
    logicalTableId: string,
    ignoreAliases: boolean,
    ignoreSelection: boolean,
    includeAllColumns: boolean,
    columnsToIncludeById: Array<string>,
    maxRows: number,
    includeDataValuesOption: IncludeDataValuesOption,
  ): Promise<Contract.DataTable> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getUnderlyingTableDataAsync',
      [ParameterId.VisualId]: visualId,
      [ParameterId.LogicalTableId]: logicalTableId,
      [ParameterId.IgnoreAliases]: ignoreAliases,
      [ParameterId.IgnoreSelection]: ignoreSelection,
      [ParameterId.IncludeAllColumns]: includeAllColumns,
      [ParameterId.ColumnsToIncludeById]: this.verifyIncludeColumnArray(columnsToIncludeById),
      [ParameterId.MaxRows]: this.getLimitedMaxRows(maxRows),
      [ParameterId.ShowDataTableFormat]: ExternalToInternalEnumMappings.showDataTableFormatType.convert(includeDataValuesOption),
    };

    return this.execute(VerbId.GetUnderlyingTableData, parameters).then<DataTable>((response) => {
      const responseData = response.result as UnderlyingDataTable;
      return this.processResultsTable(responseData.data, false);
    });
  }

  public getUnderlyingTableDataReaderAsync(
    visualId: VisualId,
    logicalTableId: string,
    pageRowCount: number,
    ignoreAliases: boolean,
    ignoreSelection: boolean,
    includeAllColumns: boolean,
    columnsToIncludeById: Array<string>,
    includeDataValuesOption: IncludeDataValuesOption,
  ): Promise<Contract.DataTableReader> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getUnderlyingTableDataReaderAsync',
      [ParameterId.ViewDataTableCacheId]: this.getViewDataTableCacheId(),
      [ParameterId.VisualId]: visualId,
      [ParameterId.LogicalTableId]: logicalTableId,
      [ParameterId.IgnoreAliases]: ignoreAliases,
      [ParameterId.IgnoreSelection]: ignoreSelection,
      [ParameterId.IncludeAllColumns]: includeAllColumns,
      [ParameterId.ColumnsToIncludeById]: this.verifyIncludeColumnArray(columnsToIncludeById),
      [ParameterId.ShowDataTableFormat]: ExternalToInternalEnumMappings.showDataTableFormatType.convert(includeDataValuesOption),
      [ParameterId.PageRowCount]: pageRowCount,
    };

    return this.execute(VerbId.GetUnderlyingTableDataReader, parameters).then<DataTableReader>((response) => {
      const responseData = response.result as DataTableReaderInternalContract;

      return new DataTableReader(responseData.id, responseData.totalRowCount, pageRowCount, this._registryId);
    });
  }

  private static summaryTableId = '';

  public getPageAsync(tableId: string, pageNumber: number, pageSize: number): Promise<Contract.DataTable> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getPageAsync',
      [ParameterId.ViewDataTableCacheId]: this.getViewDataTableCacheId(),
      [ParameterId.ViewDataTableId]: tableId,
      [ParameterId.DataRowIndex]: pageNumber * pageSize,
    };

    return this.execute(VerbId.GetDataTableReaderPage, parameters).then<DataTable>((response) => {
      const responseData = response.result as DataTableInternalContract;
      return this.processResultsPage(responseData, tableId === GetDataServiceImpl.summaryTableId ? true : false);
    });
  }

  public releaseAsync(tableId: string): Promise<void> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'release',
      [ParameterId.ViewDataTableCacheId]: this.getViewDataTableCacheId(),
      [ParameterId.ViewDataTableId]: tableId,
    };

    return this.execute(VerbId.ReleaseDataTableReader, parameters).then<void>((response) => {
      return;
    });
  }

  private verifyIncludeColumnArray(columns: Array<string>): Array<string> {
    // columns must be a valid array
    if (!Array.isArray(columns)) {
      throw new TableauError(ErrorCodes.InvalidParameter, 'columnsToInclude and columnsToIncludeById must be valid arrays');
    }

    // Remove any duplicates from the input array
    const columnsAsSet: Set<string> = new Set(columns);
    return Array.from(columnsAsSet);
  }

  protected processResultsTable(responseData: DataTableInternalContract, isSummary: boolean): DataTable {
    const headers = responseData.headers.map((h) => new Column(h.fieldCaption, h.fieldName, h.dataType, h.isReferenced, h.index));

    // TODO This should be controlled by a flag indicating whether this api will respond marks info or not
    let marks;
    if (responseData.marks) {
      marks = responseData.marks.map((h) => new MarkInfo(h.type, h.color, h.tupleId));
    }

    // Limit+1 is our sentinal that underlying data contains more rows than user is allowed to fetch.
    // Remove the last element so we always return MaxRowLimit
    const isTotalRowCountLimited = isSummary === false && responseData.dataTable.length === this.getMaxRowLimit() + 1;
    if (isTotalRowCountLimited) {
      responseData.dataTable.length -= 1;
    }

    const table = responseData.dataTable.map((row) => {
      return row.map((cell, index) => {
        return DataValueFactory.MakeTableDataValue(cell, headers[index].dataType);
      });
    });

    if (marks) {
      return new DataTable(table, headers, table.length, isTotalRowCountLimited, isSummary, marks);
    }
    return new DataTable(table, headers, table.length, isTotalRowCountLimited, isSummary);
  }

  protected processResultsPage(responseData: DataTableInternalContract, isSummary: boolean): DataTable {
    const headers = responseData.headers.map((h) => new Column(h.fieldCaption, h.fieldName, h.dataType, h.isReferenced, h.index));

    let marks;
    if (responseData.marks) {
      marks = responseData.marks.map((h) => new MarkInfo(h.type, h.color, h.tupleId));
    }

    const table = responseData.dataTable.map((row) => {
      return row.map((cell, index) => {
        return DataValueFactory.MakeTableDataValue(cell, headers[index].dataType);
      });
    });

    if (marks) {
      return new DataTable(table, headers, table.length, false, isSummary, marks);
    }
    return new DataTable(table, headers, table.length, false, isSummary);
  }
}
