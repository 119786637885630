import * as Contract from '@tableau/api-external-contract-js';
import {
  DateRangeType,
  EmbeddingErrorCodes,
  ErrorCodes,
  FilterDomainType,
  FilterType as ExternalFilterType,
  SharedErrorCodes,
} from '@tableau/api-external-contract-js';
import * as InternalContract from '@tableau/api-internal-contract-js';
import { ExecuteParameters, FilterType, ParameterId, VerbId, VisualId } from '@tableau/api-internal-contract-js';
import { TableauError } from '../../../ApiShared';
import { ExternalToInternalEnumMappings as ExternalEnumConverter } from '../../EnumMappings/ExternalToInternalEnumMappings';
import { InternalToExternalEnumMappings as InternalEnumConverter } from '../../EnumMappings/InternalToExternalEnumMappings';
import {
  CategoricalDomain,
  CategoricalFilter,
  HierarchicalDataValue,
  HierarchicalFilter,
  HierarchicalLevelDetail,
  RangeDomain,
  RangeFilter,
  RelativeDateFilter,
} from '../../Models/FilterModels';
import { DataValue } from '../../Models/GetDataModels';
import { DataValueFactory } from '../../Utils/DataValueFactory';
import { Param } from '../../Utils/Param';
import { FilterService } from '../FilterService';
import { ServiceNames } from '../ServiceRegistry';
import { ServiceImplBase } from './ServiceImplBase';

export class FilterServiceImpl extends ServiceImplBase implements FilterService {
  public get serviceName(): string {
    return ServiceNames.Filter;
  }

  public applyFilterAsync(
    visualId: VisualId,
    fieldName: string,
    values: Array<string>,
    updateType: Contract.FilterUpdateType,
    filterOptions: Contract.FilterOptions,
  ): Promise<string> {
    const verb = VerbId.ApplyCategoricalFilter;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'applyFilterAsync',
    };
    parameters[ParameterId.VisualId] = visualId;
    parameters[ParameterId.FieldName] = fieldName;
    if (!Array.isArray(values)) {
      throw new TableauError(ErrorCodes.InvalidParameter, 'values parameter for applyFilterAsync must be an array');
    }
    parameters[ParameterId.FilterValues] = values;
    parameters[ParameterId.FilterUpdateType] = ExternalEnumConverter.filterUpdateType.convert(updateType);
    parameters[ParameterId.IsExcludeMode] =
      filterOptions === undefined || filterOptions.isExcludeMode === undefined ? false : filterOptions.isExcludeMode;

    return this.execute(verb, parameters).then<string>((response) => {
      return fieldName;
    });
  }

  public applyRangeFilterAsync(visualId: VisualId, fieldName: string, filterOptions: Contract.RangeFilterOptions): Promise<string> {
    const verb = VerbId.ApplyRangeFilter;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'applyRangeFilterAsync',
    };

    if (filterOptions.min !== undefined && filterOptions.min !== null) {
      let min: string | number;
      if (filterOptions.min instanceof Date) {
        min = Param.serializeDateForPlatform(filterOptions.min);
      } else {
        min = filterOptions.min;
      }
      parameters[ParameterId.FilterRangeMin] = min;
    }

    if (filterOptions.max !== undefined && filterOptions.max !== null) {
      let max: string | number;
      if (filterOptions.max instanceof Date) {
        max = Param.serializeDateForPlatform(filterOptions.max);
      } else {
        max = filterOptions.max;
      }
      parameters[ParameterId.FilterRangeMax] = max;
    }

    // The null option is used with min+max for 'include-range' or 'include-range-or-null'
    if (filterOptions.nullOption) {
      parameters[ParameterId.FilterRangeNullOption] = ExternalEnumConverter.nullOptions.convert(filterOptions.nullOption);
    }

    parameters[ParameterId.FieldName] = fieldName;
    parameters[ParameterId.VisualId] = visualId;

    return this.execute(verb, parameters).then<string>((response) => {
      this.apiFilterHandlerCheckForCommandError(response.result as { [key: string]: string });
      return fieldName;
    });
  }

  public applyHierarchicalFilterAsync(
    visualId: VisualId,
    fieldName: string,
    values: Array<string> | Contract.HierarchicalLevels,
    updateType: Contract.FilterUpdateType,
    filterOptions: Contract.FilterOptions,
  ): Promise<string> {
    const verb = VerbId.HierarchicalFilter;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'applyHierarchicalFilterAsync',
    };
    parameters[ParameterId.VisualId] = visualId;
    parameters[ParameterId.FieldName] = fieldName;

    const hierarchicalLevels = (values as Contract.HierarchicalLevels).levels;

    if (Array.isArray(hierarchicalLevels) && hierarchicalLevels.length > 0) {
      parameters[ParameterId.FilterLevels] = hierarchicalLevels;
    } else if ((values as Array<String>).length > 0) {
      parameters[ParameterId.FilterValues] = values;
    } else {
      // the server command expects empty list for clearing the filter
      // it also expects eithers FilterLevels or FilterValues to be set
      parameters[ParameterId.FilterLevels] = [];
    }

    parameters[ParameterId.FilterUpdateType] = ExternalEnumConverter.filterUpdateType.convert(updateType);
    parameters[ParameterId.IsExcludeMode] = filterOptions && !!filterOptions.isExcludeMode;

    return this.execute(verb, parameters).then<string>((response) => {
      return fieldName;
    });
  }

  public clearFilterAsync(visualId: VisualId, fieldName: string): Promise<string> {
    const verb = VerbId.ClearFilter;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'clearFilterAsync',
    };
    parameters[ParameterId.VisualId] = visualId;
    parameters[ParameterId.FieldName] = fieldName;
    return this.execute(verb, parameters).then<string>((resposne) => {
      return fieldName;
    });
  }

  public applyRelativeDateFilterAsync(visualId: VisualId, fieldName: string, options: Contract.RelativeDateFilterOptions) {
    const verb = VerbId.ApplyRelativeDateFilter;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'applyRelativeDateFilterAsync',
    };
    parameters[ParameterId.VisualId] = visualId;
    parameters[ParameterId.FieldName] = fieldName;
    parameters[ParameterId.PeriodType] = ExternalEnumConverter.periodType.convert(options.periodType);
    parameters[ParameterId.DateRangeType] = ExternalEnumConverter.dateRangeType.convert(options.rangeType);
    if (options.rangeType === DateRangeType.LastN || options.rangeType === DateRangeType.NextN) {
      if (options.rangeN === undefined || options.rangeN === null) {
        throw new TableauError(
          EmbeddingErrorCodes.MissingRangeNForRelativeDateFilters,
          'Missing rangeN field for a relative date filter of LASTN or NEXTN.',
        );
      }
      parameters[ParameterId.RangeN] = options.rangeN;
    }

    if (options.anchorDate !== undefined && options.anchorDate !== null) {
      parameters[ParameterId.AnchorDate] = this.convertAnchorDate(options.anchorDate);
    }

    return this.execute(verb, parameters).then<string>((response) => {
      return response.result as string;
    });
  }

  public getFiltersAsync(visualId: VisualId): Promise<Array<Contract.Filter>> {
    const verb = VerbId.GetFilters;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getFiltersAsync',
    };
    parameters[ParameterId.VisualId] = visualId;
    return this.execute(verb, parameters).then<Array<Contract.Filter>>((response) => {
      const filters = response.result as Array<InternalContract.Filter>;
      return this.convertDomainFilters(filters);
    });
  }

  public getCategoricalDomainAsync(
    worksheetName: string,
    fieldId: string,
    domainType: FilterDomainType,
  ): Promise<Contract.CategoricalDomain> {
    const verb = VerbId.GetCategoricalDomain;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getCategoricalDomainAsync',
    };
    parameters[ParameterId.VisualId] = {
      worksheet: worksheetName,
    };

    parameters[ParameterId.FieldId] = fieldId;
    parameters[ParameterId.DomainType] = ExternalEnumConverter.filterDomainType.convert(domainType);
    return this.execute(verb, parameters).then<Contract.CategoricalDomain>((response) => {
      const domain = response.result as InternalContract.CategoricalDomain;
      return this.convertCategoricalDomain(domain, domainType);
    });
  }

  public getRangeDomainAsync(worksheetName: string, fieldId: string, domainType: FilterDomainType): Promise<Contract.RangeDomain> {
    const verb = VerbId.GetRangeDomain;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getRangeDomainAsync',
    };
    parameters[ParameterId.VisualId] = {
      worksheet: worksheetName,
    };

    parameters[ParameterId.FieldId] = fieldId;
    parameters[ParameterId.DomainType] = ExternalEnumConverter.filterDomainType.convert(domainType);
    return this.execute(verb, parameters).then<Contract.RangeDomain>((response) => {
      const domain = response.result as InternalContract.RangeDomain;

      return this.convertRangeDomain(domain, domainType);
    });
  }

  public getDashboardFiltersAsync(): Promise<Array<Contract.Filter>> {
    const verb = VerbId.GetDashboardFilters;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getDashboardFiltersAsync',
    };
    return this.execute(verb, parameters).then<Array<Contract.Filter>>((response) => {
      const filters = response.result as Array<InternalContract.Filter>;
      return this.convertDomainFilters(filters);
    });
  }

  public applyDashboardFilterAsync(
    fieldName: string,
    values: Array<string>,
    updateType: Contract.FilterUpdateType,
    filterOptions: Contract.FilterOptions,
  ): Promise<string> {
    const verb = VerbId.DashboardCategoricalFilter;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'applyDashboardFilterAsync',
    };
    parameters[ParameterId.FieldName] = fieldName;
    parameters[ParameterId.FilterValues] = values;
    parameters[ParameterId.FilterUpdateType] = ExternalEnumConverter.filterUpdateType.convert(updateType);
    parameters[ParameterId.IsExcludeMode] = filterOptions && !!filterOptions.isExcludeMode;

    return this.execute(verb, parameters).then<string>((response) => {
      return response.result as string;
    });
  }

  public async getAppliedWorksheetsAsync(worksheetName: string, fieldId: string): Promise<Array<string>> {
    const sharedFilterModel = await this.executeGetAppliedWorksheets(worksheetName, fieldId, 'getAppliedWorksheetsAsync');
    const worksheetNames: string[] = [];
    sharedFilterModel.worksheets?.map((worksheetInfo: InternalContract.SharedFilterWorksheetModel) => {
      if (worksheetInfo.isSelected) {
        worksheetNames.push(worksheetInfo.worksheetName);
      }
    });
    return worksheetNames;
  }

  public async setAppliedWorksheetsAsync(
    worksheetName: string,
    fieldName: string,
    fieldId: string,
    applyToWorksheets: Array<string>,
  ): Promise<Array<string>> {
    const sharedFilterModel = await this.executeGetAppliedWorksheets(worksheetName, fieldId, 'getAppliedWorksheetsAsyncInternal');
    if (!sharedFilterModel || !sharedFilterModel.worksheets) {
      throw new TableauError(SharedErrorCodes.InternalError, 'This filter does not apply to multiple worksheets');
    }

    const allowedWorksheets: string[] = [];
    let activeWorksheet = '';
    sharedFilterModel.worksheets.forEach((worksheet) => {
      // Get active worksheet
      if (worksheet.isActive) {
        activeWorksheet = worksheet.worksheetName;
      }

      // Populate allowed worksheets
      if (worksheet.isSelected || worksheet.isEnabled) {
        allowedWorksheets.push(worksheet.worksheetName);
      }
    });

    if (activeWorksheet === '') {
      throw new TableauError(SharedErrorCodes.InternalError, 'No active worksheet');
    }

    if (!applyToWorksheets.includes(activeWorksheet)) {
      throw new TableauError(SharedErrorCodes.InternalError, `${activeWorksheet} must be included in the applied worksheets`);
    }

    applyToWorksheets.forEach((sheet) => {
      // check if it's present within compatible sheets
      if (!allowedWorksheets.includes(sheet)) {
        throw new TableauError(SharedErrorCodes.InternalError, `The field ${fieldName} isn't applicable to the worksheet ${sheet}`);
      }
    });

    const verb = VerbId.ChangeSharedFilter;
    const parameters: ExecuteParameters = {};
    parameters[ParameterId.FunctionName] = 'setAppliedWorksheetsAsync';
    parameters[ParameterId.VisualId] = {
      worksheet: worksheetName,
    };
    parameters[ParameterId.FieldId] = fieldId;
    parameters[ParameterId.SharedFilterSheets] = applyToWorksheets;

    return this.execute(verb, parameters).then<string[]>((response) => {
      return applyToWorksheets;
    });
  }

  // Helper Methods

  private executeGetAppliedWorksheets(
    worksheetName: string,
    fieldId: string,
    telemetryFunctionName: string,
  ): Promise<InternalContract.SharedFilterModel> {
    const verb = VerbId.GetSharedFilter;
    const parameters: ExecuteParameters = {};
    parameters[ParameterId.FunctionName] = telemetryFunctionName;
    parameters[ParameterId.VisualId] = {
      worksheet: worksheetName,
    };
    parameters[ParameterId.FieldId] = fieldId;

    return this.execute(verb, parameters).then<InternalContract.SharedFilterModel>((response) => {
      const sharedFilterModel = response.result as InternalContract.SharedFilterModel;
      return sharedFilterModel;
    });
  }

  private convertDomainFilters(domainFilters: Array<InternalContract.Filter>): Array<Contract.Filter> {
    const filters: Array<Contract.Filter> = [];
    domainFilters.forEach((domainFilter) => {
      switch (domainFilter.filterType) {
        case FilterType.Categorical: {
          const filter = domainFilter as InternalContract.CategoricalFilter;
          if (filter) {
            filters.push(this.convertCategoricalFilter(filter));
          } else {
            throw new Error('Invalid Categorical Filter');
          }
          break;
        }

        case FilterType.Hierarchical: {
          const filter = domainFilter as InternalContract.HierarchicalFilter;
          if (filter) {
            filters.push(this.convertHierarchicalFilter(filter));
          } else {
            throw new Error('Invalid Hierarchical Filter');
          }
          break;
        }

        case FilterType.Range: {
          const filter = domainFilter as InternalContract.RangeFilter;
          if (filter) {
            filters.push(this.convertRangeFilter(filter));
          } else {
            throw new Error('Invalid Range Filter');
          }
          break;
        }

        case FilterType.RelativeDate: {
          const filter = domainFilter as InternalContract.RelativeDateFilter;
          if (filter) {
            filters.push(this.convertRelativeDateFilter(filter));
          } else {
            throw new Error('Invalid Relative Date Filter');
          }
          break;
        }

        default: {
          break;
        }
      }
    });
    return filters;
  }

  private convertCategoricalFilter(domainFilter: InternalContract.CategoricalFilter): Contract.CategoricalFilter {
    const appliedValues: Array<Contract.DataValue> = domainFilter.values.map((dv) => {
      return DataValueFactory.MakeFilterDataValue(dv);
    });

    return new CategoricalFilter(
      domainFilter.visualId.worksheet,
      domainFilter.fieldCaption,
      domainFilter.fieldName,
      FilterType.Categorical,
      this._registryId,
      appliedValues,
      domainFilter.isExclude,
      domainFilter.isAllSelected,
    );
  }

  private convertHierarchicalFilter(domainFilter: InternalContract.HierarchicalFilter): Contract.HierarchicalFilter {
    const appliedValues: Array<Contract.HierarchicalFilterDataValue> = domainFilter.values.map((hierarchicalDataValue) => {
      return new HierarchicalDataValue(
        DataValueFactory.MakeFilterDataValue(hierarchicalDataValue.value),
        hierarchicalDataValue.hierarchicalPath,
        hierarchicalDataValue.level,
      );
    });

    const levelDetails: Array<Contract.HierarchicalLevelDetail> = domainFilter.levelInfo.map((aLevel) => {
      return new HierarchicalLevelDetail(
        aLevel.name,
        InternalEnumConverter.hierarchicalLevelSelectionState.convert(aLevel.levelSelectionState),
      );
    });

    return new HierarchicalFilter(
      domainFilter.visualId.worksheet,
      domainFilter.fieldCaption,
      domainFilter.fieldName,
      FilterType.Hierarchical,
      this._registryId,
      domainFilter.dimensionName,
      domainFilter.hierarchyCaption,
      domainFilter.levels,
      levelDetails,
      appliedValues,
      domainFilter.isAllSelected,
    );
  }

  private convertRangeFilter(domainFilter: InternalContract.RangeFilter): Contract.RangeFilter {
    const minValue: DataValue = DataValueFactory.MakeFilterDataValue(domainFilter.min);
    const maxValue: DataValue = DataValueFactory.MakeFilterDataValue(domainFilter.max);
    return new RangeFilter(
      domainFilter.visualId.worksheet,
      domainFilter.fieldCaption,
      domainFilter.fieldName,
      FilterType.Range,
      this._registryId,
      minValue,
      maxValue,
      domainFilter.includeNullValues,
    );
  }

  private convertRelativeDateFilter(domainFilter: InternalContract.RelativeDateFilter): Contract.RelativeDateFilter {
    const anchorDateValue: DataValue = DataValueFactory.MakeFilterDataValue(domainFilter.anchorDate);
    return new RelativeDateFilter(
      domainFilter.visualId.worksheet,
      domainFilter.fieldCaption,
      domainFilter.fieldName,
      ExternalFilterType.RelativeDate,
      this._registryId,
      anchorDateValue,
      InternalEnumConverter.dateStepPeriod.convert(domainFilter.periodType),
      InternalEnumConverter.dateRangeType.convert(domainFilter.rangeType),
      domainFilter.rangeN,
    );
  }

  private convertCategoricalDomain(domain: InternalContract.CategoricalDomain, domainType: FilterDomainType): Contract.CategoricalDomain {
    const values: Array<DataValue> = domain.values.map((domainDv) => {
      return DataValueFactory.MakeFilterDataValue(domainDv);
    });
    return new CategoricalDomain(values, domainType);
  }

  private convertRangeDomain(domain: InternalContract.RangeDomain, domainType: FilterDomainType): Contract.RangeDomain {
    const min: DataValue = DataValueFactory.MakeFilterDataValue(domain.min);
    const max: DataValue = DataValueFactory.MakeFilterDataValue(domain.max);
    return new RangeDomain(min, max, domainType);
  }

  private convertAnchorDate(anchorDate: Date): string {
    // Converts a Date object into a string format that the server expects for date/time values.
    // If anchorDate doesn't represent a valid Date object, any of these would be NaN.
    const year = anchorDate.getUTCFullYear();
    const month = anchorDate.getUTCMonth() + 1;
    const day = anchorDate.getUTCDate();
    const hh = anchorDate.getUTCHours();
    const mm = anchorDate.getUTCMinutes();
    const sec = anchorDate.getUTCSeconds();

    if (isNaN(year) || isNaN(month) || isNaN(day) || isNaN(hh) || isNaN(mm) || isNaN(sec)) {
      throw new TableauError(EmbeddingErrorCodes.InvalidDateParameter, 'Invalid date parameter: anchorDate');
    }

    const result = `${year}-${month}-${day} ${hh}:${mm}:${sec}`;
    return result;
  }

  private apiFilterHandlerCheckForCommandError(serverPm: { [key: string]: string }) {
    if (!serverPm[InternalContract.ParameterId.ParameterError]) {
      return;
    }
    if (serverPm[InternalContract.ParameterId.InvalidFieldCaption]) {
      throw new TableauError(SharedErrorCodes.InvalidFilterFieldName, serverPm[InternalContract.ParameterId.InvalidFieldCaption]);
    }
    if (serverPm[InternalContract.ParameterId.InvalidValues]) {
      throw new TableauError(SharedErrorCodes.InvalidFilterFieldValue, serverPm[InternalContract.ParameterId.InvalidValues]);
    }
    if (serverPm[InternalContract.ParameterId.InvalidAggFieldName]) {
      throw new TableauError(SharedErrorCodes.InvalidAggregationFieldName, serverPm[InternalContract.ParameterId.InvalidAggFieldName]);
    }
    throw new TableauError(SharedErrorCodes.ServerError, 'Server Error');
  }
}
