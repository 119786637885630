/**
 * A Promise wrapper class allowing for resolving/rejecting the Promise outside the context of its constructor.
 *
 * @export
 * @class Deferred
 * @template TResolve The type used to resolve the promise.
 * @template TReject The type used to reject the promise. Defaults to any.
 * @link https://stackoverflow.com/a/34637436
 */
export class Deferred<TResolve, TReject = any> {
  private _promise: Promise<TResolve>;
  private _resolve: (response: TResolve | PromiseLike<TResolve>) => void;
  private _reject: (reason?: TReject) => void;

  /**
   * Creates an instance of Deferred.
   */
  constructor() {
    this._promise = new Promise<TResolve>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  /**
   * Gets the Promise.
   *
   * @readonly
   * @type {Promise<TResolve>}
   */
  get promise(): Promise<TResolve> {
    return this._promise;
  }

  /**
   * Resolves the wrapped Promise.
   *
   * @param {(TResolve | PromiseLike<TResolve>)} response The argument to be resolved by the Promise.
   * @returns {*}  {void}
   */
  resolve(response: TResolve | PromiseLike<TResolve>): void {
    if (!this._resolve) {
      return;
    }

    this._resolve(response);
  }

  /**
   *
   *
   * @param {TReject} [reason] The reason why the Promise rejected.
   * @returns {*}  {void}
   */
  reject(reason?: TReject): void {
    if (!this._reject) {
      return;
    }

    this._reject(reason);
  }
}

export const UnregisterTimeoutInMilliseconds = 30000;
