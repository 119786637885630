import * as Contract from '@tableau/api-external-contract-js';
import { VizSize } from '@tableau/api-external-contract-js';

export class FirstVizSizeKnownEvent implements Contract.FirstVizSizeKnownEvent {
  private _vizSize: VizSize;

  public get vizSize(): VizSize {
    return this._vizSize;
  }

  public constructor(vizSize: VizSize) {
    this._vizSize = vizSize;
  }
}
