import * as Contract from '@tableau/api-external-contract-js';

export class TabSwitchedEvent implements Contract.TabSwitchedEvent {
  public constructor(private _oldSheetName: string, private _newSheetName: string) {}

  public get oldSheetName() {
    return this._oldSheetName;
  }

  public get newSheetName() {
    return this._newSheetName;
  }
}
