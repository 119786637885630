import { WebComponentLocalSettings, WebComponentSettingsShared } from './WebComponentInterfaces';

/**
 * The names of the string constants you can use as HTML attributes for the <tableau-ask-data> web component.
 * @ignore
 */
export enum AskDataAttributes {
  Origin = 'origin',
  ShowEmbed = 'show-embed',
  ShowPin = 'show-pin',
  ShowSave = 'show-save',
  ShowShare = 'show-share',
}

export interface AskDataSettings extends WebComponentSettingsShared, WebComponentLocalSettings {
  /**
   * Indicates whether the embed button is hidden or visible.
   * ```
   * <tableau-ask-data id="tableauAskData" show-embed>
   * ```
   */
  showEmbed?: boolean;

  /**
   * Indicates whether the pin button is hidden or visible.
   * ```
   * <tableau-ask-data id="tableauAskData" show-pin>
   * ```
   */
  showPin?: boolean;

  /**
   * Indicates whether the save button is hidden or visible.
   * ```
   * <tableau-ask-data id="tableauAskData" show-save>
   * ```
   */
  showSave?: boolean;

  /**
   * Indicates whether the share button is hidden or visible.
   * ```
   * <tableau-ask-data id="tableauAskData" show-share>
   * ```
   */
  showShare?: boolean;

  /**
   *  Used for telemetry
   * ```
   * <tableau-ask-data id="tableauAskData" origin="embeddingSite">
   * ```
   */
  origin?: string;
}

/**
 * The interface for the top level AskData object.
 **/
export interface AskData extends AskDataSettings {}
