import { DashboardObjectPositionAndSizeUpdate, SharedErrorCodes } from '@tableau/api-external-contract-js';
import { DashboardObjectImpl } from '../Impl/DashboardObjectImpl';
import { TableauError } from '../TableauError';
import { Param } from './Param';

interface EnumLike {
  toString(): string;
}

/**
 * This class is used to construct common errors throughout the external
 * projects (api-shared, extensions-api, etc.).  It has some duplication with
 * the ErrorHelpers class in api-core, but is separate due to the need to throw
 * an external TableauError vs. an InternalTableauError.
 */
export class ErrorHelpers {
  /**
   * Throws with code InternalError.
   *
   * @param apiName name of api that was called.
   */
  public static apiNotImplemented(apiName: string): TableauError {
    return new TableauError(SharedErrorCodes.InternalError, `${apiName} API not yet implemented.`);
  }

  /**
   * Throws an internal error if argument is null or undefined.
   *
   * @param argumentValue value to verify
   * @param argumentName name of argument to verify
   */
  /*tslint:disable-next-line */
  public static verifyInternalValue(argumentValue: any, argumentName: string) {
    if (argumentValue === null || argumentValue === undefined) {
      throw new TableauError(SharedErrorCodes.InternalError, `${argumentValue} is invalid value for: ${argumentName}`);
    }
  }

  /**
   * Throws an InvalidParameter error if argument is null or undefined.
   *
   * @param argumentValue value to verify
   * @param argumentName name of argument to verify
   */
  /*tslint:disable-next-line */
  public static verifyParameter(argumentValue: any, argumentName: string) {
    if (argumentValue === null || argumentValue === undefined) {
      throw new TableauError(SharedErrorCodes.InvalidParameter, `${argumentValue} is invalid value for parameter: ${argumentName}`);
    }
  }

  /**
   * Throws an InvalidParameter error if argument is not the specified type.
   * For objects, it just tests that it is an object
   *
   * @param argumentValue value to verify
   * @param expectedType expected result of typeof
   * @param argumentName name of argument to verify
   */
  /*tslint:disable-next-line */
  public static verifyParameterType(argumentValue: any, expectedType: string, argumentName: string) {
    if (typeof argumentValue !== expectedType) {
      throw new TableauError(SharedErrorCodes.InvalidParameter, `${argumentValue} has invalid type for parameter: ${argumentName}.`);
    }
  }

  /**
   * Throws an InvalidParameter error if argument is empty string, null or undefined.
   *
   * @param argumentValue value to verify
   * @param argumentName name of argument to verify
   */
  /*tslint:disable-next-line */
  public static verifyStringParameter(argumentValue: string, argumentName: string) {
    if (argumentValue === null || argumentValue === undefined || argumentValue === '') {
      throw new TableauError(SharedErrorCodes.InvalidParameter, `${argumentValue} is invalid value for paramter: ${argumentName}`);
    }
  }

  /**
   * Verifies passed value is a valid value for that enum.
   * Throws an InvalidParameter error if the enum value is not valid.
   *
   * String enums are {string : string} dictionaries which are not reverse mappable
   * This is an ugly workaround
   *
   * @param enumValue value to verify
   * @param enumType enum to verify against
   * @param enumName enum name for clear error message
   */
  /*tslint:disable-next-line */
  public static verifyEnumValue<EnumType extends EnumLike>(enumValue: EnumType, enumType: any, enumName: string) {
    let isValid = false;
    Object.keys(enumType).forEach((enumKey) => {
      if (enumType[enumKey] === enumValue.toString()) {
        isValid = true;
      }
    });

    if (!isValid) {
      throw new TableauError(SharedErrorCodes.InvalidParameter, `${enumValue} is invalid value for enum: ${enumName}.`);
    }
  }

  /**
   * Verifies passed value is between [min, max) ... min <= value < max
   * Throws an InvalidParameter error if the value is not valid.
   *
   *
   * @param value value to verify
   * @param min   value must be >= min
   * @param max   value must be < max
   */
  /*tslint:disable-next-line */
  public static verifyRange(value: number, min: number, max: number) {
    let isValid = min <= value && value < max;

    if (!isValid) {
      throw new TableauError(SharedErrorCodes.InvalidParameter, `${value} is invalid value for range: [${min}..${max})`);
    }
  }

  /**
   * Verifies the params min and max for applying range filter.
   * Throws with error code InvalidParameter if range is invalid.
   *
   * @param min range min
   * @param max range max
   */
  /*tslint:disable-next-line */
  public static verifyRangeParamType(min: any, max: any): void {
    if (!min && !max) {
      throw new TableauError(SharedErrorCodes.InvalidParameter, 'Unexpected invalid param value, at least one of min or max is required.');
    }

    if (min && !Param.isTypeNumber(min) && !Param.isTypeDate(min)) {
      throw new TableauError(
        SharedErrorCodes.InvalidParameter,
        'Unexpected invalid param value, only Date and number are allowed for parameter min.',
      );
    }

    if (max && !Param.isTypeNumber(max) && !Param.isTypeDate(max)) {
      throw new TableauError(
        SharedErrorCodes.InvalidParameter,
        'Unexpected invalid param value, only Date and number are allowed for parameter max.',
      );
    }

    if (min && max && typeof min !== typeof max) {
      throw new TableauError(
        SharedErrorCodes.InvalidParameter,
        'Unexpected invalid param value, parameters min and max should be of the same type.',
      );
    }
  }

  /**
   * Verifies that the zoneId is present in the current dashboard.
   * Throws with error code InvalidParameter if either condition is false.
   *
   * @param dashboardZoneMap A map of zoneId's to the corresponding dashboard object.
   * @param zoneID ZoneId to be validated
   */
  public static verifyZoneIsValid(dashboardZoneMap: Map<number, DashboardObjectImpl>, zoneID: number): void {
    if (dashboardZoneMap.has(zoneID)) {
      return;
    }

    throw new TableauError(
      SharedErrorCodes.InvalidParameter,
      `Unexpected invalid param value, Dashboard Object Id: ${zoneID} is not present in dashboard.`,
    );
  }

  /**
   * Verifies that the zone is present and floating in the current dashboard.
   * Throws with error code InvalidParameter if either condition is false.
   *
   * @param dashboardZoneMap A map of zoneId's to the corresponding dashboard object.
   * @param zoneID ZoneId to be validated
   */
  public static verifyZoneIsValidAndFloating(dashboardZoneMap: Map<number, DashboardObjectImpl>, zoneID: number): void {
    if (dashboardZoneMap.has(zoneID) && dashboardZoneMap.get(zoneID)!.isFloating) {
      return;
    }

    throw new TableauError(
      SharedErrorCodes.InvalidParameter,
      `Unexpected invalid param value, Dashboard Object Id: ${zoneID} is not present or is a fixed zone in the dashboard.`,
    );
  }

  /**
   * Verifies that width and height are > 0 for each DashboardObjectPositionAndSizeUpdate object.
   * Throws with error code InvalidParameter if either condition is false.
   *
   * @param dashboardObjectPositionAndSizeUpdate DashboardObjectPositionAndSizeUpdate object for which width and height will be validated
   */
  public static verifyWidthAndHeightOfDashboardObjectPositionAndSizeUpdate(
    dashboardObjectPositionAndSizeUpdate: DashboardObjectPositionAndSizeUpdate,
  ): void {
    if (dashboardObjectPositionAndSizeUpdate.width < 0 || dashboardObjectPositionAndSizeUpdate.height < 0) {
      throw new TableauError(
        SharedErrorCodes.InvalidParameter,
        `Unexpected invalid param value for dashboard object ID ${dashboardObjectPositionAndSizeUpdate.dashboardObjectID}:` +
          ` negative widths and heights are not allowed.`,
      );
    }
  }
}
