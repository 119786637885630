import * as Contract from '@tableau/api-external-contract-js';
import { DashboardObjectType } from '@tableau/api-external-contract-js';
import { DashboardImpl } from './DashboardImpl';
import { WorksheetImpl } from './WorksheetImpl';

/**
 * Implementation of the dashboard objects - the zones in a dashboard.
 * This does not follow the Impl pattern as it is just a property bag.
 */
export class DashboardObjectImpl {
  public constructor(
    private _dashboardImpl: DashboardImpl,
    private _type: DashboardObjectType,
    private _position: Contract.Point,
    private _size: Contract.Size,
    private _worksheetImpl: WorksheetImpl | undefined,
    private _name: string,
    private _isFloating: boolean,
    private _isVisible: boolean,
    private _id: number,
  ) {}

  public get dashboardImpl(): DashboardImpl {
    return this._dashboardImpl;
  }

  public get type(): DashboardObjectType {
    return this._type;
  }

  public get position(): Contract.Point {
    return this._position;
  }

  public get size(): Contract.Size {
    return this._size;
  }

  public get worksheetImpl(): WorksheetImpl | undefined {
    return this._worksheetImpl;
  }

  public get name(): string {
    return this._name;
  }

  public get isFloating(): boolean {
    return this._isFloating;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  public get id(): number {
    return this._id;
  }
}
