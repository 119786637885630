import * as Contract from '@tableau/api-external-contract-js';
import { TableauEventType } from '@tableau/api-external-contract-js';
import { SingleEventManager } from '../SingleEventManager';

/**
 * This class implements the SingleEventManager interface for a single type of Tableau event
 *
 * @template TEventType The Tableau event type this class specializes
 */
export class SingleEventManagerImpl<TEventType extends Contract.TableauEvent> implements SingleEventManager {
  private _eventType: TableauEventType;
  private _handlers: Array<(eventObj: TEventType) => void>;

  public constructor(eventType: TableauEventType) {
    this._eventType = eventType;
    this._handlers = [];
  }

  public get eventType(): TableauEventType {
    return this._eventType;
  }

  public addEventListener(handler: (eventObj: TEventType) => void): Contract.TableauEventUnregisterFn {
    this._handlers.push(handler);
    return () => this.removeEventListener(handler);
  }

  public removeEventListener(handler: (eventObj: TEventType) => void): boolean {
    const beforeCount = this._handlers.length;
    this._handlers = this._handlers.filter((h) => h !== handler);
    return beforeCount > this._handlers.length;
  }

  public triggerEvent(eventGenerator: () => TEventType): void {
    for (const handler of this._handlers) {
      try {
        const eventModel = eventGenerator();
        handler(eventModel);
      } catch (e) {
        // Since this handler could be outside our control, just catch anything it throws and continue on
        continue;
      }
    }
  }
}
